import * as React from "react";
import ChevronImage from "../../../assets/ChevronImage";
import { validatePercentage, validateTEM } from "../../../Validations/ValidateFunctions";
import useStoreInputs from "../../../store/globalStates";
import { stylesSteps } from "../../../../css/steps";
import InputSelectAndCheckbox from "../../../Inputs/InputSelectAndCheckbox";

const SensitivityTables = () => {
  const styles = stylesSteps();
  const { chevronStates, setChevronStates } = useStoreInputs();
  const { setValidationByName, setValueByName, getInputValue } = useStoreInputs((state) => state);

  const handleCheckboxChange = (inputName: string) => {
    setValueByName(inputName, !getInputValue(inputName));
    if (inputName === "PEM") {
      setPEMCheck(!PEMCheck);
    }
    if (inputName === "PGRC") {
      if (!getInputValue(inputName)) {
        setValidationByName("PGR", true);
      } else {
        const isNumberandPercent = validatePercentage(getInputValue("PGR"));
        if (!isNumberandPercent) {
          setValidationByName("PGR", false);
        }
      }
    }
    if (inputName === "TEMC") {
      if (!getInputValue(inputName)) {
        setValidationByName("TEM", true);
      } else {
        const isValidate = validateTEM(getInputValue("TEM"));
        if (!isValidate) {
          setValidationByName("TEM", false);
        }
      }
    }
  };

  const isCheckboxDisabled = () => {
    return Array.isArray(getInputValue("YS")) && getInputValue("YS").length === 1;
  };
  const calculateYearsArray = () => {
    const years = getInputValue("YS");
    return Array.isArray(years) ? years : [years];
  };
  const calculateDefaultYear = (offset: number) => {
    const years = getInputValue("YS");
    return Array.isArray(years) ? Number(years[0]) + offset : Number(years) + offset;
  };
  const [PEMCheck, setPEMCheck] = React.useState(false);

  return (
    <>
      <div className={styles.stepOneContainers}>
        <div
          className={styles.subStep}
          style={{ margin: "0", display: "flex", alignItems: "center", justifyContent: "space-between" }}
        >
          <h4 className={styles.subStepTitle}>Sensitivity Tables Selection</h4>
          <button
            tabIndex={-1}
            style={{
              border: "none",
              backgroundColor: "transparent",
              transform: `rotate(${chevronStates[4].rotation}deg)`,
              transition: "transform 0.5s ease",
              cursor: "pointer",
            }}
            onClick={() => {
              setChevronStates(4);
            }}
          >
            <ChevronImage></ChevronImage>
          </button>
        </div>
        <div
          style={{
            opacity: chevronStates[4].visible ? "1" : "0",
            transition: chevronStates[4].visible ? "opacity 0.3s ease 0.3s" : "",
          }}
        >
          <div className={styles.stepCheckbox} style={{ backgroundColor: "#fff", borderRadius: "6px" }}>
            <input
              type="checkbox"
              className={styles.checkboxStep3}
              checked={getInputValue("PVTVC")}
              onChange={() => handleCheckboxChange("PVTVC")}
              id="checkbox-PVTVC"
            />
            <div className={styles.titlesCheckbox}>
              <h4 className={styles.titleCheckbox}>Present Value of Terminal Value / Enterprise Value </h4>
            </div>
          </div>
          <div className={styles.stepCheckbox} style={{ backgroundColor: "#fff", borderRadius: "6px" }}>
            <input
              type="checkbox"
              className={styles.checkboxStep3}
              checked={getInputValue("ITEMC")}
              onChange={() => handleCheckboxChange("ITEMC")}
              id="checkbox-ITEMC"
            />
            <div className={styles.titlesCheckbox}>
              <h4 className={styles.titleCheckbox}>Implied Terminal EBITDA Multiple</h4>
            </div>
          </div>
          <div className={styles.stepCheckbox} style={{ backgroundColor: "#fff", borderRadius: "6px" }}>
            <input
              type="checkbox"
              className={styles.checkboxStep3}
              checked={getInputValue("IPGRC")}
              onChange={() => handleCheckboxChange("IPGRC")}
              id="checkbox-IPGRC"
            />
            <div className={styles.titlesCheckbox}>
              <h4 className={styles.titleCheckbox}>Implied Perpetuity Growth Rate</h4>
            </div>
          </div>
          <div
            className={styles.stepCheckbox}
            style={{
              backgroundColor: "#fff",
              flexDirection: "column",
              alignItems: "start",
              flexWrap: "wrap",
              borderRadius: "6px",
            }}
          >
            <div style={{ display: "flex", alignItems: "center", gap: "0.6rem" }}>
              <input
                type="checkbox"
                className={styles.checkboxStep3}
                checked={getInputValue("EVC")}
                onChange={() => handleCheckboxChange("EVC")}
                id="checkbox-EVC"
              />
              <h4 className={styles.titleCheckbox}>Enterprise Value / EBITDA Multiple</h4>
            </div>
            <div
              className={styles.stepThreeCheckboxContainer}
              style={{ flexWrap: "wrap", marginLeft: "1.2rem", display: getInputValue("EVC") ? "flex" : "none" }}
            >
              <div className={styles.checkboxAndSelect}>
                {/* <input type="checkbox" className={styles.checkboxStep3} checked={true} /> */}
                <InputSelectAndCheckbox
                  disabled={isCheckboxDisabled()}
                  invisible={!getInputValue("EVC")}
                  options={calculateYearsArray()}
                  defaultValue={calculateDefaultYear(0)}
                  height_optional="1.375rem"
                  width_optional="3.5rem"
                  bgColor="#E1E1E3"
                  inputName="EVC1"
                />
              </div>
              <div className={styles.checkboxAndSelect}>
                {/* <input type="checkbox" className={styles.checkboxStep3} /> */}
                <InputSelectAndCheckbox
                  disabled={isCheckboxDisabled()}
                  invisible={!getInputValue("EVC")}
                  options={calculateYearsArray()}
                  defaultValue={calculateDefaultYear(1)}
                  height_optional="1.375rem"
                  bgColor="#E1E1E3"
                  width_optional="3.5rem"
                  inputName="EVC2"
                />
              </div>
              <div className={styles.checkboxAndSelect}>
                {/* <input type="checkbox" className={styles.checkboxStep3} /> */}
                <InputSelectAndCheckbox
                  disabled={isCheckboxDisabled()}
                  invisible={!getInputValue("EVC")}
                  options={calculateYearsArray()}
                  defaultValue={calculateDefaultYear(2)}
                  height_optional="1.375rem"
                  bgColor="#E1E1E3"
                  width_optional="3.5rem"
                  inputName="EVC3"
                />
              </div>
            </div>
          </div>
          <div
            className={styles.stepCheckbox}
            style={{ backgroundColor: "#fff", flexDirection: "column", alignItems: "start", borderRadius: "6px" }}
          >
            <div style={{ display: "flex", alignItems: "center", gap: "0.6rem" }}>
              <input
                type="checkbox"
                className={styles.checkboxStep3}
                checked={PEMCheck}
                onChange={() => handleCheckboxChange("PEM")}
                id="checkbox-PEM"
              />
              <h4 className={styles.titleCheckbox}>Price / Earnings Multiple</h4>
            </div>
            <div
              className={styles.stepThreeCheckboxContainer}
              style={{ flexWrap: "wrap", marginLeft: "1.2rem", display: getInputValue("PEM") ? "flex" : "none" }}
            >
              <div className={styles.checkboxAndSelect}>
                {/* <input type="checkbox" className={styles.checkboxStep3} checked={true} /> */}
                <InputSelectAndCheckbox
                  disabled={isCheckboxDisabled()}
                  invisible={!getInputValue("PEM")}
                  options={calculateYearsArray()}
                  defaultValue={calculateDefaultYear(0)}
                  height_optional="1.375rem"
                  bgColor="#E1E1E3"
                  width_optional="3.5rem"
                  inputName="PEMC1"
                />
              </div>
              <div className={styles.checkboxAndSelect}>
                {/* <input type="checkbox" className={styles.checkboxStep3} /> */}
                <InputSelectAndCheckbox
                  disabled={isCheckboxDisabled()}
                  invisible={!getInputValue("PEM")}
                  options={calculateYearsArray()}
                  defaultValue={calculateDefaultYear(1)}
                  height_optional="1.375rem"
                  bgColor="#E1E1E3"
                  width_optional="3.5rem"
                  inputName="PEMC2"
                />
              </div>
              <div className={styles.checkboxAndSelect}>
                {/* <input type="checkbox" className={styles.checkboxStep3} /> */}
                <InputSelectAndCheckbox
                  disabled={isCheckboxDisabled()}
                  invisible={!getInputValue("PEM")}
                  options={calculateYearsArray()}
                  defaultValue={calculateDefaultYear(2)}
                  height_optional="1.375rem"
                  bgColor="#E1E1E3"
                  width_optional="3.5rem"
                  inputName="PEMC3"
                />
              </div>
            </div>
          </div>
        </div>
      </div>
    </>
  );
};

export default SensitivityTables;
