import React from "react";
import { useStoreValuations, useTabsValuations } from "../../store/globalStates";
import { valuationsTabsStyles } from "../../../css/valuations";

export default function Tabs() {
  const styles = valuationsTabsStyles();
  const { finished, saveForLater, setSaveForLater, setFinished } = useTabsValuations();
  const { valuations } = useStoreValuations();

  const handleTabs = (tab: string) => {
    if (tab === "saveForLater") {
      setSaveForLater(true);
      setFinished(false);
    } else if (tab === "finished") {
      setSaveForLater(false);
      setFinished(true);
    }
  };

  const hasSavedForLater = valuations.filter((valuation) => valuation.saveForLater).length > 0;
  const hasFinished = valuations.filter((valuation) => !valuation.saveForLater).length > 0;

  return (
    <div className={styles.container}>
      <button
        className={`${styles.button} ${saveForLater ? styles.saveForLaterActive : styles.saveForLaterInactive} ${
          !hasSavedForLater ? styles.disabledButton : ""
        }`}
        onClick={() => handleTabs("saveForLater")}
        disabled={!hasSavedForLater}
      >
        Saved for later
      </button>
      <button
        className={`${styles.button} ${finished ? styles.finishedActive : styles.finishedInactive} ${
          !hasFinished ? styles.disabledButton : ""
        }`}
        onClick={() => handleTabs("finished")}
        disabled={!hasFinished}
      >
        Finished
      </button>
    </div>
  );
}
