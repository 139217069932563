import React from "react";
export default function EditIcon() {
  return (
    <svg width="12" height="13" viewBox="0 0 12 13" fill="none" xmlns="http://www.w3.org/2000/svg">
      <g clipPath="url(#clip0_3018_3094)">
        <path
          d="M5.95496 2.72266H2.45496C2.18974 2.72266 1.93539 2.82801 1.74785 3.01555C1.56031 3.20309 1.45496 3.45744 1.45496 3.72266V10.7227C1.45496 10.9879 1.56031 11.2422 1.74785 11.4298C1.93539 11.6173 2.18974 11.7227 2.45496 11.7227H9.45496C9.72017 11.7227 9.97453 11.6173 10.1621 11.4298C10.3496 11.2422 10.455 10.9879 10.455 10.7227V7.22266"
          stroke="white"
          strokeWidth="0.929932"
          strokeLinecap="round"
          strokeLinejoin="round"
        />
        <path
          d="M9.70496 1.97277C9.90387 1.77386 10.1737 1.66211 10.455 1.66211C10.7363 1.66211 11.006 1.77386 11.205 1.97277C11.4039 2.17168 11.5156 2.44146 11.5156 2.72277C11.5156 3.00407 11.4039 3.27386 11.205 3.47277L6.45496 8.22277L4.45496 8.72277L4.95496 6.72277L9.70496 1.97277Z"
          stroke="white"
          strokeWidth="0.929932"
          strokeLinecap="round"
          strokeLinejoin="round"
        />
      </g>
      <defs>
        <clipPath id="clip0_3018_3094">
          <rect width="12" height="12" fill="white" transform="translate(0.454956 0.722656)" />
        </clipPath>
      </defs>
    </svg>
  );
}
