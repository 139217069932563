import useStoreInputs from "../store/globalStates";

export const numberToNegative = (number) => -Math.abs(number);

export const setCommonValues = (nameItem) => {
  const { setColorInputFont, setCelda, setCeldaWithSheet } = useStoreInputs.getState();
  setColorInputFont(nameItem, false);
  setCelda(nameItem, "");
  setCeldaWithSheet(nameItem, "");
};
export const setInputEmpty = (nameItem) => {
  const { setValueByName, setColorInputFont, setLabelValidation, setShowValidation, setValidationByName } =
    useStoreInputs.getState();
  // En caso de que quede vacío el input
  setValueByName(nameItem, "");
  setColorInputFont(nameItem, undefined);
  setLabelValidation(nameItem, "");
  setShowValidation(nameItem, false);

  const inputsObligatorios = ["WACC", "PGR", "TEM", "YS", "EBI", "COE"];
  if (inputsObligatorios.includes(nameItem)) {
    setValidationByName(nameItem, false);
    setLabelValidation(nameItem, "Is mandatory");
  } else {
    setValidationByName(nameItem, true);
  }
};
export const validateInput = (validationByName, nameItem, value) => {
  const { setValidationByName, setLabelValidation } = useStoreInputs.getState();
  setValidationByName(nameItem, validationByName(nameItem, value));
  if (!validationByName(nameItem, value)) {
    //En caso de que la validacion falle, se setea el mensaje de error
    setLabelValidation(nameItem, "Wrong Format");
  }
};

const splitValue = (value) => (typeof value === "string" && value.includes(";") ? value.split(";") : [value]);

const validateLengths = (nameItem: string) => {
  const { getInputValue, setValidationByName, setLabelValidation } = useStoreInputs.getState();
  const lengthOfArray = Array.isArray(getInputValue(nameItem)) ? getInputValue(nameItem).length : 1;
  const lengthOfYearSelection = Array.isArray(getInputValue("YS")) ? getInputValue("YS").length : 1;
  if (Number(lengthOfArray) === Number(lengthOfYearSelection)) {
    setValidationByName(nameItem, true);
  } else {
    setValidationByName(nameItem, false);
    setLabelValidation(nameItem, "Amount of values differs from Years Selection");
  }
};

const updateValidation = (nameItem, validationByName) => {
  const { getInputValue, setValidationByName, setLabelValidation } = useStoreInputs.getState();
  setValidationByName(nameItem, validationByName(nameItem, getInputValue(nameItem)));
  if (!validationByName(nameItem, getInputValue(nameItem))) {
    setLabelValidation(nameItem, "Wrong Format");
  }
};

export const handleSecondStepInputs = (validationByName, nameItem, value) => {
  const { setValueByName, setValidationByName, setLabelValidation } = useStoreInputs.getState();
  if (typeof value === "string") {
    setValueByName(nameItem, splitValue(value));
  } else if (Array.isArray(value)) {
    setValueByName(nameItem, value);
  }
  updateValidation(nameItem, validationByName);
  if (value && value.length > 0) {
    validateLengths(nameItem);
  } else {
    setValidationByName(nameItem, true);
    setLabelValidation(nameItem, "");
  }
};

export const handleVDInput = (value) => {
  const { setValueByName, setColorInputFont } = useStoreInputs.getState();
  // Agregar las barras en las posiciones correctas
  if (value.length === 2 && value.indexOf("/") === -1) {
    value += "/";
  } else if (value.length === 5 && value.lastIndexOf("/") === 2) {
    value += "/";
  } else if (value.length > 10) {
    // Limitar la longitud a 10 caracteres (DD/MM/AAAA)
    value = value.slice(0, 10);
  }

  setValueByName("VD", value);
  setCommonValues(setColorInputFont);
  return value;
};

export const handleYSValidation = (validationByName, fromModal, setInputValue?) => {
  const { getInputValue, getInputValidation, getLabelValidation, setValueByName } = useStoreInputs.getState();
  const secondStepItemsWthYS = ["D&A", "CX", "EBI", "CNWC", "IdD", "iDD", "NI", "DIR", "DVDS"];

  secondStepItemsWthYS.forEach((item) => {
    if (getInputValue(item).length > 0) {
      if (getInputValidation(item) || getLabelValidation(item) === "Amount of values differs from Years Selection") {
        if (getInputValue(item) !== "" || item === "EBI") {
          validateLengths(item);
        }
      }
    }
  });

  updateValidation("YS", validationByName);

  let YSValue = getInputValue("YS");

  let finalYSValue;
  if (fromModal) {
    if (Array.isArray(YSValue) && YSValue.length > 1) {
      finalYSValue = YSValue.map((value) => {
        const fourDigitNumber = value?.match(/\d{4}/);
        return fourDigitNumber ? fourDigitNumber[0] : value; // Si hay un número de 4 dígitos, tomarlo; si no, tomar el valor tal cual
      });
    } else {
      // Convertir YSValue a string si no lo es
      YSValue = YSValue?.toString();
      const fourDigitNumber = YSValue?.match(/\d{4}/);
      finalYSValue = fourDigitNumber ? [fourDigitNumber[0]] : [YSValue]; // Si hay un número de 4 dígitos, tomarlo; si no, tomar el valor tal cual
    }
  }
  setInputValue && setInputValue(finalYSValue ? finalYSValue : YSValue);
  setValueByName("YS", finalYSValue ? finalYSValue : YSValue);
};

export const handleToggleValue = (value) => {
  // Convertir el valor a string y luego a mayúsculas para comparación
  const upperValue = String(value).toUpperCase();

  // Devolver "1" si el valor es "ON" o "1", de lo contrario devolver "0"
  return upperValue === "ON" || upperValue === "1" ? "1" : "0";
};

export const handleSpecialCases = (nameItem, value, validationByName, fromModal, setInputValue?) => {
  const { setValueByName, getInputValue, setNetIncomeEmpty, setWarning } = useStoreInputs.getState();
  switch (nameItem) {
    case "VD": {
      handleVDValidation();
      break;
    }
    case "FCFY": {
      handleFCFYValidation(setInputValue);
      break;
    }
    case "YS": {
      handleYSValidation(validationByName, fromModal, setInputValue);
      break;
    }
    case "D&A": {
      const itemValue = getInputValue("D&A");
      if (Array.isArray(itemValue)) {
        const newArray = itemValue.map((element) => (element > 0 ? numberToNegative(element) : element));
        setValueByName(nameItem, newArray);
      } else {
        itemValue > 0 ? numberToNegative(itemValue) : itemValue;
        setValueByName(nameItem, itemValue);
      }

      break;
    }
    case "CX": {
      const numbersArray = getInputValue("CX");
      if (Array.isArray(numbersArray)) {
        const foundPositive = numbersArray.some((element) => Number(element) > 0);
        setWarning("CX", foundPositive);
      }
      break;
    }
    case "MYC":
    case "CEDA":
    case "DTA/DTL": {
      setValueByName(nameItem, handleToggleValue(value));
      setCommonValues(nameItem);
      break;
    }
    case "NI": {
      const niValue = getInputValue("NI");
      const isNetIncomeEmpty = (niValue[0] === "" && niValue.length < 2) || niValue === "";
      setNetIncomeEmpty("NI", isNetIncomeEmpty);
      break;
    }
    default:
      break;
  }
};
export const handleVDValidation = () => {
  const { getInputValidation, getInputValue, setValidationByName, setLabelValidation } = useStoreInputs.getState();
  const FCFYValue = getInputValue("FCFY");
  if (getInputValidation("FCFY")) {
    if (FCFYValue !== "" && getInputValidation("VD")) {
      const valuationDate = getInputValue("VD");
      const yearValuationDate = valuationDate.slice(-4);
      const isValid = FCFYValue >= yearValuationDate;
      setValidationByName("VD", isValid);
      setLabelValidation("VD", "");
      if (!isValid) {
        setLabelValidation("VD", "The year is less than the year of FCFY");
      }
    }
  }
};
export const handleFCFYValidation = (setInputValue?) => {
  const { getInputValidation, getInputValue, setValidationByName, setLabelValidation, setValueByName } =
    useStoreInputs.getState();
  const FCFYValue = getInputValue("FCFY");
  const fourDigitNumber = FCFYValue.match(/\d{4}/);
  const finalFCFYValue = fourDigitNumber ? fourDigitNumber[0] : FCFYValue; // Si hay un número de 4 dígitos, tomarlo; si no, tomar el valor tal cual
  setValueByName("FCFY", finalFCFYValue);
  setInputValue && setInputValue(finalFCFYValue);

  if (fourDigitNumber) setValidationByName("FCFY", true);
  if (getInputValidation("FCFY")) {
    if (finalFCFYValue !== "" && getInputValidation("VD")) {
      const valuationDate = getInputValue("VD");
      const yearValuationDate = valuationDate.slice(-4);
      const isValid = finalFCFYValue >= yearValuationDate;
      setValidationByName("FCFY", isValid);
      setLabelValidation("FCFY", "");
      if (!isValid) {
        setLabelValidation("FCFY", "The year is less than the year of Valuation Date");
      }
    }
  }
};
