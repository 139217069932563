import { makeStyles, shorthands } from "@fluentui/react-components";

export const stylesModal = makeStyles({
  modalContainer: {
    position: "absolute",
    zIndex: "100",
    backgroundColor: "rgba(242, 243, 245, 1)",
    boxShadow: "0px 4px 32px 0px rgba(0, 0, 0, 0.09)",
    transform: "translate(-50%, -50%)",
    left: "50%",
    ...shorthands.padding("1rem"),
    // ...shorthands.padding('0px, 20px, 24px, 20px'),
    width: "50vw",
    height: "20vw",
    minWidth: "15.7rem",
    minHeight: "11rem",
  },
  modalContent: {
    display: "flex",
    flexDirection: "column",
    justifyContent: "space-around",
    height: "100%",
  },
  title: {
    color: "black",
    fontSize: "1rem",
    fontFamily: "Poppins",
    marginTop: "0",
    marginBottom: "0",
  },
  close: {
    color: "black",
    fontSize: "1rem",
    fontFamily: "Poppins",
    // position: "absolute",
    // top: '5px',
    // right: '5px',
    ...shorthands.border("none"),
    backgroundColor: "transparent",
    cursor: "pointer",
  },
  input: {
    width: "150px",
    textAlign: "center",
    height: "1.875rem",
    justifyContent: "flex-end",
    alignItems: "center",
    ...shorthands.gap("0.25rem"),
    ...shorthands.borderRadius("0.25rem"),
    ...shorthands.border("none"),
    position: "relative",
    backgroundColor: "#E1E1E3",
    "&::placeholder": {
      ...shorthands.margin("0.4rem"),
      color: "rgba(35, 44, 51, 1)",
      fontFamily: "Poppins",
      fontWeight: "500",
      fontSize: "0.7rem",
      lineHeight: "1.12rem",
      width: "101%",
    },
  },
  save: {
    backgroundColor: "rgba(35, 44, 51, 1)",
    color: "#fff",
    width: "3.4rem",
    height: "1.8rem",
    fontSize: "0.9375rem",
    cursor: "pointer",
    ...shorthands.border("none"),
    fontFamily: "Poppins",
  },
  saveDisabled: {
    backgroundColor: "#C2C2C7",
    color: "#fff",
    width: "3.4rem",
    height: "1.8rem",
    fontSize: "0.9375rem",
    cursor: "not-allowed",
    ...shorthands.border("none"),
  },
  inputButtonContainer: {
    display: "flex",
  },
  modalName: {
    color: "rgba(35, 44, 51, 1)",
    fontFamily: "Poppins",
    fontWeight: "500",
    fontSize: "0.75rem",
    lineHeight: "1.125",
    marginLeft: "10px",
  },
  modalInputContainer: {
    display: "flex",
    flexDirection: "row",
    justifyContent: "space-around",
    alignItems: "center",
    backgroundColor: "rgba(251, 251, 254, 1)",
    ...shorthands.gap("15px"),
    ...shorthands.borderRadius("6px"),
    width: "100%",
  },
  modalHeader: {
    display: "flex",
    justifyContent: "space-between",
    marginTop: "0.3rem",
  },
  modalBody: {
    display: "flex",
    flexDirection: "column",
    borderRadius: "6px",
    backgroundColor: "rgba(251, 251, 254, 1)",
    padding: "0 1rem",
    alignItems: "center",
    justifyContent: "space-between",
  },
});
