import * as React from "react";
import InputClip from "../../../Inputs/InputClip";
import ChevronImage from "../../../assets/ChevronImage";
import useStoreInputs from "../../../store/globalStates";
import Tooltip from "../../../Inputs/Tooltip";
import { stylesSteps2 } from "../../../../css/step2";

export default function CashFlowItems() {
  const { customContainers, getInputValue, getInputValidation, getShowValidation, getWarning, getLabelValidation } =
    useStoreInputs((state) => state);
  // const {customContainers,customContainersAux,editTitleCustomContainerAux,addCustomContainerAux,deleteCustomContainerAux,indexCustomInput,setIndexCustomInput,editTitleCustomContainer,addCustomContainer,deleteCustomContainer } = useStoreCustomContainers((state) => state)
  const { addCustomContainer, changeTitleCustomContainer, deleteCustomContainer } = useStoreInputs((state) => state);

  const styles = stylesSteps2();
  const { chevronStates, setChevronStates } = useStoreInputs();
  // const [titleCustomInput, setTitleCustomInput] = useState("");

  // const handleTitleCustomInput = (value: string) => {
  //   setTitleCustomInput(value);
  // };
  const handleAddCustomInput = () => {
    const newInput = {
      name: String(`Cash Flow Item ${customContainers.length}`),
      value: "",
      validation: true,
      step: 2,
      showValidation: false,
      colorInputFont: undefined,
      celdaWithSheet: "",
      celda: "",
      formula: "",
    };
    addCustomContainer(newInput);

    // if (titleCustomInput !== "") {
    //   setTitleCustomInput("");
    // }
  };

  const handleDeleteCustomInput = (inputName) => {
    deleteCustomContainer(inputName);
  };
  const onlyDDM = getInputValue("DDM") && !getInputValue("LDCF") && !getInputValue("UDCF");
  // const handleKeyDown = (event) => {
  //   if (event.key === "Tab") {
  //     event.preventDefault();
  //     // Simular clic en el botón "Next"
  //     simulateNextButtonClick();
  //   }
  // };

  // const simulateNextButtonClick = () => {
  //   setNextIsPress(true);
  // };
  return (
    <div
      className={styles.stepOneContainers}
      style={{
        margin: "0",
      }}
    >
      <div className={styles.subStep} style={{ margin: "0" }}>
        <h4 className={styles.subStepTitle}>Cash Flow Items</h4>
        <button
          tabIndex={-1}
          style={{
            border: "none",
            backgroundColor: "transparent",
            transform: `rotate(${chevronStates[2].rotation}deg)`,
            transition: "transform 0.5s ease",
            cursor: "pointer",
          }}
          onClick={() => {
            setChevronStates(2);
          }}
        >
          <ChevronImage></ChevronImage>
        </button>
      </div>
      <div
        style={{
          opacity: chevronStates[2].visible ? "1" : "0",
          transition: chevronStates[2].visible ? "opacity 0.3s ease 0.3s" : "",
          margin: "0",
        }}
      >
        <div className={styles.stepInput} style={{ backgroundColor: "#fff" }}>
          <div style={{ display: "flex", flexDirection: "column" }}>
            <h4 className={styles.titleGeneral}>EBITDA</h4>
            {getShowValidation("EBI") && (
              <div className={styles.validationContainer} style={{ marginRight: "auto" }}>
                <h4 className={styles.validation} style={{ marginRight: "0.313rem", marginLeft: "0.313rem" }}>
                  {getLabelValidation("EBI")}
                </h4>
              </div>
            )}
          </div>

          {<InputClip type="text" nameItem="EBI" />}
        </div>
        <div className={styles.stepInput} style={{ backgroundColor: "#fff" }}>
          <div style={{ display: "flex", flexDirection: "column" }}>
            <div style={{ display: "flex", gap: "1rem" }}>
              <h4 className={styles.titleGeneral}>D&A</h4>
              <Tooltip infoText="Should be negative for all periods" title="D&A"></Tooltip>
            </div>
            {getInputValidation("D&A") === false && getShowValidation("D&A") && (
              <div className={styles.validationContainer} style={{ marginRight: "auto" }}>
                <h4 className={styles.validation} style={{ marginRight: "0.313rem", marginLeft: "0.313rem" }}>
                  {getLabelValidation("D&A")}
                </h4>
              </div>
            )}
            {/* {getWarning('D&A')  && getInputValidation('D&A') === true && <div className={styles.validationContainer} style={{marginRight:'auto'}}><h4 className={styles.validation} style={{marginRight:'0.313rem', marginLeft:'0.313rem'}}>Amount differ from YS</h4></div>} */}
          </div>

          {<InputClip type="text" nameItem="D&A" />}
        </div>
        <div className={styles.stepInput} style={{ backgroundColor: "#fff" }}>
          <div style={{ display: "flex", flexDirection: "column" }}>
            <div style={{ display: "flex", gap: "1rem" }}>
              <h4 className={styles.titleGeneral}>Capex</h4>
              <Tooltip
                infoText="Should be negative for all periods unless a sale is expected for a given period"
                title="Capex"
              ></Tooltip>
            </div>
            {getInputValidation("CX") === false && getShowValidation("CX") && (
              <div className={styles.validationContainer} style={{ marginRight: "auto" }}>
                <h4 className={styles.validation} style={{ marginRight: "0.313rem", marginLeft: "0.313rem" }}>
                  {getLabelValidation("CX")}
                </h4>
              </div>
            )}
            {getWarning("CX") === true && getInputValidation("CX") === true && (
              <div
                className={styles.validationContainer}
                style={{
                  backgroundColor: "rgba(255, 210, 106, 0.5)",
                  width: "auto",
                  wordBreak: "break-word",
                  padding: "0.2rem",
                }}
              >
                <h4 className={styles.validation} style={{ marginRight: "0.313rem", marginLeft: "0.313rem" }}>
                  Some numbers are positive
                </h4>
              </div>
            )}

            {getShowValidation("CX") && (
              <div className={styles.validationContainer} style={{ marginRight: "auto" }}>
                <h4 className={styles.validation} style={{ marginRight: "0.313rem", marginLeft: "0.313rem" }}>
                  {getInputValidation("CX")}
                </h4>
              </div>
            )}

            {/* {getInputValidation('CX') === false && getShowValidation('CX') && getInputValue('CX') !== '' && (
                  <div className={styles.validationContainer} style={{marginRight:'auto'}}>
                      <h4 className={styles.validation} style={{marginRight:'0.313rem', marginLeft:'0.313rem'}}>Wrong format</h4>
                  </div>
              )} */}
          </div>
          {<InputClip type="text" nameItem="CX" />}
        </div>
        <div className={styles.stepInput} style={{ backgroundColor: "#fff" }}>
          <div style={{ display: "flex", flexDirection: "column" }}>
            <div style={{ display: "flex", gap: "1rem" }}>
              <h4 className={styles.titleGeneral}>Change in NWC</h4>
              <Tooltip
                infoText="Values should be expressed in their true cash flow sign"
                title="Change in NWC"
              ></Tooltip>
            </div>
            {getInputValidation("CNWC") === false && getShowValidation("CNWC") && (
              <div className={styles.validationContainer} style={{ marginRight: "auto" }}>
                <h4 className={styles.validation} style={{ marginRight: "0.313rem", marginLeft: "0.313rem" }}>
                  {getLabelValidation("CNWC")}
                </h4>
              </div>
            )}
          </div>

          {<InputClip type="text" nameItem="CNWC" />}
        </div>
        <div className={styles.stepInput} style={{ backgroundColor: "#fff" }}>
          <div style={{ display: "flex", flexDirection: "column", maxWidth: "55%" }}>
            <div style={{ display: "flex", gap: "0.4rem" }}>
              <h4 className={styles.titleGeneral}>(Increase) / Decrease in DTA</h4>
              <Tooltip
                infoText="Values should be expressed in their true cash flow sign"
                title="(Increase) / Decrease in DTA"
              ></Tooltip>
            </div>
            {getInputValidation("IdD") === false && getShowValidation("IdD") && (
              <div className={styles.validationContainer} style={{ marginRight: "auto" }}>
                <h4 className={styles.validation} style={{ marginRight: "0.313rem", marginLeft: "0.313rem" }}>
                  {getLabelValidation("IdD")}
                </h4>
              </div>
            )}
          </div>
          {<InputClip type="text" nameItem="IdD" />}
        </div>
        <div className={styles.stepInput} style={{ backgroundColor: "#fff" }}>
          <div style={{ display: "flex", flexDirection: "column", maxWidth: "55%" }}>
            <div style={{ display: "flex", gap: "0.4rem" }}>
              <h4 className={styles.titleGeneral}>Increase / (Decrease) in DTL</h4>
              <Tooltip
                infoText="Values should be expressed in their true cash flow sign"
                title="Increase / (Decrease) in DTL"
              ></Tooltip>
            </div>
            {getInputValidation("iDD") === false && getShowValidation("iDD") && (
              <div className={styles.validationContainer} style={{ marginRight: "auto" }}>
                <h4 className={styles.validation} style={{ marginRight: "0.313rem", marginLeft: "0.313rem" }}>
                  {getLabelValidation("iDD")}
                </h4>
              </div>
            )}
          </div>
          {<InputClip type="text" nameItem="iDD" />}
        </div>

        <>
          <div className={styles.stepInput} style={{ backgroundColor: "#fff" }}>
            <div
              style={{
                display: "flex",
                flexDirection: "column",
                width: `${getInputValue("LDCF") ? "auto" : "100%"}`,
              }}
            >
              <div style={{ display: "flex", flexDirection: "row", justifyContent: "space-between" }}>
                <h4 className={styles.titleGeneral} style={{ opacity: `${getInputValue("LDCF") ? 1 : 0.2}` }}>
                  Net Income
                </h4>
                {!getInputValue("LDCF") && (
                  <Tooltip
                    right={true}
                    infoText="Not Needed for Selected Valuation Methodologies"
                    title="Net Income"
                  ></Tooltip>
                )}
              </div>

              {getInputValidation("NI") === false && getShowValidation("NI") && (
                <div className={styles.validationContainer} style={{ marginRight: "auto" }}>
                  <h4 className={styles.validation} style={{ marginRight: "0.313rem", marginLeft: "0.313rem" }}>
                    {getLabelValidation("NI")}
                  </h4>
                </div>
              )}
            </div>
            {getInputValue("LDCF") && <InputClip type="text" nameItem="NI" />}
          </div>
          <div className={styles.stepInput} style={{ backgroundColor: "#fff" }}>
            <div
              style={{
                display: "flex",
                flexDirection: "column",
                width: `${getInputValue("DDM") ? "auto" : "100%"}`,
              }}
            >
              <div style={{ display: "flex", flexDirection: "row", justifyContent: "space-between" }}>
                <h4 className={styles.titleGeneral} style={{ opacity: `${getInputValue("DDM") ? 1 : 0.2}` }}>
                  Dividends
                </h4>
                {!getInputValue("DDM") && (
                  <Tooltip
                    right={true}
                    infoText="Not Needed for Selected Valuation Methodologies"
                    title="Dividends"
                  ></Tooltip>
                )}
              </div>
            </div>
            {getInputValue("DDM") && <InputClip type="text" nameItem="DVDS" />}
          </div>

          <div className={styles.stepInput} style={{ backgroundColor: "#fff" }}>
            <div style={{ display: "flex", flexDirection: "column", maxWidth: "55%" }}>
              <h4 className={styles.titleGeneral} style={{ opacity: `${getInputValue("LDCF") ? 1 : 0.2}` }}>
                Debt Issuance / (Repayment)
              </h4>
              {getInputValidation("DIR") === false && getShowValidation("DIR") && (
                <div className={styles.validationContainer} style={{ marginRight: "auto" }}>
                  <h4 className={styles.validation} style={{ marginRight: "0.313rem", marginLeft: "0.313rem" }}>
                    {getLabelValidation("DIR")}
                  </h4>
                </div>
              )}
            </div>
            {!getInputValue("LDCF") && (
              <Tooltip
                right={true}
                infoText="Not Needed for Selected Valuation Methodologies"
                title="Debt Issuance / (Repayment)"
              ></Tooltip>
            )}
            {getInputValue("LDCF") && <InputClip type="text" nameItem="DIR" />}
          </div>
        </>

        {customContainers.map((input, index) => (
          <div key={index} className={styles.stepOneContainers} style={{ backgroundColor: "#fff" }}>
            <div className={styles.stepInput}>
              <div style={{ display: "flex", flexDirection: "column" }}>
                <h4 className={styles.titleGeneral}>
                  <span style={{ width: "8rem", border: "none", backgroundColor: "transparent", outline: "none" }}>
                    <input
                      style={{ width: "8rem", border: "none", backgroundColor: "transparent", outline: "none" }}
                      type="text"
                      value={input.name == `Cash Flow Item ${customContainers.length - 1}` ? "" : input.name}
                      onChange={(e) => changeTitleCustomContainer(Number(index), e.target.value)}
                      placeholder="Enter title"
                    />
                  </span>
                </h4>
              </div>
              <div style={{ display: "flex", gap: "0.3rem" }}>
                <InputClip type="text" nameItem={input.name} />
                <button
                  className={styles.close}
                  style={{ borderRadius: "4px" }}
                  onClick={() => handleDeleteCustomInput(input.name)}
                >
                  X
                </button>
              </div>
            </div>
          </div>
        ))}
        <div className={styles.buttonNewLineContainer}>
          <button
            disabled={onlyDDM ? true : false}
            className={`${onlyDDM ? styles.buttonNewLineDisabled : styles.buttonNewLine}`}
            onClick={() => {
              handleAddCustomInput();
            }}
            id="button-add-new-cash-flow-item"
          >
            Add New Cash Flow Item
          </button>
        </div>
      </div>
    </div>
  );
}
