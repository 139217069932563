import React from "react";
import { myValuationsStyles } from "../../../css/valuations";
import useStoreInputs, {
  useStoreActualValuationNumber,
  useStoreRoutingService,
  useStoreValuations,
  useTabsValuations,
} from "../../store/globalStates";
import { Valuation } from "../../../types/types";
import Tabs from "./Tabs";
import ValuationCard from "./ValuationCard";

export default function MyValuations() {
  const styles = myValuationsStyles();
  const { setInput } = useStoreInputs((state) => state);
  const { setRoutingNumber } = useStoreRoutingService((state) => state);
  const { setActualValuationNumber } = useStoreActualValuationNumber((state) => state);
  const { saveForLater, finished } = useTabsValuations();
  const { valuations, setEditValuation, setValuationSelected } = useStoreValuations();

  const handleValuation = (valuation: Valuation) => {
    setValuationSelected(valuation.valuationNumber);
    setRoutingNumber(2);
    setEditValuation(true);
    setInput(valuation.inputs);
    setActualValuationNumber(valuation.valuationNumber);
  };
  const renderValuations = () => {
    if (saveForLater && finished) {
      return valuations.map((valuation, index) => (
        <ValuationCard index={index} handleValuation={handleValuation} valuation={valuation} key={index} />
      ));
    }

    if (saveForLater) {
      return valuations
        .filter((valuation) => valuation.saveForLater)
        .map((valuation, index) => (
          <ValuationCard index={index} handleValuation={handleValuation} valuation={valuation} key={index} />
        ));
    }

    if (finished) {
      return valuations
        .filter((valuation) => !valuation.saveForLater)
        .map((valuation, index) => (
          <ValuationCard index={index} handleValuation={handleValuation} valuation={valuation} key={index} />
        ));
    }

    return null;
  };
  return (
    <>
      <div className={styles.stepContainer}>
        <div className={styles.titlesContainer}>
          <p className={styles.title}> My valuations</p>
        </div>
      </div>
      <Tabs />
      <div className={styles.finishedValuationsContainer}>{renderValuations()}</div>
    </>
  );
}
