import React from "react";
import { buttonStyles } from "../../../css/buttons";
import useStoreInputs, {
  useStoreActualValuationNumber,
  useStoreRoutingService,
  useStoreSteps,
  useStoreValuations,
  useTabsValuations,
} from "../../store/globalStates";
import { createValuationDate } from "../../helpers/utils";

export default function SaveForLaterButton() {
  const { setRoutingNumber } = useStoreRoutingService((state) => state);
  const {
    addValuation,
    editValuation,
    incrementValuationCounter,
    updateSaveForLater,
    updateValuationInputs,
    valuationCounter,
  } = useStoreValuations((state) => state);

  const { inputs, valuationName } = useStoreInputs((state) => state);
  const { actualValuationNumber } = useStoreActualValuationNumber((state) => state);
  const { setStepNumber } = useStoreSteps((state) => state);
  const { setSaveForLater } = useTabsValuations((state) => state);

  const handleCreateValuation = () => {
    setRoutingNumber(4);
    setSaveForLater(true);
    setStepNumber(1);
    // eslint-disable-next-line no-undef
    window.scrollTo(0, 0);
  };
  const createNewValuation = () => {
    const newValuation = {
      valuationName: valuationName,
      inputs: inputs,
      date: createValuationDate(),
      valuationNumber: valuationCounter + 1, // Usa el contador como ID
      saveForLater: true,
    };

    addValuation(newValuation);
    incrementValuationCounter(); // Incrementa el contador
  };
  const updateExistingValuation = () => {
    updateSaveForLater(actualValuationNumber, true);
    updateValuationInputs(actualValuationNumber, inputs);
  };

  const handleSaveForLater = async () => {
    if (!editValuation) {
      createNewValuation();
    } else {
      updateExistingValuation();
    }
    handleCreateValuation();
  };

  const styles = buttonStyles();
  return (
    <button
      tabIndex={-1}
      className={styles.buttonSave}
      style={{ borderRadius: "0.25rem" }}
      onClick={handleSaveForLater}
      id="button-save-for-later"
    >
      Save for Later
    </button>
  );
}
