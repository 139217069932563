import * as React from "react";
import { useState } from "react";
import { makeStyles, shorthands } from "@fluentui/react-components";
import TooltipImage from "../assets/TooltipImage";

interface TooltipProps {
  infoText: string;
  title: string;
  right?: boolean;
}

const useStyles = makeStyles({
  tooltipContainer: {
    display: "flex",
    flexDirection: "column",
    alignItems: "center",
    justifyContent: "center",
    position: "relative",
  },
  tooltip: {
    marginLeft: "-1.4rem",
    width: "11rem",
    height: "4rem",
    fontSize: "12px",
    fontWeight: "400",
    backgroundColor: "#f5f5f5",
    textAlign: "center",
    ...shorthands.borderRadius("6px"),
    position: "absolute",
    top: "-55px",
    opacity: 0,
  },
  arrow: {
    width: 0,
    height: 0,
    position: "absolute",
    bottom: "-20px",
    left: "calc(13% - 12px)",
  },
  open: {
    transform: "translateY(-50px)",
    opacity: 1,
  },
  tooltipText: {
    ...shorthands.margin("0px"),
    fontFamily: "Poppins",
    fontWeight: "400",
    fontSize: "0.625rem",
    color: "#000",
    lineHeight: "1rem",
    textAlign: "left",
  },
});

const Tooltip: React.FC<TooltipProps> = ({ infoText, title, right }) => {
  const [showTooltip, setShowTooltip] = useState(false);
  const styles = useStyles();

  return (
    <div
      className={styles.tooltipContainer}
      style={{ gap: "20px" }}
      onMouseEnter={() => setShowTooltip(true)}
      onMouseLeave={() => setShowTooltip(false)}
    >
      {/* Contenedor más grande para el signo de pregunta */}
      <div style={{ display: "inline-block", position: "relative" }}>
        {/* Signo de pregunta con eventos onMouseEnter y onMouseLeave */}
        <span
          style={{
            fontSize: "20px",
            position: "relative",
            zIndex: 2,
            cursor: "pointer",
            marginRight: `${right ? "7px" : ""}`,
          }}
        >
          <TooltipImage></TooltipImage>
        </span>

        {/* Tooltip content */}
        <div
          className={`${styles.tooltip} ${showTooltip ? styles.open : ""}`}
          style={{
            transition: "all 0.3s ease",
            padding: "12px",
            border: "1px solid #d8e2e2",
            visibility: showTooltip ? "visible" : "hidden",
            left: `${right ? "-150px" : ""}`,
            zIndex: 3,
          }}
        >
          <div style={{ display: "flex", flexDirection: "column", gap: "0.3rem" }}>
            <h4 className={styles.tooltipText} style={{ fontWeight: "700" }}>
              {title}
            </h4>
            <h5 className={styles.tooltipText}>{infoText}</h5>
          </div>

          {/* Arrow indicator */}
          <div
            className={styles.arrow}
            style={{
              borderLeft: "12px solid transparent",
              borderRight: "12px solid transparent",
              borderTop: "20px solid #f5f5f5",
              left: `${right ? "calc(90% - 12px)" : ""}`,
            }}
          />
        </div>
      </div>
    </div>
  );
};

export default Tooltip;
