import * as React from "react";
import { useState, useEffect } from "react";
import useStoreInputs from "../../../store/globalStates";
import { stylesSteps } from "../../../../css/steps";
import TerminalValue from "./TerminalValue";
import General from "./General";
import ReportCheckboxs from "./ReportCheckboxs";
import FinalInputs from "./FinalInputs";

interface InputComponentProps {
  onTabPress: () => void;
}

const StepContent1: React.FC<InputComponentProps> = ({ onTabPress }) => {
  const styles = stylesSteps();
  const { setValidationByName, setValueByName, getInputValue } = useStoreInputs((state) => state);
  const [prevUDCFValue, setPrevUDCFValue] = useState(getInputValue("UDCF"));
  const [prevLDCFValue, setPrevLDCFValue] = useState(getInputValue("LDCF"));

  useEffect(() => {
    const currentUDCFValue = getInputValue("UDCF");
    if (currentUDCFValue !== prevUDCFValue) {
      setValueByName("WACC", "");
      setValidationByName("WACC", !currentUDCFValue);
      setPrevUDCFValue(currentUDCFValue);
    }
  }, [getInputValue("UDCF")]);

  useEffect(() => {
    const currentLDCFValue = getInputValue("LDCF");
    if (currentLDCFValue !== prevLDCFValue) {
      setValueByName("COE", "");
      setValidationByName("COE", !currentLDCFValue);
      setPrevLDCFValue(currentLDCFValue);
    }
  }, [getInputValue("LDCF")]);

  return (
    <>
      <div
        className={`${styles.root}`}
        style={{
          paddingBottom: "4.2rem",
          width: "calc(100% - 0.8rem)",
          // ...(modalVisible.visible ? { backdropFilter: "blur(2px)", filter: "blur(2px)" } : {}),
        }}
      >
        <ReportCheckboxs />
        <General />
        <TerminalValue />
        <FinalInputs onTabPress={onTabPress} />
      </div>
    </>
  );
};

export default StepContent1;
