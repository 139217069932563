import React from "react";
import { buttonStyles } from "../../../css/buttons";

interface BackButtonProps {
  backIsPressed: () => void;
}
export default function BackButton({ backIsPressed }: BackButtonProps) {
  const styles = buttonStyles();
  return (
    <button
      tabIndex={-1}
      className={styles.buttonBack}
      style={{ borderRadius: "0.25rem" }}
      onClick={() => {
        backIsPressed();
      }}
      id="button-back"
    >
      Back
    </button>
  );
}
