import React from "react";
import { stylesSteps } from "../../../../css/steps";
import ChevronImage from "../../../assets/ChevronImage";
import useStoreInputs from "../../../store/globalStates";
import { validatePercentage, validateTEM } from "../../../Validations/ValidateFunctions";

export default function TerminalValue() {
  const styles = stylesSteps();
  const { chevronStates, setChevronStates } = useStoreInputs();
  const { setValidationByName, setValueByName, getInputValue } = useStoreInputs((state) => state);

  const handleCheckboxChange = (inputName: string) => {
    setValueByName(inputName, !getInputValue(inputName));
    if (inputName === "PGRC") {
      if (!getInputValue(inputName)) {
        setValidationByName("PGR", true);
      } else {
        const isNumberandPercent = validatePercentage(getInputValue("PGR"));
        if (!isNumberandPercent) {
          setValidationByName("PGR", false);
        }
      }
    }
    if (inputName === "TEMC") {
      if (!getInputValue(inputName)) {
        setValidationByName("TEM", true);
      } else {
        const isValidate = validateTEM(getInputValue("TEM"));
        if (!isValidate) {
          setValidationByName("TEM", false);
        }
      }
    }
  };

  return (
    <div
      style={{
        transition: chevronStates[0].visible ? "transform 0.5s ease" : "transform 0.5s ease",
        transform: chevronStates[0].visible ? "translateY(0)" : "translateY(-220px)",
      }}
    >
      <div className={styles.generalTitle}>
        <h4 className={styles.subStepTitle}>Terminal Value Approach</h4>
        <button
          tabIndex={-1}
          style={{
            border: "none",
            backgroundColor: "transparent",
            transform: `rotate(${chevronStates[1].rotation}deg)`,
            transition: "transform 0.5s ease",
            cursor: "pointer",
          }}
          onClick={() => {
            setChevronStates(1);
          }}
        >
          <ChevronImage></ChevronImage>
        </button>
      </div>
      <div
        className={styles.stepCheckbox}
        style={{
          backgroundColor: "#fff",
          borderRadius: "6px",
          opacity: chevronStates[1].visible ? "1" : "0",
          transition: chevronStates[1].visible ? "opacity 0.3s ease 0.3s" : "",
        }}
      >
        <input
          type="checkbox"
          className={styles.checkboxStep3}
          checked={getInputValue("PGRC")}
          onChange={() => handleCheckboxChange("PGRC")}
          id="checkbox-PGRC"
        />
        <div className={styles.titlesCheckbox}>
          <h4 className={styles.titleCheckbox}>Perpetuity Growth Rate</h4>
          {/* <h6 className={styles.subTitleCheckbox}>(Unlevered DCF)</h6> */}
        </div>
      </div>
      <div
        className={styles.stepCheckbox}
        style={{
          backgroundColor: "#fff",
          borderRadius: "6px",
          opacity: chevronStates[1].visible ? "1" : "0",
          transition: chevronStates[1].visible ? "opacity 0.3s ease 0.3s" : "",
        }}
      >
        <input
          type="checkbox"
          id="checkbox-TEMC"
          className={styles.checkboxStep3}
          checked={getInputValue("TEMC")}
          onChange={() => handleCheckboxChange("TEMC")}
        />
        <div className={styles.titlesCheckbox}>
          <h4 className={styles.titleCheckbox}>Terminal EBITDA Multiple</h4>
          {/* <h6 className={styles.subTitleCheckbox}>(Levered DCF)</h6> */}
        </div>
      </div>
    </div>
  );
}
