import React from "react";
import InputClip from "../../../Inputs/InputClip";
import InputCheckbox from "../../../Inputs/InputCheckbox";
import { stylesSteps } from "../../../../css/steps";
import Tooltip from "../../../Inputs/Tooltip";
import useStoreInputs from "../../../store/globalStates";

interface InputComponentProps {
  onTabPress: () => void;
}

const FinalInputs: React.FC<InputComponentProps> = ({ onTabPress }) => {
  const styles = stylesSteps();
  const { chevronStates } = useStoreInputs();
  const { getShowValidation, getInputValue, getLabelValidation } = useStoreInputs((state) => state);

  return (
    <div
      style={{
        transition: chevronStates[0].visible ? "transform 0.5s ease" : "transform 0.5s ease",
        transform: chevronStates[0].visible ? "translateY(0)" : "translateY(-220px)",
      }}
    >
      {/* FINAL STEP 1 */}
      <div
        className={styles.stepOneContainers}
        style={{
          transition: chevronStates[1].visible ? "transform 0.5s ease" : "transform 0.5s ease",
          transform: chevronStates[1].visible ? "translateY(0)" : "translateY(-40%)",
        }}
      >
        <div className={styles.stepInput} style={{ backgroundColor: "#fff" }}>
          <div style={{ display: "flex", flexDirection: "column" }}>
            <h4 className={styles.titleGeneral}>Copex Equal to D&A</h4>
            {getShowValidation("CEDA") && (
              <div className={styles.validationContainer} style={{ marginRight: "auto" }}>
                <h4 className={styles.validation} style={{ marginRight: "0.313rem", marginLeft: "0.313rem" }}>
                  {getLabelValidation("CEDA")}
                </h4>
              </div>
            )}
          </div>
          <div className={styles.dobleInputContainer}>
            <InputCheckbox nameItem="CEDA" />
            <InputClip type="checkbox" nameItem="CEDA" linkedToggle={true} />
          </div>
        </div>
        <div className={styles.stepInput} style={{ backgroundColor: "#fff" }}>
          <div style={{ display: "flex", flexDirection: "column" }}>
            <div style={{ display: "flex", gap: "1rem" }}>
              <h4 className={styles.titleGeneral}>Include DTA/DTL</h4>
              <Tooltip infoText="Deferred Tax Assets / Deferred Tax Liabilities" title="DTA/DTL"></Tooltip>
            </div>
            {getShowValidation("DTA/DTL") && (
              <div className={styles.validationContainer} style={{ marginRight: "auto" }}>
                <h4 className={styles.validation} style={{ marginRight: "0.313rem", marginLeft: "0.313rem" }}>
                  {getLabelValidation("DTA/DTL")}
                </h4>
              </div>
            )}
          </div>
          <div className={styles.dobleInputContainer}>
            <InputCheckbox nameItem="DTA/DTL" />
            {<InputClip type="checkbox" nameItem="DTA/DTL" linkedToggle={true} />}
          </div>
        </div>
        <div className={styles.stepInput} style={{ backgroundColor: "#fff" }}>
          <div style={{ display: "flex", flexDirection: "column" }}>
            <h4 className={styles.titleGeneral} style={{ opacity: `${getInputValue("PGRC") ? 1 : 0.2}` }}>
              Perpetuity Growth Rate
            </h4>

            {getShowValidation("PGR") && (
              <div className={styles.validationContainer} style={{ marginRight: "auto" }}>
                <h4 className={styles.validation} style={{ marginRight: "0.313rem", marginLeft: "0.313rem" }}>
                  {getLabelValidation("PGR")}
                </h4>
              </div>
            )}
          </div>
          {!getInputValue("PGRC") && (
            <Tooltip
              right={true}
              infoText="Not Needed for Selected Valuation Methodologies"
              title="Perpetuity Growth Rate"
            ></Tooltip>
          )}
          {getInputValue("PGRC") && <InputClip type="text" placeholder="%" nameItem="PGR" />}
        </div>
        <div className={styles.stepInput} style={{ backgroundColor: "#fff" }}>
          <div style={{ display: "flex", flexDirection: "column" }}>
            <h4 className={styles.titleGeneral} style={{ opacity: `${getInputValue("TEMC") ? 1 : 0.2}` }}>
              Terminal EBITDA Multiple
            </h4>

            {getShowValidation("TEM") && (
              <div className={styles.validationContainer} style={{ marginRight: "auto" }}>
                <h4 className={styles.validation} style={{ marginRight: "0.313rem", marginLeft: "0.313rem" }}>
                  {getLabelValidation("TEM")}
                </h4>
              </div>
            )}
          </div>
          {!getInputValue("TEMC") && (
            <Tooltip
              right={true}
              infoText="Not Needed for Selected Valuation Methodologies"
              title="Terminal EBITDA Multiple"
            ></Tooltip>
          )}
          {getInputValue("TEMC") && <InputClip type="text" placeholder="x" nameItem="TEM" onTabPress={onTabPress} />}
        </div>
      </div>
    </div>
  );
};
export default FinalInputs;
