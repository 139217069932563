export const abreviaturasNombres: Record<string, string> = {
  IU: "Interface Units",
  VD: "Valuation Date",
  FCFY: "Final Cash Flow Year",
  MYC: "Mid-Year Convention",
  WACC: "WACC",
  COE: "Cost of Equity",
  CEDA: "Copex Equal to D&A",
  "DTA/DTL": "Include DTA/DTL",
  PGR: "Perpetuity Growth Rate",
  TEM: "Terminal EBITDA Multiple",
  YS: "Years Selection",
  EBI: "EBITDA",
  "D&A": "D&A",
  CX: "Capex",
  CNWC: "Change in NWC",
  IdD: "(Increase) / Decrease in DTA",
  iDD: "Increase / (Decrease) in DTL",
  NI: "Net Income",
  DIR: "Debt Issuance / (Repayment)",
  CCE: "Cash & Cash Equivalents",
  TD: "Total Debt",
  MI: "Minority Interest",
  WACCS: "WACC Step",
  DVDS: "Dividends",
  COES: "Cost of Equity Step",
  PGRS: "PGR Step",
  TEMS: "Terminal EBITDA Multiple Step",
};
export const percentageInputs = ["WACC", "COE", "PGR", "PGRS", "COES", "WACCS"];
export const secondStepItems = ["YS", "D&A", "CX", "EBI", "CNWC", "IdD", "iDD", "NI", "DIR", "DVDS"];
export const itsCheckboxInputs = ["MYC", "CEDA", "DTA/DTL"];
