import { makeStyles, shorthands } from "@fluentui/react-components";
export const valuationsStyles = makeStyles({
  root: {
    minHeight: "100vh",
    display: "flex",
    flexDirection: "column",
    justifyContent: "flex-start",
    width: "95%",
    marginLeft: "2.5%",
    marginTop: "1rem",
    fontFamily: "Poppins",
  },
  version: {
    position: "absolute",
    bottom: "0px",
    right: "0px",
  },
});

export const myValuationsStyles = makeStyles({
  stepContainer: {
    display: "flex",
    flexDirection: "row",
    justifyContent: "space-between",
    alignItems: "center",
  },

  titlesContainer: {
    display: "flex",
    flexDirection: "column",
    justifyContent: "flex-start",
    alignItems: "flex-start",
  },
  title: {
    marginBottom: "1.2rem",
    fontSize: "16px",
    fontWeight: "700",
  },
  finishedValuationsContainer: {
    display: "flex",
    flexDirection: "column",
    ...shorthands.gap("1rem"),
  },
});
export const frequentlyUsedStyles = makeStyles({
  stepContainer: {
    display: "flex",
    flexDirection: "column",
    alignItems: "flex-start",
    gap: "1rem",
    padding: "1rem",
  },
  title: {
    marginBottom: "1.2rem",
    fontSize: "16px",
    fontWeight: "700",
  },
  buttonContainer: {
    display: "flex",
    flexDirection: "row",
    width: "90%",
    cursor: "pointer",
    justifyContent: "flex-start",
    backgroundColor: "white",
    ...shorthands.padding("0.6rem"),
    ...shorthands.gap("0.8rem"),
    alignItems: "center",
  },
  text: {
    fontWeight: "700",
    fontSize: "12px",
  },
});

export const valuationsTabsStyles = makeStyles({
  container: {
    display: "flex",
    justifyContent: "flex-start",
    ...shorthands.gap("1rem"),
    alignItems: "center",
    marginBottom: "1.4rem",
  },
  button: {
    height: "24px",
    ...shorthands.borderRadius("2rem"),
    display: "flex",
    flexDirection: "row",
    justifyContent: "center",
    alignItems: "center",
    fontWeight: "600",
    ...shorthands.padding("0.3rem"),
    ...shorthands.border("1px", "solid", "#9A9AA2"),
    ...shorthands.outline("none"),
  },
  saveForLaterActive: {
    backgroundColor: "#A886F0",
    width: "119px",
    color: "white",
  },
  saveForLaterInactive: {
    backgroundColor: "transparent",
    width: "119px",
    color: "#4F5A62",
    cursor: "pointer",
  },
  finishedActive: {
    backgroundColor: "#A886F0",
    width: "68px",
    color: "white",
  },
  finishedInactive: {
    backgroundColor: "transparent",
    width: "68px",
    color: "#4F5A62",
    cursor: "pointer",
  },
  disabledButton: {
    opacity: "0.2",
    cursor: "not-allowed",
  },
});

export const valuationCardStyles = makeStyles({
  valuationsContainer: {
    backgroundColor: "#FBFBFE",
    ...shorthands.padding("1rem"),
    ...shorthands.borderRadius("0.5rem"),
  },
  cardHeader: {
    display: "flex",
    flexDirection: "row",
    ...shorthands.gap("0.8rem"),
    width: "100%",
    justifyContent: "space-between",
    alignItems: "center",
    marginRight: "1rem",
  },
  cardTitle: {
    display: "flex",
    flexDirection: "column",
    ...shorthands.gap("0rem"),
  },
  stepNumber: {
    fontSize: "1.2rem",
    fontWeight: "bold",
    ...shorthands.margin(0),
  },
  valuationName: {
    ...shorthands.margin(0),
    fontWeight: "600",
    fontSize: "14px",
  },
  subTitleValuations: {
    ...shorthands.margin(0),
    fontWeight: "400",
    fontSize: "12px",
    color: "#6c757d",
  },
  editButton: {
    display: "flex",
    justifyContent: "center",
    alignItems: "center",
    backgroundColor: "#4F5A62",
    color: "white",
    ...shorthands.borderRadius("2rem"),
    height: "25px",
    width: "75px",
    ...shorthands.border("none"),
    cursor: "pointer",
    ...shorthands.gap("0.3rem"),
  },
});

export const namingValuationStyles = makeStyles({
  root: {
    display: "flex",
    flexDirection: "column",
    justifyContent: "flex-start",
    alignItems: "center",
    marginTop: "100px",
    boxShadow: "0px 4px 32px 0px rgba(0, 0, 0, 0.09)",
    backgroundColor: "rgba(251, 251, 254, 1)",
    margin: "3rem 1rem",
    height: "18rem",
  },
  addValuationContainer: {
    display: "flex",
    flexDirection: "column",
    justifyContent: "center",
    alignItems: "center",
  },
  addValuationButton: {
    backgroundColor: "#F9F9F9",
    color: "rgba(0, 0, 0, 0.25)",
    height: "70px",
    width: "70px",
    cursor: "pointer",
    fontSize: "3rem",
    marginBottom: "1rem",
  },
  addValuationTitle: {
    color: "#232C33",
    textAlign: "center",
    fontSize: "0.875rem",
    fontStyle: "normal",
    fontWeight: "600",
    lineHeight: "normal",
  },
  valuationSubTitle: {
    color: "#9a9aa2",
    textAlign: "center",
    fontSize: "1rem",
    fontStyle: "normal",
    fontWeight: "500",
    lineHeight: "normal",
  },
  valuationTitle: {
    color: "#232C33",
    textAlign: "left",
    fontSize: "1.125rem",
    fontStyle: "normal",
    lineHeight: "normal",
    fontFamily: "Poppins",
    fontWeight: "700",
  },
  divider: {
    borderBottom: "1px solid rgba(1,1,1,10%)",
    height: "10px",
    width: "100%",
    maxWidth: "95%",
    marginBottom: "2rem",
    marginTop: "0",
  },
  inputContainer: {
    display: "flex",
    justifyContent: "space-between",
    height: "2rem",
    width: "100%",
    marginLeft: "3rem",
    gap: "1rem",
    alignItems: "center",
  },
  input: {
    height: "1.5rem",
    borderRadius: "0.25rem",
  },
  inputError: {
    border: "2px solid red",
  },
  inputDefault: {
    border: "2px solid #ca00ca",
  },
  buttonCreateValuation: {
    fontFamily: "Poppins",
    backgroundColor: "#232C33",
    color: "#fff",
    width: "10rem",
    height: "1.8rem",
    fontSize: "0.9375rem",
    cursor: "pointer",

    marginTop: "2rem",
    ...shorthands.border("none"),
  },
  buttonContainer: {
    display: "flex",
    width: "100%",
    marginLeft: "3rem",
  },
  titleContainer: {
    display: "flex",
    justifyContent: "flex-start",
    width: "100%",
    marginLeft: "3rem",
    paddingTop: "0.1rem",
  },
  close: {
    color: "black",
    fontSize: "1rem",
    fontFamily: "Poppins",
    position: "absolute",
    top: "7rem",
    right: "2rem",
    ...shorthands.border("none"),
    backgroundColor: "transparent",
    cursor: "pointer",
  },
});
