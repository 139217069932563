/* eslint-disable no-undef */
// utils.js
import fetch from "node-fetch";

export const createValuationDate = () => {
  const date = new Date();
  const day = date.getDate().toString().padStart(2, "0");
  const month = (date.getMonth() + 1).toString().padStart(2, "0"); // Los meses van de 0 a 11
  const year = date.getFullYear();
  return `${month}/${day}/${year}`;
};

export const handleFetchReports = async (BASE_URL, valuationName, inputs, setRoutingNumber) => {
  let responses = [];
  for (const input of inputs) {
    if (["UDCF", "LDCF", "DDM"].includes(input.name) && input.value) {
      try {
        const response = await fetch(`${BASE_URL}/reports/create/${input.name}`, {
          method: "POST",
          headers: {
            "Content-Type": "application/json",
          },
          body: JSON.stringify({ valuationName, inputs }),
        });
        const responseConverted = await response.json();
        responses.push(responseConverted);
      } catch (error) {
        setRoutingNumber(3);
        console.error("Error during fetch:", error);
        return []; // Exit the function if an error occurs
      }
    }
  }
  return responses;
};

export const processResponses = async (responses, setRoutingNumber, isDDM) => {
  try {
    for await (const response of responses) {
      await newReportBack(response, setRoutingNumber, isDDM);
    }
  } catch (error) {
    setRoutingNumber(5);
    console.error("Error during newReportBack:", error);
    return false; // Exit the function if an error occurs
  }
  return true;
};

export const exists = (value) => value !== undefined && value !== null;

export const getBorderIndex = (side) => {
  switch (side) {
    case "top":
      return 0;
    case "bottom":
      return 1;
    case "left":
      return 2;
    case "right":
      return 3;
    default:
      return 0;
  }
};

export const PositionReport = {
  UDCF: 28,
  LDCF: 30,
  DDM: 18,
};

export const addNewItems = async (IU, additionalItemExtra, nameReport) => {
  await Excel.run(async (context) => {
    try {
      const sheet = context.workbook.worksheets.getItem(nameReport);
      let counter = 1;

      for (let key in additionalItemExtra) {
        const numberRows = counter + PositionReport[nameReport];
        sheet.getRange(`${numberRows}:${numberRows}`).insert(Excel.InsertShiftDirection.down);

        const newRange = sheet.getRange(`B${numberRows}:BB${numberRows}`);
        const values = Array(53).fill("");
        values[0] = key;
        values[1] = IU;
        const value = additionalItemExtra[key];
        const valueEnd = Array.isArray(value) ? value.map((val) => `=${val}`) : [`=${value}`];
        values.splice(6, valueEnd.length, ...valueEnd);

        newRange.values = [values];
        counter++;
      }
      context.workbook.application.calculate("Recalculate");
      return await context.sync();
    } catch (error) {
      console.log(":rocket: ~ await Excel.run ~ error:", error);
    }
  });
};

export const formatting = async (data, nameSheet, setRoutingNumber) => {
  try {
    await Excel.run(async (context) => {
      const sheets = context.workbook.worksheets;
      const sheet = sheets.getItem(nameSheet);
      sheet.showGridlines = false;
      if (data.printArea) {
        const printAreaRanges = sheet.getRanges(data.printArea);
        sheet.pageLayout.setPrintArea(printAreaRanges);
      }
      console.log(sheet, "sheet name");
      for (const column in data.columnsWidth) {
        sheet.getRangeByIndexes(0, parseInt(column) - 1, 1, 1).getColumn(0).format.columnWidth =
          data.columnsWidth[column];
      }

      for (const row in data.rowsHeight) {
        sheet.getRangeByIndexes(parseInt(row) - 1, 0, 1, 1).format.rowHeight = data.rowsHeight[row];
      }

      data.ranges.base.forEach(({ style, range: rangeKey }) => {
        const range = sheet.getRange(rangeKey);
        const fill = style.fill;
        const font = style.font;
        const numberFormat = style.numberFormat;
        const borders = style.borders;
        const alignment = style.alignment;

        if (alignment) {
          if (alignment.horizontal) range.format.horizontalAlignment = alignment.horizontal;
          if (alignment.vertical) range.format.verticalAlignment = alignment.vertical;
        }

        if (font) {
          if (exists(font.name)) range.format.font.name = font.name;
          if (exists(font.size)) range.format.font.size = font.size;
          if (exists(font.color)) range.format.font.color = font.color;
          if (exists(font.bold)) range.format.font.bold = font.bold === "true";
          if (exists(font.italic)) range.format.font.italic = font.italic === "true";
          if (exists(font.underline)) range.format.font.underline = font.underline;
        }
        if (fill) range.format.fill.color = fill.color;
        if (numberFormat) range.numberFormat = [[numberFormat]];
        if (borders) {
          for (const borderKey in borders) {
            const border = borders[borderKey];
            const currentBorder = range.format.borders.getItemAt(getBorderIndex(borderKey));

            if (border.color) currentBorder.color = border.color;
            currentBorder.style = border.style;
            currentBorder.weight = border.weight;
          }
        }
      });
      sheet.activate();
      await context.sync();
    });
  } catch (error) {
    setRoutingNumber(3);
    console.error("Error al formatear la hoja de cálculo:", error);
  }
};

export const formattingCustom = async (data, nameSheet, setRoutingNumber) => {
  try {
    await Excel.run(async (context) => {
      const sheets = context.workbook.worksheets;
      const sheet = sheets.getItem(nameSheet);
      sheet.showGridlines = false;
      data.ranges.custom.forEach(({ style, range: rangeKey }) => {
        const range = sheet.getRange(rangeKey);
        const fill = style.fill;
        const font = style.font;
        const numberFormat = style.numberFormat;
        const borders = style.borders;
        const alignment = style.alignment;

        if (alignment) {
          if (alignment.horizontal) range.format.horizontalAlignment = alignment.horizontal;
          if (alignment.vertical) range.format.verticalAlignment = alignment.vertical;
        }

        if (font) {
          if (exists(font.name)) range.format.font.name = font.name;
          if (exists(font.size)) range.format.font.size = font.size;
          if (exists(font.color)) range.format.font.color = font.color;
          if (exists(font.bold)) range.format.font.bold = font.bold === "true";
          if (exists(font.italic)) range.format.font.italic = font.italic === "true";
          if (exists(font.underline)) range.format.font.underline = font.underline;
        }
        if (fill) range.format.fill.color = fill.color;
        if (numberFormat) range.numberFormat = [[numberFormat]];
        if (borders) {
          for (const borderKey in borders) {
            const border = borders[borderKey];
            const currentBorder = range.format.borders.getItemAt(getBorderIndex(borderKey));

            if (border.color) currentBorder.color = border.color;
            currentBorder.style = border.style;
            currentBorder.weight = border.weight;
          }
        }
      });
      sheet.activate();
      await context.sync();
    });
  } catch (error) {
    setRoutingNumber(3);
    console.error("Error al formatear la hoja de cálculo:", error);
  }
};

async function updateColumnsSize(nameSheet, resultRange) {
  await Excel.run(async (context) => {
    const sheets = context.workbook.worksheets;
    const sheet = sheets.getItem(nameSheet);
    resultRange.map((value) => {
      sheet.getRange(value).getEntireColumn().format.autofitColumns();
    });
    return await context.sync();
  });
}
export const newReportBack = async (data, setRoutingNumber) => {
  await Excel.run(async (context) => {
    try {
      let sheets = context.workbook.worksheets;
      sheets.load("items/name");
      await context.sync();
      sheets.items.forEach((sheet) => {
        if (sheet.name === data.name) {
          context.workbook.worksheets.getItem(data.name).delete();
        }
      });

      let sheetDCF = sheets.add(data.name);
      sheetDCF.tabColor = "#bfe1b6";
      await context.sync();
      try {
        const { resultReport, styles, name } = data;
        let cellRangeDescription = sheetDCF.getRangeByIndexes(
          resultReport.startRow,
          resultReport.startColumn,
          resultReport.rowCount,
          resultReport.columnCount
        );
        cellRangeDescription.values = resultReport.values;

        context.workbook.application.calculate(Excel.CalculationType.full);
        await context.sync();
        await formatting(styles, name, setRoutingNumber);
        if (styles.deletedRows !== "0") {
          const range = sheetDCF.getRange(styles.deletedRows);
          range.delete(Excel.DeleteShiftDirection.up);
          await context.sync();
        }
        if (name !== "DDM") {
          addNewItems(data.IU, data.additionalItemExtra, name);
        }
        await formattingCustom(styles, name, setRoutingNumber);
        updateColumnsSize(name, styles.getRangesAutofitColumn);
        sheet.getEntireColumn().format.autofitColumns();
        context.application.calculationMode = Excel.CalculationMode.manual;
      } catch (error) {
        console.log(":rocket: ~ awaitExcel.run ~ error:", error);
      }
    } catch (error) {
      setRoutingNumber(3);
      console.log(":rocket: ~ mainDCFUnlevered ~ error:", error);
    }
  });
};
