/* eslint-disable no-undef */
import {
  setCommonValues,
  validateInput,
  handleSecondStepInputs,
  handleSpecialCases,
  setInputEmpty,
  handleVDInput,
} from "./validationHelpers";
import { validationByName } from "../Validations/ValidationByName";
import { abreviaturasNombres, itsCheckboxInputs, percentageInputs, secondStepItems } from "../helpers/namesInputs";
import useStoreInputs from "../store/globalStates";

const {
  getInputValue,
  setColorInputFont,
  setCelda,
  setCeldaWithSheet,
  setLabelValidation,
  setValidationByName,
  getCelda,
  getColorInputFont,
  setValueByName,
  setShowValidation,
  getShowValidation,
  getInputValidation,
  getWarning,
} = useStoreInputs.getState();

export const handleSelection = async (nameItem, setInputValueLocal) => {
  await Excel.run(async (context) => {
    Office.context.document.bindings.addFromPromptAsync(
      Office.BindingType.Matrix,
      { id: nameItem, promptText: configureTitleModal(nameItem) },
      async (asyncResult) => {
        if (asyncResult.status === Office.AsyncResultStatus.Succeeded) {
          try {
            window.focus();
            const binding = context.workbook.bindings.getItem(nameItem);
            const selectedRange = binding.getRange().load(["address", "rowIndex"]);
            await context.sync();
            const rangeAddress = selectedRange.address;
            // Usa la función para obtener el nombre de la hoja y el rango
            const { sheetName, range } = extractSheetNameAndRange(rangeAddress);

            if (!sheetName || !range) {
              console.error("Error al extraer el nombre de la hoja o el rango:", rangeAddress);
              return;
            }
            const sheet = context.workbook.worksheets.getItem(sheetName);
            const celdas = sheet.getRange(range);
            celdas.load("text");
            await context.sync();
            const value = String(celdas.text[0][0]);
            const valueArray = celdas.text[0];
            setColorInputFont(nameItem, true);
            setCelda(nameItem, range);
            setCeldaWithSheet(nameItem, rangeAddress);
            setLabelValidation(nameItem, "");
            setValidationByName(nameItem, true);

            if (secondStepItems.includes(nameItem)) {
              handleSecondStepInputs(validationByName, nameItem, valueArray);
              handleSpecialCases(nameItem, getInputValue(nameItem), validationByName, true, setInputValueLocal);
              const itemValue = getInputValue(nameItem);
              if (getColorInputFont(nameItem) === true && Array.isArray(itemValue) && itemValue.length > 1) {
                return setInputValueLocal(separatorValues(itemValue));
              } else {
                return setInputValueLocal(itemValue);
              }
            }
            setValueByName(nameItem, value);
            setInputValueLocal(value);
            validateInput(validationByName, nameItem, getInputValue(nameItem));
            handleSpecialCases(nameItem, getInputValue(nameItem), validationByName, true, setInputValueLocal);
            if (percentageInputs.includes(nameItem)) {
              if (Number(value) < 1) {
                const percentage = `${Number(value) * 100}%`;
                setValueByName(nameItem, percentage);
              }
              setValueByName(nameItem, formatValue(value, nameItem));
              setInputValueLocal(formatValue(value, nameItem));
            }
          } catch (error) {
            console.error("Error accessing the binding range:", error);
          }
        } else {
          console.error("Binding creation failed:", asyncResult.error.message);
        }
      }
    );
  });
};
export const handleOnChange = (e: any, nameItem: string, setInputValueLocal: (value: string) => void) => {
  let value = e.target.value;
  let inputType = e.nativeEvent.inputType;

  // Manejar el caso específico para "VD"
  if (nameItem === "VD" && inputType !== "deleteContentBackward") {
    setColorInputFont(nameItem, false);
    return setInputValueLocal(handleVDInput(value));
  }
  // Procesar el valor
  processInputValue(nameItem, value, false, setInputValueLocal);
};

export const handleOnBlur = (e, nameItem, setInputValue) => {
  const value = e.target.value;
  if (["WACC", "COE", "PGR", "PGRS", "COES", "WACCS"].includes(nameItem)) {
    if (value.includes("%") && getCelda(nameItem) !== "") {
      const numberValue = parseFloat(value).toFixed(2);
      return numberValue;
    }
    const numberValue = parseFloat(value);
    if (!isNaN(numberValue)) {
      return setInputValue(numberValue.toFixed(2) + "%");
    }
  }
  if (["TEM", "TEMS"].includes(nameItem)) {
    const numberValue = parseFloat(value);
    if (!isNaN(numberValue)) {
      return setInputValue(numberValue.toFixed(2) + "x");
    }
  }
};
export const separatorValues = (array) => {
  if (array.length === 0) return array;

  // Procesar el primer elemento, verificando si es una cadena antes de aplicar trim()
  const firstValue = typeof array[0] === "string" ? array[0].trim() : array[0];

  // Procesar el último elemento del array
  const lastValue = array[array.length - 1];

  return `${firstValue}:${lastValue}`;
};
export const extractSheetNameAndRange = (celdaWithSheet) => {
  const lastExclamationIndex = celdaWithSheet.lastIndexOf("!");
  let sheetName = "";
  let range = "";

  if (lastExclamationIndex !== -1) {
    // Extrae el nombre de la hoja y el rango de celdas
    sheetName = celdaWithSheet.substring(0, lastExclamationIndex);
    range = celdaWithSheet.substring(lastExclamationIndex + 1);

    // Elimina las comillas simples que rodean el nombre de la hoja
    if (sheetName.startsWith("'") && sheetName.endsWith("'")) {
      sheetName = sheetName.slice(1, -1);
    }
  } else {
    console.error("Formato de celdaWithSheet no válido:", celdaWithSheet);
  }

  return { sheetName: sheetName.trim(), range: range.trim() };
};

export const formatValue = (value, nameItem) => {
  // Si el valor es null, undefined o un objeto vacío, devolvemos una cadena vacía
  if (value === null || value === undefined || (typeof value === "object" && Object.keys(value).length === 0)) {
    return "";
  }
  if (secondStepItems.includes(nameItem)) {
    if (Array.isArray(value) && value.length > 1 && getColorInputFont(nameItem) === true) {
      return separatorValues(getInputValue(nameItem));
    }
  }
  if (Array.isArray(value)) {
    return value.join(";");
  }
  if (percentageInputs.includes(nameItem)) {
    if (value.includes("%") && getCelda(nameItem) !== "") {
      const numberValue = parseFloat(value).toFixed(2);
      return numberValue + "%";
    }
    const numberValue = parseFloat(value);
    if (!isNaN(numberValue)) {
      return numberValue.toFixed(2) + "%";
    }
  }
  if (["TEM", "TEMS"].includes(nameItem)) {
    const numberValue = parseFloat(value);
    if (!isNaN(numberValue)) {
      return numberValue.toFixed(2) + "x";
    }
  }
  return value;
};

export const getSheetName = (rangeAddress) => {
  const parts = rangeAddress.split("!");
  return parts[0];
};

export const configureTitleModal = (nameItem) => {
  const nombre = abreviaturasNombres[nameItem];
  return nombre ? nombre : nameItem;
};

export const handleKeyDown = (
  event: React.KeyboardEvent<HTMLInputElement>,
  nameItem,
  onTabPress,
  setInputValueLocal
) => {
  // Verificar si se presionó la tecla Tab
  if (event.key === "Tab" && (nameItem === "TEM" || nameItem === "MI" || nameItem === "TEMS")) {
    event.preventDefault(); // Evitar que se pierda el foco al presionar Tab
    if (onTabPress) {
      return onTabPress(); // Llamar a la función pasada por props para manejar el evento Tab
    }
  }
  // Verificar si se presionó Alt+Enter
  if (event.altKey && event.key === "Enter") {
    // Activar la visibilidad del modal
    // setModalVisible(true, nameItem);
    handleSelection(nameItem, setInputValueLocal);
  }

  if (itsCheckboxInputs.includes(nameItem)) {
    if (event.key === " ") {
      if (getInputValue(nameItem) === "0") {
        setValueByName(nameItem, "1");
      } else if (getInputValue(nameItem) === "1") {
        setValueByName(nameItem, "0");
      }
    }
  }
};
export function getInputStyles({ disabled, nameItem }) {
  const styles: React.CSSProperties = {
    paddingRight: "35%", // Siempre presente
    backgroundColor: !disabled ? "rgba(225, 225, 227, 1)" : undefined,
  };

  // Validación del input, muestra un borde rojo si es inválido
  if (!disabled && getInputValidation(nameItem) === false && getShowValidation(nameItem)) {
    styles.border = "1px solid red";
  }

  // Condicional para el item CX, muestra un borde dorado si es válido pero con advertencia
  if (!disabled && nameItem === "CX" && getInputValidation("CX") && getWarning("CX")) {
    styles.border = "1px solid rgba(255, 210, 106, 0.5)";
  }

  // Condicional para cambiar el fondo y color si getCelda(nameItem) no está vacío
  if (!disabled && getCelda(nameItem) !== "" && getColorInputFont(nameItem) === true) {
    styles.backgroundColor = "rgba(95, 128, 63, 0.3)";
    styles.color = "rgba(95, 128, 63, 1)";
  }

  // Condicional para cambiar el fondo y color si getCelda(nameItem) está vacío
  if (!disabled && getCelda(nameItem) === "" && getColorInputFont(nameItem) === false) {
    styles.backgroundColor = "rgba(77, 116, 190, 0.2)";
    styles.color = "rgba(77, 116, 190, 1)";
  }

  return styles;
}
export const processInputValue = (
  nameItem: string,
  value: string | string[],
  selectFromModal: boolean,
  setInputValueLocal?: (value: string) => void
) => {
  // Oculta la validación si es necesario
  setShowValidation(nameItem, false);
  // Si es un array, conviértelo a una cadena separada por ";"
  const finalValue = Array.isArray(value) ? value.join(";") : value;
  // Configura el valor local si la función está disponible
  if (setInputValueLocal) {
    setInputValueLocal(finalValue);
  }

  // Si el valor está vacío, marca el input como vacío
  if (finalValue === "") {
    setInputEmpty(nameItem);
    return;
  }
  if (!selectFromModal) {
    setCommonValues(nameItem);
  }
  // Establece valores comunes
  setValueByName(nameItem, finalValue);
  setValidationByName(nameItem, validationByName(nameItem, getInputValue(nameItem)));

  if (secondStepItems.includes(nameItem)) {
    handleSecondStepInputs(validationByName, nameItem, finalValue);
    handleSpecialCases(nameItem, getInputValue(nameItem), validationByName, selectFromModal, setInputValueLocal);
    const itemValue = getInputValue(nameItem);
    if (selectFromModal) {
      setColorInputFont(nameItem, true);
    } else setColorInputFont(nameItem, false);
    if (getColorInputFont(nameItem) === true && Array.isArray(itemValue) && itemValue.length > 1) {
      return setInputValueLocal(separatorValues(itemValue));
    } else {
      return setInputValueLocal(itemValue.join(";"));
    }
  }

  // Maneja casos especiales
  handleSpecialCases(nameItem, finalValue, selectFromModal, validationByName);
};
