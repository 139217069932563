import React from "react";
import noCompatibleStyles from "../css/noCompatible.js";

export default function NoCompatible() {
  const styles = noCompatibleStyles();
  return (
    <div className={styles.container}>
      <svg width="81" height="83" viewBox="0 0 81 83" fill="none" xmlns="http://www.w3.org/2000/svg">
        <path
          d="M54.4511 59.6774C57.1335 57.7402 58.9386 54.7966 59.3898 51.4504H49.713C50.1141 54.7462 51.8439 57.715 54.4511 59.6774Z"
          fill="black"
        />
        <path
          d="M79.3705 33.21H56.2063V36.531C56.2063 39.2733 53.9751 41.5125 51.2425 41.5125H28.0783V71.3764C28.0783 72.2822 28.8304 73.037 29.7329 73.037H63.1004L73.2535 82.5471C73.5794 82.8239 73.9555 83 74.3817 83C74.6073 83 74.8329 82.9497 75.0335 82.849C75.6351 82.5723 76.0362 81.9936 76.0362 81.3395V73.037H79.3454C80.2479 73.037 81 72.2822 81 71.3764V34.8705C81 33.9396 80.2479 33.21 79.3454 33.21H79.3705Z"
          fill="#B989F0"
        />
        <path d="M26.4485 12.001L23.3398 18.2656H29.5571L26.4485 12.001Z" fill="#A286E8" />
        <path
          d="M4.96376 38.1665V44.8085C4.96376 45.4375 5.3398 46.0161 5.89133 46.2929C6.11695 46.4187 6.36765 46.469 6.61834 46.469C6.99439 46.469 7.34536 46.3432 7.64619 46.0916L17.8244 38.1665H51.2671C52.1696 38.1665 52.9217 37.4117 52.9217 36.506V1.66051C52.9217 0.729616 52.1696 0 51.2671 0H1.65459C0.752084 0 0 0.729616 0 1.66051V36.506C0 37.4117 0.752084 38.1665 1.65459 38.1665H4.96376ZM15.0668 27.4738L24.9943 7.54775C25.5458 6.41559 27.4009 6.41559 27.9525 7.54775L37.88 27.4738C38.2811 28.2789 37.9552 29.2853 37.1279 29.6878C36.8772 29.8136 36.6265 29.8639 36.3758 29.8639C35.7742 29.8639 35.1976 29.5369 34.8967 28.9582L31.2366 21.5614H21.7102L18.05 28.9582C17.6489 29.7633 16.6461 30.1155 15.8439 29.713C15.0166 29.2853 14.6907 28.2789 15.0918 27.4738H15.0668Z"
          fill="#A286E8"
        />
        <path
          d="M58.6129 54.1929C58.6129 56.3314 56.8831 58.0674 54.7522 58.0674C52.6213 58.0674 50.8915 56.3314 50.8915 54.1929C50.8915 52.0543 52.6213 50.3184 54.7522 50.3184C56.8831 50.3184 58.6129 52.0543 58.6129 54.1929ZM66.3594 50.3184C64.2285 50.3184 62.4987 52.0543 62.4987 54.1929C62.4987 56.3314 64.2285 58.0674 66.3594 58.0674C68.4903 58.0674 70.2201 56.3314 70.2201 54.1929C70.2201 52.0543 68.4903 50.3184 66.3594 50.3184ZM43.1701 50.3184C41.0392 50.3184 39.3094 52.0543 39.3094 54.1929C39.3094 56.3314 41.0392 58.0674 43.1701 58.0674C45.301 58.0674 47.0308 56.3314 47.0308 54.1929C47.0308 52.0543 45.301 50.3184 43.1701 50.3184Z"
          fill="white"
        />
      </svg>
      <div className={styles.title}>Language Compatibility</div>
      <div className={styles.subtitle}>Please switch it to English to use the plugin</div>
    </div>
  );
}
