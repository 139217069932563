import { makeStyles, shorthands } from "@fluentui/react-components";

export const stylesSteps2 = makeStyles({
  root: {
    display: "flex",
    width: "100%",
    flexDirection: "column",
    justifyContent: "flex-start",
    ...shorthands.gap("1rem"),
  },
  stepInput: {
    display: "flex",
    flexDirection: "row",
    justifyContent: "space-between",
    alignItems: "center",
    paddingLeft: "1rem",
    paddingRight: "1rem",
    ...shorthands.padding("0.625rem"),
    // height: '2.625rem'
    height: "auto",
  },
  subStep: {
    display: "flex",
    flexDirection: "row",
    justifyContent: "space-between",
    alignItems: "center",
    marginBottom: "1rem",
    marginTop: "1rem",
  },
  subStepTitle: {
    color: "#9A9AA2",
    fontSize: "0.875rem",
    fontWeight: "400",
    marginBottom: "0px",
    marginTop: "0px",
    fontFamily: "Poppins",
  },

  stepOneContainers: {
    display: "flex",
    flexDirection: "column",
    ...shorthands.gap("0.1rem"),
  },
  titleGeneral: {
    fontFamily: "Poppins",
    fontWeight: "500",
    fontSize: "0.75rem",
    lineHeight: "1.125rem",
    color: "#232C33",
    ...shorthands.margin("0"),
  },
  yearContainer: {
    height: "1.875rem",
    display: "flex",
    justifyContent: "space-between",
    alignItems: "center",
    ...shorthands.margin("0.5rem"),
  },
  validation: {
    fontFamily: "Arial",
    fontWeight: "400",
    fontSize: "0.625rem",
    ...shorthands.margin("0"),
    textAlign: "left",
    lineHeight: "1rem",
  },
  validationContainer: {
    backgroundColor: "rgba(237, 93, 88, 0.5)",
    height: "auto",
    minWidth: "4rem",
    maxWidth: "10rem",
    ...shorthands.borderRadius("4px"),
  },
  buttonNewLineContainer: {
    display: "flex",
    justifyContent: "center",
    marginTop: "1rem",
  },
  buttonNewLine: {
    ...shorthands.border("none"),
    backgroundColor: "transparent",
    fontFamily: "Poppins",
    fontWeight: "bold",
    textAlign: "center",
    color: "#79caf5",
    cursor: "pointer",
  },
  buttonNewLineDisabled: {
    ...shorthands.border("none"),
    backgroundColor: "transparent",
    fontFamily: "Poppins",
    fontWeight: "bold",
    textAlign: "center",
    color: "gray",
    cursor: "not-allowed",
  },
  close: {
    color: "#fff",
    fontSize: "1rem",
    fontFamily: "Poppins",
    ...shorthands.border("none"),
    backgroundColor: "#9a9a9a",
    cursor: "pointer",
    width: "1.8rem",
  },
});
