import React, { forwardRef } from "react";
import { buttonStyles } from "../../../css/buttons";
import useStoreInputs from "../../store/globalStates";

interface NextButtonProps {
  handleNext: () => void;
}

const NextButton = forwardRef<HTMLButtonElement, NextButtonProps>(({ handleNext }, ref) => {
  const styles = buttonStyles();
  const { getInputValue } = useStoreInputs((state) => state);

  return (
    <button
      tabIndex={-1}
      disabled={getInputValue("UDCF") || getInputValue("LDCF") || getInputValue("DDM") ? false : true}
      className={`${
        getInputValue("UDCF") || getInputValue("LDCF") || getInputValue("DDM")
          ? styles.buttonNext
          : styles.buttonNextDisabled
      }`}
      style={{ borderRadius: "0.25rem" }}
      onClick={handleNext}
      ref={ref}
      id="button-next"
    >
      Next
    </button>
  );
});

NextButton.displayName = "NextButton"; // Añadir el nombre de display

export default NextButton;
