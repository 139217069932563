import * as React from "react";
import useStoreInputs from "../store/globalStates";
import { inputCheckboxStyles } from "../../css/inputCheckbox";
interface InputClipProps {
  nameItem?: string;
}

const InputCheckbox: React.FC<InputClipProps> = ({ nameItem }) => {
  const styles = inputCheckboxStyles();
  const { setValidationByName, setValueByName, getInputValue, setColorInputFont } = useStoreInputs((state) => state);
  const handleToggle = () => {
    const currentValue = getInputValue(nameItem);
    const newValue = currentValue === "0" ? "1" : "0";
    setValueByName(nameItem, newValue);
    setColorInputFont(nameItem, false);
    setValidationByName(nameItem, true);
  };

  return (
    <div
      id={`checkbox-${nameItem}`}
      className={`${styles.checkbox} ${getInputValue(nameItem) === "0" ? styles.checkboxOff : styles.checkboxOn} ${
        getInputValue(nameItem) === "0" ? styles.CheckboxTextOff : styles.checkboxTextOn
      }`}
      style={{ transition: "background-color 0.5s" }}
      onClick={() => {
        handleToggle();
      }}
    >
      <div
        className={`${styles.checkboxBall} ${
          getInputValue(nameItem) === "0" ? styles.checkboxBallOn : styles.checkboxBallOff
        }`}
        style={{ transition: "left 0.5s" }}
      ></div>
      <span
        className={styles.checkboxText}
        style={getInputValue(nameItem) === "0" ? { marginRight: "0.3rem" } : { marginLeft: "0.3rem" }}
      >
        {getInputValue(nameItem) === "0" ? "OFF" : "ON"}
      </span>
    </div>
  );
};
export default InputCheckbox;
