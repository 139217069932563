/* eslint-disable no-undef */
import React, { forwardRef } from "react";
import { buttonStyles } from "../../../css/buttons";
import { useStoreSteps } from "../../store/globalStates";
// import { Valuation } from "../../types/types";
import FinishButton from "./FinishButton";
import NextButton from "./NextButton";
import BackButton from "./BackButton";
import SaveForLaterButton from "./SaveForLaterButton";

interface ButtonsContainerProps {
  backIsPressed: () => void;
  handleNext: () => void;
  refFinish: React.RefObject<HTMLButtonElement>;
}
const ButtonsContainer = forwardRef<HTMLButtonElement, ButtonsContainerProps>(
  ({ backIsPressed, handleNext, refFinish }, ref) => {
    const styles = buttonStyles();
    const { stepNumber } = useStoreSteps((state) => state);
    return (
      <>
        <div className={styles.containerButtons}>
          <BackButton backIsPressed={backIsPressed} />
          <div style={{ display: "flex", gap: "1rem" }}>
            <SaveForLaterButton />
            {stepNumber === 3 ? (
              <FinishButton refFinish={refFinish} />
            ) : (
              <NextButton handleNext={handleNext} ref={ref} />
            )}
          </div>
        </div>
      </>
    );
  }
);

ButtonsContainer.displayName = "ButtonsContainer";
export default ButtonsContainer;
