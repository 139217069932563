import React from "react";
export default function TooltipImage() {
  return (
    <svg width="14" height="15" viewBox="0 0 14 15" fill="none" xmlns="http://www.w3.org/2000/svg">
      <g clipPath="url(#clip0_1685_4838)">
        <path
          d="M6.83901 13.4808C9.98668 13.4808 12.5384 10.9291 12.5384 7.78145C12.5384 4.63378 9.98668 2.08209 6.83901 2.08209C3.69134 2.08209 1.13965 4.63378 1.13965 7.78145C1.13965 10.9291 3.69134 13.4808 6.83901 13.4808Z"
          stroke="#636366"
          strokeWidth="1.13987"
          strokeLinecap="round"
          strokeLinejoin="round"
        />
        <path
          d="M5.18066 6.07162C5.31466 5.69072 5.57914 5.36952 5.92725 5.16493C6.27537 4.96034 6.68467 4.88555 7.08264 4.95381C7.48062 5.02208 7.84159 5.22899 8.10163 5.53789C8.36167 5.8468 8.50399 6.23777 8.50339 6.64156C8.50339 7.78143 6.79358 8.35137 6.79358 8.35137"
          stroke="#636366"
          strokeWidth="1.13987"
          strokeLinecap="round"
          strokeLinejoin="round"
        />
        <path
          d="M6.83936 10.6311H6.84551"
          stroke="#636366"
          strokeWidth="1.13987"
          strokeLinecap="round"
          strokeLinejoin="round"
        />
      </g>
      <defs>
        <clipPath id="clip0_1685_4838">
          <rect width="13.6785" height="13.6785" fill="white" transform="translate(0 0.9422)" />
        </clipPath>
      </defs>
    </svg>
  );
}
