import { makeStyles, shorthands } from "@fluentui/react-components";

export const stylesSteps = makeStyles({
  root: {
    display: "flex",
    width: "90%",
    flexDirection: "column",
    justifyContent: "flex-start",
    marginLeft: "0.4rem",
    ...shorthands.gap("10px"),
  },
  stepCheckbox: {
    display: "flex",
    flexDirection: "row",
    justifyContent: "flex-start",
    alignItems: "center",
    ...shorthands.gap("0.6rem"),
    ...shorthands.padding("0.375rem"),
  },
  titlesCheckbox: {
    display: "flex",
    flexDirection: "column",
    alignItems: "flex-start",
    justifyContent: "center",
  },
  titleCheckbox: {
    ...shorthands.margin("0px"),
    fontFamily: "Poppins",
    fontWeight: "600",
    fontSize: "10px",
    lineHeight: "1.125rem",
  },
  subTitleCheckbox: {
    ...shorthands.margin("0px"),
    fontFamily: "Poppins",
    fontWeight: "400",
    fontSize: "8px",
    color: "#000",
    lineHeight: "1rem",
  },
  stepInput: {
    display: "flex",
    flexDirection: "row",
    justifyContent: "space-between",
    alignItems: "center",
    paddingLeft: "1rem",
    paddingRight: "1rem",
    ...shorthands.padding("0.4rem"),
    // height: '2.625rem'
    height: "auto",
  },
  dobleInputContainer: {
    display: "flex",
    flexDirection: "row",
    alignItems: "center",
    justifyContent: "space-between",
    ...shorthands.gap("0.3rem"),
    width: "8.1rem",
  },

  subStepTitle: {
    color: "#9A9AA2",
    fontSize: "12px",
    fontWeight: "400",
    marginBottom: "0px",
    marginTop: "0px",
    fontFamily: "Poppins",
  },

  generalTitle: {
    display: "flex",
    justifyContent: "space-between",
    height: "1.5rem",
  },

  stepOneContainers: {
    display: "flex",
    flexDirection: "column",
    ...shorthands.gap("0.1rem"),
  },
  titleGeneral: {
    fontFamily: "Poppins",
    fontWeight: "500",
    fontSize: "10px",
    lineHeight: "1.125rem",
    color: "#232C33",
    ...shorthands.margin("0"),
  },
  checkboxStep3: {
    minHeight: "0.921rem",
    minWidth: "0.921rem",
    accentColor: "black",
  },
  validation: {
    fontFamily: "Arial",
    fontWeight: "400",
    fontSize: "0.625rem",
    ...shorthands.margin("0"),
    textAlign: "left",
    lineHeight: "1rem",
  },
  validationContainer: {
    backgroundColor: "rgba(237, 93, 88, 0.5)",
    height: "auto",
    minWidth: "4rem",
    maxWidth: "10rem",
    ...shorthands.borderRadius("4px"),
  },
});
