import { makeStyles, shorthands } from "@fluentui/react-components";

export const buttonStyles = makeStyles({
  containerButtons: {
    display: "flex",
    flexDirection: "row",
    justifyContent: "space-between",
    marginLeft: "1rem",
    marginBottom: "1rem",
    position: "fixed",
    bottom: "0",
    backgroundColor: "rgb(242, 243, 245)",
    margin: "0",
    alignItems: "start",
    paddingTop: "0.5rem",
    zIndex: "120",
    transform: "translate(0,50%)",
    width: "95%",
    height: "50px",
  },
  buttonBack: {
    backgroundColor: "transparent",
    color: "#232C33",
    width: "3.625rem",
    height: "1.813rem",
    fontSize: "0.9375rem",
    fontWeight: "400",
    cursor: "pointer",
    ...shorthands.border("1px solid #232C33"),
  },
  buttonSave: {
    backgroundColor: "#E1E1E3",
    color: "#232C33",
    width: "7.1rem",
    height: "1.8rem",
    fontSize: "0.9375rem",
    cursor: "pointer",
    ...shorthands.border("none"),
  },
  buttonNext: {
    backgroundColor: "#232C33",
    color: "#fff",
    width: "3.4rem",
    height: "1.8rem",
    fontSize: "0.9375rem",
    cursor: "pointer",
    ...shorthands.border("none"),
  },
  buttonNextDisabled: {
    backgroundColor: "#C2C2C7",
    color: "#fff",
    width: "3.4rem",
    height: "1.8rem",
    fontSize: "0.9375rem",
    cursor: "not-allowed",
    ...shorthands.border("none"),
  },
});
