import React from "react";
import useStoreInputs, { useStoreRoutingService, useStoreSteps, useStoreValuations } from "../../store/globalStates";
import { frequentlyUsedStyles } from "../../../css/valuations";
import ValuationIcon from "../../assets/Valuations/ValuationIcon";

export default function FrequentlyUsed() {
  const { setDefaultInputs } = useStoreInputs((state) => state);
  const { setStepNumber } = useStoreSteps((state) => state);
  const { setRoutingNumber } = useStoreRoutingService((state) => state);
  const { setEditValuation } = useStoreValuations((state) => state);
  const styles = frequentlyUsedStyles();

  return (
    <div className={styles.stepContainer}>
      <div className={styles.titlesContainer}>
        <h2 className={styles.title}>Frequently Used</h2>
      </div>
      <div
        className={styles.buttonContainer}
        style={{ borderRadius: "0.5rem", boxShadow: "0 2px 4px rgba(0, 0, 0, 0.1)" }}
        onClick={() => {
          setRoutingNumber(1);
          setStepNumber(1);
          setDefaultInputs();
          setEditValuation(false);
        }}
        id="button-create-valuation"
      >
        <ValuationIcon />
        <p className={styles.text}>Valuations</p>
      </div>
    </div>
  );
}
