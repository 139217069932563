import * as React from "react";
import YearsSelection from "./YearsSelection";
import BalanceSheetItems from "./BalanceSheetItems";
import CashFlowItems from "./CashFlowItems";
import { stylesSteps2 } from "../../../../css/step2";

interface InputComponentProps {
  onTabPress: () => void;
}

const StepContent2: React.FC<InputComponentProps> = ({ onTabPress }) => {
  const styles = stylesSteps2();
  return (
    <>
      <div
        className={`${styles.root}`}
        // style={{ ...(modalVisible.visible ? { backdropFilter: "blur(2px)", filter: "blur(2px)" } : {}) }}
      >
        <YearsSelection />
        <CashFlowItems />
        <BalanceSheetItems onTabPress={onTabPress} />
      </div>
    </>
  );
};
export default StepContent2;
