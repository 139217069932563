import React, { useState } from "react";
import useStoreInputs, { useStoreRoutingService } from "../store/globalStates";
import { namingValuationStyles } from "../../css/valuations"; // Importa los estilos

const NamingValuation: React.FC = () => {
  const styles = namingValuationStyles();
  const [valuationNameLocal, setValuationNameLocal] = useState<string>("");
  const { setValuationName, setDefaultInputs } = useStoreInputs((state) => state);
  const [createButtonPressed, setCreateButtonPressed] = useState<boolean>(false);
  const { routingNumber, setRoutingNumber } = useStoreRoutingService((state) => state);

  const handleValuationName = (e) => {
    setValuationNameLocal(e.target.value);
  };

  const handleCreateValuation = () => {
    setCreateButtonPressed(true);
    setDefaultInputs();
    setValuationName(valuationNameLocal);
    if (valuationNameLocal !== "") {
      setRoutingNumber(routingNumber + 1);
    }
  };

  const handleKeyDown = (e) => {
    if (e.key === "Enter") {
      e.preventDefault();
      handleCreateValuation();
    }
  };

  return (
    <div onKeyDown={handleKeyDown} tabIndex={0} style={{ height: "100%", outline: "none" }}>
      <div className={styles.root}>
        <div className={styles.titleContainer}>
          <h1 className={styles.valuationTitle}>Create Valuation</h1>
        </div>
        <div className={styles.divider}></div>
        <div className={styles.inputContainer}>
          <h4 style={{ fontFamily: "Poppins" }}>Valuation Name</h4>
          <input
            type="text"
            className={`${styles.input} ${
              valuationNameLocal === "" && createButtonPressed ? styles.inputError : styles.inputDefault
            }`}
            id="valuation-name"
            style={{
              border: valuationNameLocal === "" && createButtonPressed ? "2px solid red" : "2px solid #ca00ca",
              marginRight: "4rem",
              borderRadius: "0.25rem",
            }}
            onChange={handleValuationName}
            onKeyDown={handleKeyDown}
          />
        </div>
        <div className={styles.buttonContainer}>
          <button
            className={styles.buttonCreateValuation}
            onClick={handleCreateValuation}
            style={{ borderRadius: "0.5rem" }}
            id="button-naming-valuation"
          >
            Create Valuation
          </button>
        </div>
      </div>
    </div>
  );
};

export default NamingValuation;
