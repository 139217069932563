import React from "react";

import { useStylesGlobal } from "../../css/styles";
import { useStoreSteps } from "../store/globalStates";
export default function StepHeader() {
  const { stepNumber } = useStoreSteps((state) => state);
  const stepInfo = [{ title: "Parameters" }, { title: "Financial Inputs" }, { title: "Valuation Outputs" }];
  const styles = useStylesGlobal();
  return (
    <div className={styles.stepContainer} style={{ borderBottom: "1px solid #C2C2C7" }}>
      <div className={styles.circleStepContainer}>
        <h2 className={styles.stepCircle} style={{ borderRadius: "50%", border: "1px solid #232C33" }}>
          {stepNumber}
        </h2>
      </div>
      <div className={styles.titleStepOneContainer}>
        <h5 className={styles.stepProgress}>Step {stepNumber}/3</h5>

        <h2 className={styles.titleStep}>{stepInfo[stepNumber - 1]?.title}</h2>
      </div>
    </div>
  );
}
