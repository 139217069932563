/* eslint-disable no-undef */
import * as React from "react";
import Header from "./Header";
import { makeStyles } from "@fluentui/react-components";
import Valuations from "./Valuations/Valuations";
import { useModalUnits, useStoreRoutingService } from "./store/globalStates";
// import EmptyValuations from "././Valuations/EmptyValuations";
import NamingValuation from "././Valuations/NamingValuation";
import StepStructure from "./Stepper/StepStructure";
import NoCompatible from "./NoCompatible";
import ReportLoader from "./Loader/ReportLoader";
import ErrorState from "./ErrorState/ErrorState";
import ModalUnits from "./Modal/ModalUnits";

interface AppProps {
  title: string;
}

const useStyles = makeStyles({
  root: {
    minHeight: "100vh",
    minWidth: "300px",
    backgroundColor: "#f2f3f5",
  },
});

const App = (props: AppProps) => {
  const styles = useStyles();
  const { routingNumber } = useStoreRoutingService((state) => state);
  const [languageIsEnglish, setLanguageIsEnglish] = React.useState(true);
  const { modalUnits } = useModalUnits();


  // Office.actions.associate("HIDETASKPANE", function() {
  //   return Office.addin
  //     .hide()
  //     .then(function() {
  //       return;
  //     })
  //     .catch(function(error) {
  //       return error.code;
  //     });
  // });

  React.useEffect(() => {
    const checkLanguage = async () => {
      try {
        // const excelLanguage = Office.context.displayLanguage;
        // const excelRegion = Office.context.contentLanguage;
        // const isEnglish = excelLanguage.startsWith("en") && excelRegion.startsWith("en");
        setLanguageIsEnglish(true);
      } catch (error) {
        console.error("Error checking language or region:", error);
      }
    };

    checkLanguage();
  }, []);

  return (
    <>
      {languageIsEnglish ? (
        <div className={styles.root}>
          <Header logo="assets/logo-filled.png" title={props.title} message="Welcome" />
          {modalUnits.visible && <ModalUnits />}
          {routingNumber === 0 && <Valuations />}
          {routingNumber === 1 && <NamingValuation />}
          {routingNumber === 2 && <StepStructure />}
          {routingNumber === 3 && <ReportLoader />}
          {routingNumber === 4 && <Valuations />}
          {routingNumber === 5 && <ErrorState />}
        </div>
      ) : (
        <NoCompatible />
      )}
    </>
  );
};

export default App;
