import { makeStyles, shorthands } from "@fluentui/react-components";

export const noCompatibleStyles = makeStyles({
  container: {
    backgroundColor: "rgba(242, 243, 245, 1)",
    width: "100%",
    height: "100vh",
    display: "flex",
    flexDirection: "column",
    justifyContent: "center",
    alignItems: "center",
    ...shorthands.gap("1rem"),
  },
  title: {
    color: "black",
    fontSize: "20px",
    fontFamily: "Poppins",
    fontWeight: "600",
    marginTop: "0",
    marginBottom: "0",
  },
  subtitle: {
    fontSize: "14px",
    fontFamily: "Poppins",
    fontWeight: "300",
    marginTop: "0",
    marginBottom: "0",
    color: "#54595E99",
  },
});
