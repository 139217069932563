import { makeStyles, shorthands } from "@fluentui/react-components";

export const inputClipStyles = makeStyles({
  inputContainer: {
    display: "flex",
    flexDirection: "row",
    justifyContent: "center",
    alignItems: "center",
    position: "relative",
    height: "24px",
    fontSize: "10px",
  },
  input: {
    height: "24px",
    fontSize: "10px",
    justifyContent: "flex-end",
    alignItems: "center",
    ...shorthands.gap("0.25rem"),
    ...shorthands.borderRadius("0.25rem"),
    ...shorthands.border("none"),
    position: "relative",
    width: "101%",
    "&::placeholder": {
      ...shorthands.margin("0.4rem"),
      color: "rgba(35, 44, 51, 1)",
      fontFamily: "Poppins",
      fontWeight: "500",
      fontSize: "10px",
      lineHeight: "1.12rem",
      width: "101%",
    },
  },
  celdaLabel: {
    ...shorthands.margin("0px"),
    fontFamily: "Poppins",
    fontWeight: "400",
    fontSize: "10px",
    color: "#ffffff",
    lineHeight: "1rem",
  },
  switchCylinder: {
    width: "2rem",
    height: "1rem",
    backgroundColor: "#A9A9A9",
    display: "flex",
    alignItems: "center",
    justifyContent: "space-between",
    position: "absolute",
    right: "0.25rem",
  },
  switchBall: {
    width: "0.875rem",
    height: "0.875rem",
    backgroundColor: "#808080",
    transform: "translateX(0)",
  },
  switchOn: {
    backgroundColor: "#00B578",
    "& $switchBall": {
      transform: "translateX(1.125rem)",
    },
  },
  inputImage: {
    position: "absolute",
    top: "2.5px",
    right: "5px",
    width: "1.25rem",
    height: "1.25rem",
    ...shorthands.borderRadius("0.25rem"),
    ...shorthands.padding("0.25rem"),
  },
  inputCelda: {
    position: "absolute",
    top: "2.5px",
    right: "5px",
    width: "1.25rem",
    height: "1.25rem",
    ...shorthands.borderRadius("0.25rem"),
    ...shorthands.padding("0.25rem"),
  },
  redBorder: {
    ...shorthands.border("1px solid red"),
  },
  inputNoSpinButtons: {
    "-moz-appearance": "textfield" /* Firefox */,
    "&::-webkit-inner-spin-button, &::-webkit-outer-spin-button": {
      "-webkit-appearance": "none",
      ...shorthands.margin("0"),
    },
  },
});
