import React, { useRef } from "react";
import useStoreInputs, { useModalUnits, useSelectOptionStore } from "../store/globalStates";
import { stylesModal } from "../../css/modal";
export default function ModalUnits() {
  const { setModalUnits, modalUnits } = useModalUnits();
  const { setValueByName } = useStoreInputs((state) => state);
  const styles = stylesModal();
  const [value, setInputValue] = React.useState("");
  const inputRef = useRef<HTMLInputElement>(null);
  const { addOption } = useSelectOptionStore();

  const handleInputChange = (event: React.ChangeEvent<HTMLInputElement>) => {
    event.preventDefault();
    setInputValue(event.target.value);
  };
  const onSave = () => {
    addOption(modalUnits.nameInput, value);
    setValueByName(modalUnits.nameInput, value);
    setModalUnits(false, modalUnits.nameInput);
  };
  const setTitle = () => {
    return modalUnits.nameInput === "IU1" ? "Select the currency" : "Select the unity";
  };
  React.useEffect(() => {
    if (modalUnits && inputRef.current) {
      inputRef.current.focus();
    }
  }, [modalUnits]);
  return (
    <div className={styles.modalContainer} style={{ borderRadius: "6px", top: "50%" }}>
      <div className={styles.modalContent} style={{ gap: "1rem" }}>
        <div className={styles.modalHeader} style={{ borderBottom: "1px solid rgba(225, 225, 227, 1)" }}>
          <p className={styles.title}>{setTitle()}</p>
          <button
            className={styles.close}
            onClick={() => {
              setModalUnits(false, modalUnits.nameInput);
            }}
          >
            X
          </button>
        </div>
        <div className={styles.modalBody}>
          <div className={styles.modalInputContainer}>
            <input
              type="text"
              className={`${styles.input} ${styles.modalName}`}
              style={{ maxWidth: "100px" }}
              value={value}
              onChange={handleInputChange}
              ref={inputRef}
            />
          </div>
        </div>
        <button onClick={onSave} className={`${styles.save}`} style={{ borderRadius: "0.25rem" }}>
          Ok
        </button>
      </div>
    </div>
  );
}
