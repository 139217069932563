import { create } from "zustand";

interface Input {
  name: string;
  value: string | boolean | [] | String[] | number;
  validation: boolean;
  step: number;
  showValidation: boolean;
  warning?: boolean;
  labelValidation?: string;
  colorInputFont: boolean;
  celda: string;
  celdaWithSheet: string;
  formula: string;
  netIncomeEmpty?: boolean;
}

interface ChevronState {
  visible: boolean;
  rotation: number;
}
interface InputFromModal {
  name: string;
  number: number;
}

interface InputsState {
  setCelda: (name: string, celda: string) => void;
  setCeldaWithSheet: (name: string, celdaWithSheet: string) => void;
  getCelda: (name: string) => string;
  setFormula: (name: string, formula: string) => void;
  getFormula: (name: string) => string;
  setColorInputFont: (name: string, newColorInputFont: boolean) => void;
  getColorInputFont: (name: string) => boolean;
  saveForLater: boolean;
  setSaveForLater: (newSaveForLater: boolean) => void;
  valuationNumber: number;
  setValuationNumber: (newValuationNumber: number) => void;
  valuationName: string;
  setValuationName: (newValuationName: string) => void;
  inputs: Input[];
  customContainers: Input[];
  setValueByName: (name: string, value: any) => void;
  setValidationByName: (name: string, validation: boolean) => void;
  getInputValue: (nameInput: string) => any | undefined;
  getInputValidation: (nameInput: string) => boolean;
  setShowValidation: (name: string, showValidation: boolean) => void;
  getShowValidation: (name: string) => boolean;
  setWarning: (name: string, warning: boolean) => void;
  getWarning: (name: string) => boolean;
  setNetIncomeEmpty: (name: string, netIncomeEmpty: boolean) => void;
  getNetIncomeEmpty: (name: string) => boolean;
  setInput: (newInput: Input[]) => void;
  setDefaultInputs: () => void;
  setLabelValidation: (name: string, labelValidation: string) => void;
  getLabelValidation: (name: string) => string;
  addCustomContainer: (customContainer: Input) => void;
  changeTitleCustomContainer: (index: number, title: string) => void;
  deleteCustomContainer: (name: string) => void;
  setInputFromModal: (inputFromModal: string) => void;
  inputFromModal: InputFromModal;
  getInput: (nameInput: string) => Input;
  chevronStates: ChevronState[];
  setChevronStates: (index: number) => void;
}

const useStoreInputs = create<InputsState>((set, get) => ({
  inputs: [
    {
      name: "UDCF",
      value: true,
      validation: true,
      step: 1,
      showValidation: false,
      colorInputFont: undefined,
      celda: "",
      celdaWithSheet: "",
      formula: "",
    },
    {
      name: "LDCF",
      value: false,
      validation: true,
      step: 1,
      showValidation: false,
      colorInputFont: undefined,
      celda: "",
      celdaWithSheet: "",
      formula: "",
    },
    {
      name: "DDM",
      value: false,
      validation: true,
      step: 1,
      showValidation: false,
      colorInputFont: undefined,
      celda: "",
      celdaWithSheet: "",
      formula: "",
    },
    {
      name: "IU1",
      value: "US$",
      validation: true,
      step: 1,
      showValidation: false,
      colorInputFont: undefined,
      celda: "",
      celdaWithSheet: "",
      formula: "",
    },
    {
      name: "IU2",
      value: "mm",
      validation: true,
      step: 1,
      showValidation: false,
      colorInputFont: undefined,
      celda: "",
      celdaWithSheet: "",
      formula: "",
    },

    {
      name: "VD",
      value: "",
      validation: true,
      step: 1,
      showValidation: false,
      colorInputFont: undefined,
      celda: "",
      celdaWithSheet: "",
      formula: "",
    },
    {
      name: "FCFY",
      value: "",
      validation: true,
      step: 1,
      showValidation: false,
      labelValidation: "",
      colorInputFont: undefined,
      celda: "",
      celdaWithSheet: "",
      formula: "",
    },
    {
      name: "MYC",
      value: "1",
      validation: true,
      step: 1,
      showValidation: false,
      colorInputFont: undefined,
      celda: "",
      celdaWithSheet: "",
      formula: "",
    }, //5
    {
      name: "WACC",
      value: "",
      validation: false,
      step: 1,
      showValidation: false,
      colorInputFont: undefined,
      labelValidation: "Is mandatory",
      celda: "",
      celdaWithSheet: "",
      formula: "",
    },
    {
      name: "COE",
      value: "",
      validation: true,
      step: 1,
      showValidation: false,
      colorInputFont: undefined,
      celda: "",
      celdaWithSheet: "",
      formula: "",
    },
    {
      name: "PGRC",
      value: true,
      validation: true,
      step: 1,
      showValidation: false,
      colorInputFont: undefined,
      celda: "",
      celdaWithSheet: "",
      formula: "",
    },
    {
      name: "TEMC",
      value: true,
      validation: true,
      step: 1,
      showValidation: false,
      colorInputFont: undefined,
      celda: "",
      celdaWithSheet: "",
      formula: "",
    },
    {
      name: "CEDA",
      value: "1",
      validation: true,
      step: 1,
      showValidation: false,
      colorInputFont: undefined,
      celda: "",
      celdaWithSheet: "",
      formula: "",
    }, //10
    {
      name: "DTA/DTL",
      value: "0",
      validation: true,
      step: 1,
      showValidation: false,
      colorInputFont: undefined,
      celda: "",
      celdaWithSheet: "",
      formula: "",
    },
    {
      name: "PGR",
      value: "",
      validation: false,
      step: 1,
      showValidation: false,
      colorInputFont: undefined,
      celda: "",
      celdaWithSheet: "",
      formula: "",
      labelValidation: "Is mandatory",
    },
    {
      name: "TEM",
      value: "",
      validation: false,
      step: 1,
      showValidation: false,
      colorInputFont: undefined,
      labelValidation: "Is mandatory",
      celda: "",
      celdaWithSheet: "",
      formula: "",
    },
    {
      name: "YS",
      value: "",
      validation: false,
      step: 2,
      showValidation: false,
      colorInputFont: undefined,
      labelValidation: "Is mandatory",
      celda: "",
      celdaWithSheet: "",
      formula: "",
    },
    {
      name: "EBI",
      value: "",
      validation: false,
      step: 2,
      showValidation: false,
      labelValidation: "Is mandatory",
      colorInputFont: undefined,
      celda: "",
      celdaWithSheet: "",
      formula: "",
    }, //15
    {
      name: "D&A",
      value: "",
      validation: true,
      step: 2,
      showValidation: false,
      labelValidation: "",
      colorInputFont: undefined,
      celda: "",
      celdaWithSheet: "",
      formula: "",
    },
    {
      name: "CX",
      value: "",
      validation: true,
      step: 2,
      showValidation: false,
      warning: false,
      labelValidation: "",
      colorInputFont: undefined,
      celda: "",
      celdaWithSheet: "",
      formula: "",
    },
    {
      name: "CNWC",
      value: "",
      validation: true,
      step: 2,
      showValidation: false,
      labelValidation: "",
      colorInputFont: undefined,
      celda: "",
      celdaWithSheet: "",
      formula: "",
    },
    {
      name: "IdD",
      value: "",
      validation: true,
      step: 2,
      showValidation: false,
      labelValidation: "",
      colorInputFont: undefined,
      celda: "",
      celdaWithSheet: "",
      formula: "",
    },
    {
      name: "iDD",
      value: "",
      validation: true,
      step: 2,
      showValidation: false,
      labelValidation: "",
      colorInputFont: undefined,
      celda: "",
      celdaWithSheet: "",
      formula: "",
    }, //20
    {
      name: "NI",
      value: "",
      validation: true,
      step: 2,
      showValidation: false,
      labelValidation: "",
      colorInputFont: undefined,
      celda: "",
      celdaWithSheet: "",
      formula: "",
      netIncomeEmpty: true,
    },
    {
      name: "DIR",
      value: "",
      validation: true,
      step: 2,
      showValidation: false,
      labelValidation: "",
      colorInputFont: undefined,
      celda: "",
      celdaWithSheet: "",
      formula: "",
    },
    {
      name: "CCE",
      value: "",
      validation: true,
      step: 2,
      showValidation: false,
      colorInputFont: undefined,
      celda: "",
      celdaWithSheet: "",
      formula: "",
    },
    {
      name: "TD",
      value: "",
      validation: true,
      step: 2,
      showValidation: false,
      colorInputFont: undefined,
      celda: "",
      celdaWithSheet: "",
      formula: "",
    },
    {
      name: "MI",
      value: "",
      validation: true,
      step: 2,
      showValidation: false,
      colorInputFont: undefined,
      celda: "",
      celdaWithSheet: "",
      formula: "",
    }, //25
    {
      name: "PVTVC",
      value: true,
      validation: true,
      step: 3,
      showValidation: false,
      colorInputFont: undefined,
      celda: "",
      celdaWithSheet: "",
      formula: "",
    },
    {
      name: "ITEMC",
      value: true,
      validation: true,
      step: 3,
      showValidation: false,
      colorInputFont: undefined,
      celda: "",
      celdaWithSheet: "",
      formula: "",
    },
    {
      name: "IPGRC",
      value: true,
      validation: true,
      step: 3,
      showValidation: false,
      colorInputFont: undefined,
      celda: "",
      celdaWithSheet: "",
      formula: "",
    },
    {
      name: "EVC",
      value: true,
      validation: true,
      step: 3,
      showValidation: false,
      colorInputFont: undefined,
      celda: "",
      celdaWithSheet: "",
      formula: "",
    },
    {
      name: "PEM",
      value: false,
      validation: true,
      step: 3,
      showValidation: false,
      colorInputFont: undefined,
      celda: "",
      celdaWithSheet: "",
      formula: "",
    }, //30
    {
      name: "RPT",
      value: 5,
      validation: true,
      step: 3,
      showValidation: false,
      colorInputFont: undefined,
      celda: "",
      celdaWithSheet: "",
      formula: "",
    },
    {
      name: "CPT",
      value: 5,
      validation: true,
      step: 3,
      showValidation: false,
      colorInputFont: undefined,
      celda: "",
      celdaWithSheet: "",
      formula: "",
    },
    {
      name: "WACCS",
      value: "0.50",
      validation: true,
      step: 3,
      showValidation: false,
      colorInputFont: false,
      celda: "",
      celdaWithSheet: "",
      formula: "",
    },
    {
      name: "DVDS",
      value: "",
      validation: true,
      step: 2,
      showValidation: false,
      colorInputFont: undefined,
      celda: "",
      celdaWithSheet: "",
      formula: "",
    },

    {
      name: "COES",
      value: "0.50",
      validation: true,
      step: 3,
      showValidation: false,
      colorInputFont: false,
      celda: "",
      celdaWithSheet: "",
      formula: "",
    }, //35
    {
      name: "PGRS",
      value: "0.50",
      validation: true,
      step: 3,
      showValidation: false,
      colorInputFont: false,
      celda: "",
      celdaWithSheet: "",
      formula: "",
    },
    {
      name: "TEMS",
      value: "0.5x",
      validation: true,
      step: 3,
      showValidation: false,
      colorInputFont: false,
      celda: "",
      celdaWithSheet: "",
      formula: "",
    },
    {
      name: "EVC1",
      value: "",
      validation: true,
      step: 3,
      showValidation: false,
      colorInputFont: false,
      celda: "",
      celdaWithSheet: "",
      formula: "",
    },
    {
      name: "EVC2",
      value: "",
      validation: true,
      step: 3,
      showValidation: false,
      colorInputFont: false,
      celda: "",
      celdaWithSheet: "",
      formula: "",
    },
    {
      name: "EVC3",
      value: "",
      validation: true,
      step: 3,
      showValidation: false,
      colorInputFont: false,
      celda: "",
      celdaWithSheet: "",
      formula: "",
    },
    {
      name: "PEMC1",
      value: "",
      validation: true,
      step: 3,
      showValidation: false,
      colorInputFont: false,
      celda: "",
      celdaWithSheet: "",
      formula: "",
    },
    {
      name: "PEMC2",
      value: "",
      validation: true,
      step: 3,
      showValidation: false,
      colorInputFont: false,
      celda: "",
      celdaWithSheet: "",
      formula: "",
    },
    {
      name: "PEMC3",
      value: "",
      validation: true,
      step: 3,
      showValidation: false,
      colorInputFont: false,
      celda: "",
      celdaWithSheet: "",
      formula: "",
    },
  ],
  customContainers: [],
  addCustomContainer: (customContainer: Input) => {
    set((state) => ({
      inputs: [...state.inputs, customContainer],
      customContainers: [...state.customContainers, customContainer],
    }));
  },
  changeTitleCustomContainer: (index: number, title: string) => {
    set((state) => {
      const updatedContainers = [...state.customContainers];
      updatedContainers[index].name = title;
      return {
        customContainers: updatedContainers,
      };
    });
  },
  deleteCustomContainer: (name: string) => {
    set((state) => {
      const updatedContainers = [...state.inputs];
      const updatedCustomContainers = [...state.customContainers];
      // Filtrar los elementos que no tienen el mismo nombre que el proporcionado
      const filteredInputs = updatedContainers.filter((input) => input.name !== name);
      const filteredCustomInputs = updatedCustomContainers.filter((input) => input.name !== name);

      return {
        inputs: filteredInputs,
        customContainers: filteredCustomInputs,
      };
    });
  },
  setDefaultInputs: () => {
    const defaultInputs: Input[] = [
      {
        name: "UDCF",
        value: true,
        validation: true,
        step: 1,
        showValidation: false,
        colorInputFont: undefined,
        celda: "",
        celdaWithSheet: "",
        formula: "",
      },
      {
        name: "LDCF",
        value: false,
        validation: true,
        step: 1,
        showValidation: false,
        colorInputFont: undefined,
        celda: "",
        celdaWithSheet: "",
        formula: "",
      },
      {
        name: "DDM",
        value: false,
        validation: true,
        step: 1,
        showValidation: false,
        colorInputFont: undefined,
        celda: "",
        celdaWithSheet: "",
        formula: "",
      },
      {
        name: "IU1",
        value: "US$",
        validation: true,
        step: 1,
        showValidation: false,
        colorInputFont: undefined,
        celda: "",
        celdaWithSheet: "",
        formula: "",
      },
      {
        name: "IU2",
        value: "mm",
        validation: true,
        step: 1,
        showValidation: false,
        colorInputFont: undefined,
        celda: "",
        celdaWithSheet: "",
        formula: "",
      },
      {
        name: "VD",
        value: "",
        validation: true,
        step: 1,
        showValidation: false,
        colorInputFont: undefined,
        celda: "",
        celdaWithSheet: "",
        formula: "",
      },
      {
        name: "FCFY",
        value: "",
        validation: true,
        step: 1,
        showValidation: false,
        colorInputFont: undefined,
        celda: "",
        celdaWithSheet: "",
        formula: "",
        labelValidation: "",
      },
      {
        name: "MYC",
        value: "0",
        validation: true,
        step: 1,
        showValidation: false,
        colorInputFont: undefined,
        celda: "",
        celdaWithSheet: "",
        formula: "",
      }, //5
      {
        name: "WACC",
        value: "",
        validation: false,
        step: 1,
        showValidation: false,
        colorInputFont: undefined,
        labelValidation: "Is mandatory",
        celda: "",
        celdaWithSheet: "",
        formula: "",
      },
      {
        name: "COE",
        value: "",
        validation: true,
        step: 1,
        showValidation: false,
        colorInputFont: undefined,
        celda: "",
        celdaWithSheet: "",
        formula: "",
      },
      {
        name: "PGRC",
        value: true,
        validation: true,
        step: 1,
        showValidation: false,
        colorInputFont: undefined,
        celda: "",
        celdaWithSheet: "",
        formula: "",
      },
      {
        name: "TEMC",
        value: true,
        validation: true,
        step: 1,
        showValidation: false,
        colorInputFont: undefined,
        celda: "",
        celdaWithSheet: "",
        formula: "",
      },
      {
        name: "CEDA",
        value: "1",
        validation: true,
        step: 1,
        showValidation: false,
        colorInputFont: undefined,
        celda: "",
        celdaWithSheet: "",
        formula: "",
      }, //10
      {
        name: "DTA/DTL",
        value: "0",
        validation: true,
        step: 1,
        showValidation: false,
        colorInputFont: undefined,
        celda: "",
        celdaWithSheet: "",
        formula: "",
      },
      {
        name: "PGR",
        value: "",
        validation: false,
        step: 1,
        showValidation: false,
        colorInputFont: undefined,
        celda: "",
        celdaWithSheet: "",
        labelValidation: "Is mandatory",
        formula: "",
      },
      {
        name: "TEM",
        value: "",
        validation: false,
        step: 1,
        showValidation: false,
        colorInputFont: undefined,
        labelValidation: "Is mandatory",
        celda: "",
        celdaWithSheet: "",
        formula: "",
      },
      {
        name: "YS",
        value: "",
        validation: false,
        step: 2,
        showValidation: false,
        colorInputFont: undefined,
        labelValidation: "Is mandatory",
        celda: "",
        celdaWithSheet: "",
        formula: "",
      },
      {
        name: "EBI",
        value: "",
        validation: false,
        step: 2,
        showValidation: false,
        labelValidation: "Is mandatory",
        colorInputFont: undefined,
        celda: "",
        celdaWithSheet: "",
        formula: "",
      }, //15
      {
        name: "D&A",
        value: "",
        validation: true,
        step: 2,
        showValidation: false,
        labelValidation: "",
        colorInputFont: undefined,
        celda: "",
        celdaWithSheet: "",
        formula: "",
      },
      {
        name: "CX",
        value: "",
        validation: true,
        step: 2,
        showValidation: false,
        warning: false,
        labelValidation: "",
        colorInputFont: undefined,
        celda: "",
        celdaWithSheet: "",
        formula: "",
      },
      {
        name: "CNWC",
        value: "",
        validation: true,
        step: 2,
        showValidation: false,
        labelValidation: "",
        colorInputFont: undefined,
        celda: "",
        celdaWithSheet: "",
        formula: "",
      },
      {
        name: "IdD",
        value: "",
        validation: true,
        step: 2,
        showValidation: false,
        labelValidation: "",
        colorInputFont: undefined,
        celda: "",
        celdaWithSheet: "",
        formula: "",
      },
      {
        name: "iDD",
        value: "",
        validation: true,
        step: 2,
        showValidation: false,
        labelValidation: "",
        colorInputFont: undefined,
        celda: "",
        celdaWithSheet: "",
        formula: "",
      }, //20
      {
        name: "NI",
        value: "",
        validation: true,
        step: 2,
        showValidation: false,
        labelValidation: "",
        colorInputFont: undefined,
        celda: "",
        celdaWithSheet: "",
        formula: "",
        netIncomeEmpty: true,
      },
      {
        name: "DIR",
        value: "",
        validation: true,
        step: 2,
        showValidation: false,
        labelValidation: "",
        colorInputFont: undefined,
        celda: "",
        celdaWithSheet: "",
        formula: "",
      },
      {
        name: "CCE",
        value: "",
        validation: true,
        step: 2,
        showValidation: false,
        colorInputFont: undefined,
        celda: "",
        celdaWithSheet: "",
        formula: "",
      },
      {
        name: "TD",
        value: "",
        validation: true,
        step: 2,
        showValidation: false,
        colorInputFont: undefined,
        celda: "",
        celdaWithSheet: "",
        formula: "",
      },
      {
        name: "MI",
        value: "",
        validation: true,
        step: 2,
        showValidation: false,
        colorInputFont: undefined,
        celda: "",
        celdaWithSheet: "",
        formula: "",
      }, //25
      {
        name: "PVTVC",
        value: true,
        validation: true,
        step: 3,
        showValidation: false,
        colorInputFont: undefined,
        celda: "",
        celdaWithSheet: "",
        formula: "",
      },
      {
        name: "ITEMC",
        value: true,
        validation: true,
        step: 3,
        showValidation: false,
        colorInputFont: undefined,
        celda: "",
        celdaWithSheet: "",
        formula: "",
      },
      {
        name: "IPGRC",
        value: true,
        validation: true,
        step: 3,
        showValidation: false,
        colorInputFont: undefined,
        celda: "",
        celdaWithSheet: "",
        formula: "",
      },
      {
        name: "EVC",
        value: true,
        validation: true,
        step: 3,
        showValidation: false,
        colorInputFont: undefined,
        celda: "",
        celdaWithSheet: "",
        formula: "",
      },
      {
        name: "PEM",
        value: false,
        validation: true,
        step: 3,
        showValidation: false,
        colorInputFont: undefined,
        celda: "",
        celdaWithSheet: "",
        formula: "",
      }, //30
      {
        name: "RPT",
        value: 5,
        validation: true,
        step: 3,
        showValidation: false,
        colorInputFont: undefined,
        celda: "",
        celdaWithSheet: "",
        formula: "",
      },
      {
        name: "CPT",
        value: 5,
        validation: true,
        step: 3,
        showValidation: false,
        colorInputFont: undefined,
        celda: "",
        celdaWithSheet: "",
        formula: "",
      },
      {
        name: "WACCS",
        value: "0.50",
        validation: true,
        step: 3,
        showValidation: false,
        colorInputFont: false,
        celda: "",
        celdaWithSheet: "",
        formula: "",
      },
      {
        name: "DVDS",
        value: "",
        validation: true,
        step: 2,
        showValidation: false,
        colorInputFont: undefined,
        celda: "",
        celdaWithSheet: "",
        formula: "",
      },
      {
        name: "COES",
        value: "0.50",
        validation: true,
        step: 3,
        showValidation: false,
        colorInputFont: false,
        celda: "",
        celdaWithSheet: "",
        formula: "",
      }, //35
      {
        name: "PGRS",
        value: "0.50",
        validation: true,
        step: 3,
        showValidation: false,
        colorInputFont: false,
        celda: "",
        celdaWithSheet: "",
        formula: "",
      },
      {
        name: "TEMS",
        value: "0.50x",
        validation: true,
        step: 3,
        showValidation: false,
        colorInputFont: false,
        celda: "",
        celdaWithSheet: "",
        formula: "",
      },
      {
        name: "EVC1",
        value: "",
        validation: true,
        step: 3,
        showValidation: false,
        colorInputFont: false,
        celda: "",
        celdaWithSheet: "",
        formula: "",
      },
      {
        name: "EVC2",
        value: "",
        validation: true,
        step: 3,
        showValidation: false,
        colorInputFont: false,
        celda: "",
        celdaWithSheet: "",
        formula: "",
      },
      {
        name: "EVC3",
        value: "",
        validation: true,
        step: 3,
        showValidation: false,
        colorInputFont: false,
        celda: "",
        celdaWithSheet: "",
        formula: "",
      },
      {
        name: "PEMC1",
        value: "",
        validation: true,
        step: 3,
        showValidation: false,
        colorInputFont: false,
        celda: "",
        celdaWithSheet: "",
        formula: "",
      },
      {
        name: "PEMC2",
        value: "",
        validation: true,
        step: 3,
        showValidation: false,
        colorInputFont: false,
        celda: "",
        celdaWithSheet: "",
        formula: "",
      },
      {
        name: "PEMC3",
        value: "",
        validation: true,
        step: 3,
        showValidation: false,
        colorInputFont: false,
        celda: "",
        celdaWithSheet: "",
        formula: "",
      },
    ];
    set({ inputs: defaultInputs });
  },

  setInput: (newInput: Input[]) => {
    set({ inputs: newInput });
  },

  valuationName: "",
  valuationNumber: 0,
  saveForLater: false,

  setSaveForLater: (newSaveForLater: boolean) => {
    set(() => ({
      saveForLater: newSaveForLater,
    }));
  },

  setColorInputFont: (name: string, colorInputFont: boolean) => {
    set((state) => ({
      inputs: state.inputs.map((input) => (input.name === name ? { ...input, colorInputFont } : input)),
    }));
  },

  setCelda: (name: string, celda: string) => {
    set((state) => ({
      inputs: state.inputs.map((input) => (input.name === name ? { ...input, celda } : input)),
    }));
  },
  setCeldaWithSheet: (name: string, celdaWithSheet: string) => {
    set((state) => ({
      inputs: state.inputs.map((input) => (input.name === name ? { ...input, celdaWithSheet } : input)),
    }));
  },

  setFormula: (name: string, formula: string) => {
    set((state) => ({
      inputs: state.inputs.map((input) => (input.name === name ? { ...input, formula } : input)),
    }));
  },

  setLabelValidation: (name: string, labelValidation: string) => {
    set((state) => ({
      inputs: state.inputs.map((input) => (input.name === name ? { ...input, labelValidation } : input)),
    }));
  },

  setValuationName: (newValuationName: string) => {
    set(() => ({
      valuationName: newValuationName,
    }));
  },

  setValuationNumber: (newValuationNumber: number) => {
    set(() => ({
      valuationNumber: newValuationNumber,
    }));
  },
  setValueByName: (name: string, value: string) => {
    set((state) => ({
      inputs: state.inputs.map((input) => (input.name === name ? { ...input, value } : input)),
    }));
  },
  setValidationByName: (name: string, validation: boolean) => {
    set((state) => ({
      inputs: state.inputs.map((input) => (input.name === name ? { ...input, validation } : input)),
    }));
  },
  setShowValidation: (name: string, showValidation: boolean) => {
    set((state) => ({
      inputs: state.inputs.map((input) => (input.name === name ? { ...input, showValidation } : input)),
    }));
  },
  getInputValue: (nameInput: string) => {
    const currentState = get();
    const indexInput = currentState.inputs.findIndex((input) => input.name === nameInput);
    return currentState.inputs[indexInput]?.value;
  },
  getInput: (nameInput: string) => {
    const currentState = get();
    const indexInput = currentState.inputs.findIndex((input) => input.name === nameInput);
    return currentState.inputs[indexInput];
  },
  getCelda: (nameInput: string) => {
    const currentState = get();
    const indexInput = currentState.inputs.findIndex((input) => input.name === nameInput);
    return currentState.inputs[indexInput]?.celda;
  },
  getFormula: (nameInput: string) => {
    const currentState = get();
    const indexInput = currentState.inputs.findIndex((input) => input.name === nameInput);
    return currentState.inputs[indexInput]?.formula;
  },
  getColorInputFont: (nameInput: string) => {
    const currentState = get();
    const indexInput = currentState.inputs.findIndex((input) => input.name === nameInput);
    return currentState.inputs[indexInput]?.colorInputFont;
  },
  getInputValidation: (nameInput: string) => {
    const currentState = get();
    const indexInput = currentState.inputs.findIndex((input) => input.name === nameInput);
    return currentState.inputs[indexInput]?.validation;
  },
  getShowValidation: (nameInput: string) => {
    const currentState = get();
    const indexInput = currentState.inputs.findIndex((input) => input.name === nameInput);
    return currentState.inputs[indexInput]?.showValidation;
  },
  setWarning: (name: string, warning: boolean) => {
    set((state) => ({
      inputs: state.inputs.map((input) => (input.name === name ? { ...input, warning } : input)),
    }));
  },
  setNetIncomeEmpty: (name: string, netIncomeEmpty: boolean) => {
    set((state) => ({
      inputs: state.inputs.map((input) => (input.name === name ? { ...input, netIncomeEmpty } : input)),
    }));
  },
  getNetIncomeEmpty: (name: string) => {
    const currentState = get();
    const indexInput = currentState.inputs.findIndex((input) => input.name === name);
    return currentState.inputs[indexInput]?.netIncomeEmpty;
  },
  getWarning: (name: string) => {
    const currentState = get();
    const indexInput = currentState.inputs.findIndex((input) => input.name === name);
    return currentState.inputs[indexInput]?.warning;
  },
  getLabelValidation: (name: string) => {
    const currentState = get();
    const indexInput = currentState.inputs.findIndex((input) => input.name === name);
    return currentState.inputs[indexInput]?.labelValidation;
  },
  inputFromModal: {} as InputFromModal,
  setInputFromModal: (input: string) => {
    set((state) => ({
      inputFromModal: {
        ...state.inputFromModal,
        number: state.inputFromModal.number + 1,
        name: input,
      },
    }));
  },
  chevronStates: [
    { visible: true, rotation: -180 },
    { visible: true, rotation: -180 },
    { visible: true, rotation: -180 },
    { visible: true, rotation: -180 },
    { visible: true, rotation: -180 },
  ],
  setChevronStates: (index) =>
    set((state) => {
      const updatedChevronStates = [...state.chevronStates];
      updatedChevronStates[index] = {
        ...updatedChevronStates[index],
        visible: !updatedChevronStates[index].visible,
        rotation: updatedChevronStates[index].rotation === 0 ? -180 : 0,
      };
      return { chevronStates: updatedChevronStates };
    }),
}));
export default useStoreInputs;

interface StepsState {
  stepNumber: number;
  setStepNumber: (newStepNumber: number) => void;
  modalVisible: {
    visible: boolean;
    nameInput: string;
  };
  setModalVisible: (visible: boolean, nameInput: string) => void;
}

export const useStoreSteps = create<StepsState>((set) => ({
  modalVisible: {
    visible: false,
    nameInput: "",
  },
  setModalVisible: (visible: boolean, nameInput: string) => {
    set((state) => ({
      modalVisible: {
        ...state.modalVisible,
        visible: visible,
        nameInput: nameInput,
      },
    }));
  },
  stepNumber: 1,
  setStepNumber: (newStepNumber: number) => {
    set(() => ({
      stepNumber: newStepNumber,
    }));
  },
}));

interface Valuation {
  valuationName: string;
  inputs: Input[];
  date: string;
  valuationNumber: number;
  saveForLater: boolean;
}

interface ValuationsState {
  valuations: Valuation[];
  valuationSelected: number;
  editValuation: boolean;
  setEditValuation: (newEditValuation: boolean) => void;
  setValuationSelected: (valuationSelected: number) => void;
  addValuation: (valuation: Valuation) => void;
  updateValuationInputs: (valuationNumber: number, newInputs: Input[]) => void;
  updateSaveForLater: (valuationNumber: number, saveForLater: boolean) => void;
  removeSaveForLater: (valuationNumber: number) => void;
  updateValuation: (newValuations: Valuation[]) => void;
  valuationCounter: number; // Añade el contador aquí
  incrementValuationCounter: () => void; // Función para incrementar el contador
}

export const useStoreValuations = create<ValuationsState>((set) => ({
  valuations: [],
  valuationCounter: 0, // Inicializa el contador
  valuationSelected: 0,
  editValuation: false,
  setValuationSelected: (valuationSelected: number) => {
    set(() => ({
      valuationSelected: valuationSelected,
    }));
  },
  addValuation: (valuation: Valuation) => {
    set((state) => ({
      valuations: [...state.valuations, valuation],
    }));
  },
  setEditValuation: (newEditValuation: boolean) => {
    set(() => ({
      editValuation: newEditValuation,
    }));
  },

  updateValuationInputs: (valuationNumber: number, newInputs: Input[]) => {
    set((state) => {
      const updatedValuations = state.valuations.map((valuation) => {
        if (valuation.valuationNumber === valuationNumber) {
          return {
            ...valuation,
            inputs: newInputs,
          };
        }
        return valuation;
      });

      return {
        valuations: updatedValuations,
      };
    });
  },

  updateSaveForLater: (valuationNumber: number, saveForLater: boolean) => {
    set((state) => {
      const updatedValuations = state.valuations.map((valuation) => {
        if (valuation.valuationNumber === valuationNumber) {
          return {
            ...valuation,
            saveForLater: saveForLater,
          };
        }
        return valuation;
      });

      return {
        valuations: updatedValuations,
      };
    });
  },

  removeSaveForLater: (valuationNumber: number) => {
    set((state) => {
      const updatedValuations = state.valuations.filter((valuation) => valuation.valuationNumber !== valuationNumber);

      return {
        valuations: updatedValuations,
      };
    });
  },

  updateValuation: (newValuations: Valuation[]) => {
    set({ valuations: newValuations });
  },

  incrementValuationCounter: () => {
    set((state) => ({ valuationCounter: state.valuationCounter + 1 }));
  },
}));

interface RoutingService {
  routingNumber: number;
  setRoutingNumber: (newRoutingNumber: number) => void;
}

export const useStoreRoutingService = create<RoutingService>((set) => ({
  routingNumber: 0,

  setRoutingNumber: (newRoutingNumber: number) => {
    set(() => ({
      routingNumber: newRoutingNumber,
    }));
  },
}));

interface ActualValuationNumber {
  actualValuationNumber: number;
  setActualValuationNumber: (newActualValuationNumber: number) => void;
  inputValuationNumber: number;
  setInputValuationNumber: (newInputValuationNumber: number) => void;
}

export const useStoreActualValuationNumber = create<ActualValuationNumber>((set) => ({
  actualValuationNumber: 0,
  inputValuationNumber: 0,

  setActualValuationNumber: (newActualValuationNumber: number) => {
    set(() => ({
      actualValuationNumber: newActualValuationNumber,
    }));
  },
  setInputValuationNumber: (newInputValuationNumber: number) => {
    set(() => ({
      inputValuationNumber: newInputValuationNumber,
    }));
  },
}));

interface ModalUnitsState {
  modalUnits: {
    visible: boolean;
    nameInput: string;
  };
  setModalUnits: (visible: boolean, nameInput: string) => void;
}

export const useModalUnits = create<ModalUnitsState>((set) => ({
  modalUnits: {
    visible: false,
    nameInput: "",
  },
  setModalUnits: (visible: boolean, nameInput: string) => {
    set((state) => ({
      modalUnits: {
        ...state.modalUnits,
        visible: visible,
        nameInput: nameInput,
      },
    }));
  },
}));
interface SelectOptionState {
  optionsIU: { [key: string]: string[] };
  addOption: (inputName: string, option: string) => void;
}

export const useSelectOptionStore = create<SelectOptionState>((set) => ({
  optionsIU: {
    IU1: ["US$", "€ EUR", "£ GBP", "Other"],
    IU2: ["-", "k", "mm", "bn", "Other"],
  },
  addOption: (inputName, option) =>
    set((state) => ({
      optionsIU: {
        ...state.optionsIU,
        [inputName]: [...state.optionsIU[inputName], option],
      },
    })),
}));
interface TabsState {
  finished: boolean;
  saveForLater: boolean;
  setFinished: (value: boolean) => void;
  setSaveForLater: (value: boolean) => void;
}

export const useTabsValuations = create<TabsState>((set) => ({
  finished: false,
  saveForLater: false,
  setFinished: (value: boolean) => set({ finished: value }),
  setSaveForLater: (value: boolean) => set({ saveForLater: value }),
}));
