/* eslint-disable no-undef */
export function validateYear(year: number | string) {
  if (Number(year) > 999 && Number(year) < 9999) {
    return true;
  } else {
    return false;
  }
}
export function validateDate(fechaString) {
  // Expresión regular para validar fechas en formatos M/D/AAAA, MM/DD/AAAA, M/DD/AAAA, MM/D/AAAA
  var fechaValida = /^(\d{1,2})\/(\d{1,2})\/(\d{4})$/.test(fechaString);
  if (!fechaValida) {
    return false;
  }

  var match = fechaString.match(/^(\d{1,2})\/(\d{1,2})\/(\d{4})$/);

  if (!match) {
    return false;
  } else {
    var mm = parseInt(match[1], 10);
    var dd = parseInt(match[2], 10);
    var yyyy = parseInt(match[3], 10);

    if (yyyy < 1900) {
      return false;
    } else if (mm < 1 || mm > 12) {
      return false;
    } else if (dd < 1 || dd > diasEnElMes(mm, yyyy)) {
      return false;
    } else {
      return true;
    }
  }

  function diasEnElMes(month, year) {
    return new Date(year, month, 0).getDate();
  }
}

export function validateToggle(value) {
  // no entra ni al if ni al else
  if (
    value.toUpperCase() === "ON" ||
    value.toUpperCase() === "1" ||
    value.toUpperCase() === "0" ||
    value.toUpperCase() === "OFF"
  ) {
    return true;
  } else {
    return false;
  }
}

export function validatePercentage(value) {
  // Eliminar espacios en blanco al principio y al final del valor
  value = value?.trim();

  // Verificar si el valor termina con %
  const endsWithPercent = value.endsWith("%");

  // Si termina con %, eliminar el % y validar el número
  if (endsWithPercent) {
    const numberValue = value.slice(0, -1); // Eliminar el %
    return !isNaN(parseFloat(numberValue));
  } else {
    // Validar el número directamente
    return !isNaN(parseFloat(value));
  }
}

export function validateTEM(value) {
  if (value.includes("x")) {
    const valueWithoutX = value.replace("x", "");
    return !isNaN(parseFloat(valueWithoutX));
  } else {
    return !isNaN(parseFloat(value));
  }
}

export function numberToNegative(value) {
  if (Number(value) > 0 && /^\d+$/.test(value)) {
    return "-" + value;
  }
  return value;
}

export function validateNumber(value) {
  return /^[-+]?\d+(\.\d+)?$/.test(value);
}

export function validateVoid(value) {
  if (value === "") {
    return false;
  } else {
    return true;
  }
}

export function isPGRCheckboxChecked() {
  return true;
}

export function isTEMCheckboxChecked() {
  return true;
}

export function validateArrayYears(arr) {
  if (typeof arr === "string") {
    // Si es un string, se espera que los años estén separados por un ; ";"
    if (arr.includes(";")) {
      const newArray = arr.split(";").map((year) => parseInt(year.trim(), 10));
      if (!newArray.every((year) => Number.isInteger(year) && year >= 1000 && year <= 9999)) {
        console.error("Al menos un elemento del array no es un año válido de 4 dígitos:", arr);
        return false;
      }
    } else {
      const parsedYear = parseInt(arr.trim(), 10);
      if (!(Number.isInteger(parsedYear) && parsedYear >= 1000 && parsedYear <= 9999)) {
        console.error("El valor no es un año válido de 4 dígitos:", arr);
        return false;
      }
    }
  } else if (Array.isArray(arr)) {
    // Si es un array, se valida cada elemento del array
    if (
      !arr.every((year) => {
        if (typeof year === "string") {
          const parsedYear = parseInt(year.trim(), 10);
          return Number.isInteger(parsedYear) && parsedYear >= 1000 && parsedYear <= 9999;
        }
        return Number.isInteger(year) && year >= 1000 && year <= 9999;
      })
    ) {
      console.error("Al menos un elemento del array no es un año válido de 4 dígitos:", arr);
      return false;
    }
  } else {
    console.error("El valor no es un array o una cadena que contiene un guion:", arr);
    return false;
  }
  return true;
}

export function validateMostNegative(arr) {
  if (!Array.isArray(arr)) {
    console.error("El valor no es un array:", arr);
    return false;
  }

  let countPositives = 0;
  let countNegatives = 0;

  arr.forEach((element) => {
    if (element > 0) {
      countPositives++;
    } else {
      countNegatives++;
    }
  });

  if (countPositives >= countNegatives) {
    return false;
  } else {
    return true;
  }
}

export function validateArrayNumbers(arr) {
  if (typeof arr === "string") {
    // Si es una cadena que contiene un guion
    if (arr.includes("-")) {
      const newArray = arr.split("-").map((value) => {
        const number = parseFloat(value.replace(",", "."));
        return isNaN(number) ? NaN : number;
      });
      if (!newArray.every((value) => !isNaN(value))) {
        console.error("Al menos un elemento del array no es un número:", arr);
        return false;
      }
    } else {
      // Si es una cadena que no contiene un guion
      const number = parseFloat(arr.replace(",", "."));
      return !isNaN(number);
    }
  } else if (Array.isArray(arr)) {
    // Si es un array
    if (
      !arr.every((value) => {
        if (typeof value === "string") {
          const number = parseFloat(value.replace(",", "."));
          return !isNaN(number);
        }
        return typeof value === "number" && !isNaN(value);
      })
    ) {
      console.error("Al menos un elemento del array no es un número:", arr);
      return false;
    }
  } else {
    // Si no es ni una cadena ni un array
    console.error("El valor no es un array o una cadena que contiene un guion:", arr);
    return false;
  }
  return true;
}

export function validatePositiveArrayNumbers(arr) {
  if (typeof arr === "string") {
    if (arr.includes("-")) {
      const newArray = arr.split("-").map((value) => {
        const number = parseFloat(value.replace(",", "."));
        return isNaN(number) ? NaN : number;
      });
      if (!newArray.every((value) => !isNaN(value) && value > 0)) {
        console.error("Al menos un elemento del array no es un número positivo:", arr);
        return false;
      }
    } else {
      const number = parseFloat(arr.replace(",", "."));
      return !isNaN(number) && number > 0;
    }
  } else if (Array.isArray(arr)) {
    if (
      !arr.every((value) => {
        if (typeof value === "string") {
          const number = parseFloat(value.replace(",", "."));
          return !isNaN(number) && number > 0;
        }
        return typeof value === "number" && !isNaN(value) && value > 0;
      })
    ) {
      console.error("Al menos un elemento del array no es un número positivo:", arr);
      return false;
    }
  } else {
    console.error("El valor no es un array o una cadena que contiene un guion:", arr);
    return false;
  }
  return true;
}

export function validateEmptyArray(arr) {
  if (arr.length === 0) {
    return false;
  } else if (arr.length === 1 && arr[0] === "") {
    return false;
  } else {
    return true;
  }
}

export function validateisEmpty(value) {
  if (value === "") {
    return true;
  }
  return false;
}
export function validateIsPositive(numero) {
  // Verificar si el número es un entero y es mayor que cero
  return numero > 0;
}
export function validatePositiveNumber(value) {
  // Eliminar todos los símbolos excepto los dígitos y el punto decimal
  const cleanedValue = value.replace(/[^0-9.()-]/g, "").trim();
  // Verificar si el valor tiene paréntesis, indicando un número negativo
  const isNegative = cleanedValue.includes("(") && cleanedValue.includes(")");

  // Eliminar los paréntesis si están presentes
  const normalizedValue = cleanedValue.replace(/[()]/g, "");
  // Convertir la cadena resultante a un número
  const numberValue = parseFloat(normalizedValue);

  // Verificar si el número es positivo (incluyendo 0) y no estaba entre paréntesis
  const isPositive = !isNaN(numberValue) && numberValue >= 0 && !isNegative;

  return isPositive;
}
