import * as React from "react";
import ClipImage from "../assets/ClipImage";
import { inputClipStyles } from "../../css/inputClip";
import useStoreInputs from "../store/globalStates";
import { handleSelection } from "../helpers/inputClipHandlers";

interface InputClipImageProps {
  nameItem: string;
  disabled: boolean;
  setInputValueLocal: (value: string) => void;
}

const InputClipImage: React.FC<InputClipImageProps> = ({ nameItem, disabled, setInputValueLocal }) => {
  const styles = inputClipStyles();
  const { getColorInputFont, getCelda } = useStoreInputs((state) => state);

  return getColorInputFont(nameItem) !== true ? (
    <div
      className={styles.inputImage}
      onClick={() => {
        !disabled && handleSelection(nameItem, setInputValueLocal);
      }}
      style={{
        backgroundColor: "#C2C2C7",
        height: "0.7rem",
        width: "0.7rem",
        color: "#FBFBFE",
        display: "flex",
        flexDirection: "column",
        alignItems: "center",
        justifyContent: "center",
        cursor: disabled ? "not-allowed" : "pointer",
      }}
    >
      <ClipImage />
    </div>
  ) : (
    <div
      className={styles.inputCelda}
      onClick={() => {
        !disabled && handleSelection(nameItem, setInputValueLocal);
      }}
      style={{
        height: "0.7rem",
        minWidth: "0.7rem",
        width: "auto",
        display: "flex",
        flexDirection: "column",
        alignItems: "center",
        justifyContent: "center",
        backgroundColor: " #9A9AA2",
        cursor: "pointer",
      }}
    >
      <h4 className={styles.celdaLabel}>{getCelda(nameItem)}</h4>
    </div>
  );
};

export default InputClipImage;
