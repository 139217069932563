import * as React from "react";
import { makeStyles, shorthands } from "@fluentui/react-components";
import useStoreInputs from "../store/globalStates";

interface InputSelectAndCheckboxProps {
  options: string[] | number[]; // Lista de opciones para el select
  width_optional?: string;
  height_optional?: string;
  bgColor?: string;
  fontColor?: string;
  textSide?: string;
  defaultValue?: string | number;
  inputName: string;
  disabled: boolean;
  invisible: boolean;
}

const useStyles = makeStyles({
  select: {
    width: "4rem",
    height: "1.5rem",
    ...shorthands.borderRadius("0.25rem"),
    ...shorthands.padding("0.25rem"),
    backgroundColor: "E8E8EE",
    ...shorthands.border("none"),
    ...shorthands.outline("none"),
    color: "black",
    fontWight: "500",
    fontFamily: "Poppins",
    fontSize: "0.625rem",
    lineHeight: "1rem",
  },
  checkboxStep3: {
    minHeight: "0.921rem",
    minWidth: "0.921rem",
    accentColor: "black",
  },
});

const InputSelectAndCheckbox: React.FC<InputSelectAndCheckboxProps> = ({
  defaultValue,
  options,
  width_optional,
  height_optional,
  bgColor,
  fontColor,
  textSide,
  inputName,
  disabled,
  invisible,
}) => {
  const styles = useStyles();
  const { setValueByName, getInputValue } = useStoreInputs((state) => state);
  const [checked, setChecked] = React.useState(false);
  const [valueSelected, setValueSelected] = React.useState(defaultValue);
  const handleOnChange = (e: React.ChangeEvent<HTMLSelectElement>) => {
    setValueSelected(e.target.value);
    setValueByName(inputName, e.target.value);
  };
  React.useEffect(() => {
    if (!invisible) {
      if (inputName === "PEMC1" || inputName === "EVC1") {
        setChecked(true);
        setValueByName(inputName, String(options[0]));
      }
    } else {
      setValueByName(inputName, "");
    }
    if (defaultValue !== undefined && checked) {
      setValueByName(inputName, String(defaultValue));
    }
  }, [defaultValue, invisible]);

  function handleCheckbox() {
    if (checked) {
      setChecked(false);
      setValueByName(inputName, "");
    } else {
      setChecked(true);
      if (getInputValue(inputName) === "") {
        setValueByName(inputName, String(valueSelected));
      }
    }
  }

  return (
    <div
      style={{
        display: "flex",
        flexDirection: "row",
        alignItems: "center",
        justifyContent: "flex-start",
      }}
    >
      <input
        type="checkbox"
        disabled={disabled}
        className={styles.checkboxStep3}
        checked={checked}
        onChange={handleCheckbox}
        id={inputName}
      ></input>
      <select
        className={styles.select}
        disabled={disabled}
        id={`select-${inputName}`}
        style={{
          ...(width_optional ? { width: width_optional } : {}),
          ...(height_optional ? { height: height_optional } : {}),
          ...(bgColor ? { backgroundColor: bgColor } : {}),
          ...(fontColor ? { color: fontColor } : {}),
          ...(textSide ? { textAlign: textSide as any } : {}),
        }}
        defaultValue={defaultValue}
        onChange={handleOnChange}
      >
        {Array.isArray(options) &&
          options.map((option, index) => (
            <option key={index} value={option}>
              {option}
            </option>
          ))}
      </select>
    </div>
  );
};

export default InputSelectAndCheckbox;
