import * as React from "react";
import { valuationsStyles } from "../../css/valuations";
import FrequentlyUsed from "./Frequently/FrequentlyUsed";
import MyValuations from "./MyValuations/MyValuations";
import { useStoreValuations } from "../store/globalStates";
import fetch from "node-fetch";

const Valuations = () => {
  const { valuations } = useStoreValuations();
  const styles = valuationsStyles();
  const [version, setVersion] = React.useState("");
  React.useEffect(() => {
    const fetchXml = async () => {
      try {
        const response = await fetch("../../../manifest.xml");
        const text = await response.text();
        const parser = new DOMParser();
        const xmlDoc = parser.parseFromString(text, "text/xml");
        const versionNumber = xmlDoc.getElementsByTagName("Version")[0].childNodes[0].nodeValue;
        setVersion(versionNumber);
      } catch (error) {
        // console.error("Error fetching the XML file:", error);
      }
    };

    fetchXml();
  }, []);
  return (
    <>
      <div className={styles.root}>
        <FrequentlyUsed />
        {valuations.length > 0 && (
          <>
            <MyValuations />
          </>
        )}
        <div className={styles.version}> Version {version}</div>
      </div>
    </>
  );
};

export default Valuations;
