import React from "react";
import InputClip from "../../../Inputs/InputClip";
import useStoreInputs from "../../../store/globalStates";
import { stylesSteps2 } from "../../../../css/step2";

export default function YearsSelection() {
  const { getLabelValidation, getShowValidation } = useStoreInputs((state) => state);

  const styles = stylesSteps2();

  return (
    <div className={styles.yearContainer}>
      <div style={{ display: "flex", flexDirection: "column" }}>
        <h4 className={styles.titleGeneral}>Years Selection</h4>
        {getShowValidation("YS") && (
          <div className={styles.validationContainer} style={{ marginRight: "auto" }}>
            <h4 className={styles.validation} style={{ marginRight: "0.313rem", marginLeft: "0.313rem" }}>
              {getLabelValidation("YS")}
            </h4>
          </div>
        )}
      </div>
      {<InputClip type="text" placeholder="" nameItem="YS" />}
    </div>
  );
}
