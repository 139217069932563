import Lottie from "lottie-react";
import React from "react";
import animation from "./animation.json";
export default function ReportLoader() {
  return (
    <div style={{ display: "flex", flexDirection: "column", alignItems: "center" }}>
      <Lottie animationData={animation} autoplay={true} />
      <h3 style={{ fontFamily: "Poppins" }}>Examining your data...</h3>
    </div>
  );
}
