import * as React from "react";
import useStoreInputs, { useStoreRoutingService, useStoreSteps } from "../store/globalStates";

import StepContent2 from "./Step/Step2/StepContent2";
import StepContent3 from "./Step/Step3/StepContent3";
import ButtonsContainer from "./Buttons/ButtonsContainer";
import { useStylesGlobal } from "../../css/styles";
import StepHeader from "./StepHeader";
import StepContent1 from "./Step/Step1/StepContent";
import { secondStepItems } from "../helpers/namesInputs";
import { extractSheetNameAndRange, processInputValue } from "../helpers/inputClipHandlers";

const StepStructure = () => {
  const { inputs, setValueByName, setShowValidation, getInputValue, setColorInputFont } = useStoreInputs(
    (state) => state
  );
  const { stepNumber, setStepNumber } = useStoreSteps((state) => state);

  const updateCells = async () => {
    inputs.forEach(async (input) => {
      if (input.step === stepNumber && input.celdaWithSheet != "") {
        await Excel.run(async (context) => {
          // Usa la función para obtener el nombre de la hoja y el rango
          const { sheetName, range } = extractSheetNameAndRange(input.celdaWithSheet);

          if (!sheetName || !range) {
            console.error("Error al extraer el nombre de la hoja o el rango:", input.celdaWithSheet);
            return;
          }
          // Obtén la hoja específica por su nombre
          const sheet = context.workbook.worksheets.getItem(sheetName);
          const celdas = sheet.getRange(range);
          celdas.load("text"); // Carga el valor de la celda

          await context.sync();

          const value = String(celdas.text[0][0]);
          const valueArray = celdas.text[0];

          if (secondStepItems.includes(input.name)) {
            processInputValue(input.name, valueArray, true);
          } else {
            processInputValue(input.name, value, true);
          }
        });
      }
    });
  };

  const backIsPressed = () => {
    inputs.forEach((input) => {
      setShowValidation(input.name, false);
    });
    if (stepNumber > 1) {
      setStepNumber(stepNumber - 1);
    } else {
      setRoutingNumber(routingNumber - 1);
    }
  };
  const styles = useStylesGlobal();
  function getCurrentDate() {
    const currentDate = new Date();
    const day = String(currentDate.getDate()).padStart(2, "0");
    const month = String(currentDate.getMonth() + 1).padStart(2, "0"); // Los meses empiezan desde 0
    const year = currentDate.getFullYear();

    return `${month}/${day}/${year}`;
  }
  function getCurrentYearPlusFive() {
    const currentDate = new Date();
    const currentYear = currentDate.getFullYear();
    return String(currentYear + 5);
  }
  const { routingNumber, setRoutingNumber } = useStoreRoutingService((state) => state);

  const handleNext = async () => {
    if (getInputValue("VD") === "") {
      setValueByName("VD", getCurrentDate());
      setColorInputFont("VD", false);
    }
    if (getInputValue("FCFY") === "") {
      setValueByName("FCFY", getCurrentYearPlusFive());
      setColorInputFont("FCFY", false);
    }
    await updateCells();

    // eslint-disable-next-line no-undef
    setTimeout(() => {
      let isValidationReady = true;
      inputs.forEach((input) => {
        if (input.step === stepNumber) {
          if (input.validation === false) {
            isValidationReady = false;
            setShowValidation(input.name, true); // si se muestran las validaciones cuando toca next
          }
        }
      });

      if (isValidationReady) {
        setStepNumber(stepNumber + 1);
      } // next
    }, 300);
  };

  // eslint-disable-next-line no-undef
  Office.onReady(() => {
    // Función para modificar todos los elementos
    const modifyAllElementsTabIndex = () => {
      // eslint-disable-next-line no-undef
      const allElements = document.querySelectorAll("button");
      allElements.forEach((element) => {
        element.tabIndex = -1;
      });
    };
    // Llamar a la función cuando el documento esté listo
    modifyAllElementsTabIndex();
  });
  const botonRef = React.useRef<HTMLButtonElement>(null);
  const botonFinishRef = React.useRef<HTMLButtonElement>(null);

  const handleTabPress = () => {
    // eslint-disable-next-line no-undef
    setTimeout(() => {
      if (botonRef.current && stepNumber !== 3) {
        botonRef.current.focus();
      } else if (botonFinishRef.current) {
        botonFinishRef.current.focus();
      }
    }, 150);

    // botonRef.current.focus(); // Enfoca el botón
  };
  return (
    <>
      <div className={styles.root}>
        <StepHeader />
        {stepNumber === 1 ? (
          <StepContent1 onTabPress={handleTabPress} />
        ) : stepNumber === 2 ? (
          <StepContent2 onTabPress={handleTabPress} />
        ) : (
          <StepContent3 onTabPress={handleTabPress} />
        )}
        <ButtonsContainer
          refFinish={botonFinishRef}
          ref={botonRef}
          backIsPressed={backIsPressed}
          handleNext={handleNext}
        />
      </div>
    </>
  );
};

export default StepStructure;
