import React from "react";
import InputClip from "../../../Inputs/InputClip";
import InputCheckbox from "../../../Inputs/InputCheckbox";
import { stylesSteps } from "../../../../css/steps";
import useStoreInputs, { useSelectOptionStore } from "../../../store/globalStates";
import Tooltip from "../../../Inputs/Tooltip";
import ChevronImage from "../../../assets/ChevronImage";
import InputSelect from "../../../Inputs/InputSelect";

export default function General() {
  const styles = stylesSteps();
  const { optionsIU } = useSelectOptionStore();
  const { chevronStates, setChevronStates } = useStoreInputs();
  const { getInputValidation, getShowValidation, getInputValue, getLabelValidation } = useStoreInputs((state) => state);
  return (
    <div className={styles.stepOneContainers}>
      <div className={styles.generalTitle}>
        <h4 className={styles.subStepTitle}>General</h4>
        <button
          tabIndex={-1}
          style={{
            border: "none",
            backgroundColor: "transparent",
            transform: `rotate(${chevronStates[0].rotation}deg)`,
            transition: "transform 0.5s ease",
            cursor: "pointer",
          }}
          onClick={() => {
            setChevronStates(0);
          }}
        >
          <ChevronImage></ChevronImage>
        </button>
      </div>
      <div
        style={{
          opacity: chevronStates[0].visible ? "1" : "0",
          transition: chevronStates[0].visible ? "opacity 0.3s ease 0.3s" : "",
          backgroundColor: "#fff",
        }}
      >
        <div
          className={styles.stepInput}
          style={{
            opacity: chevronStates[0].visible ? "1" : "0",
            transition: chevronStates[0].visible ? "opacity 0.3s ease 0.3s" : "",
            backgroundColor: "#fff",
          }}
        >
          <h4 className={styles.titleGeneral}>Input Units</h4>
          <div className={styles.dobleInputContainer} style={{ gap: "0.3rem" }}>
            <InputSelect
              options={optionsIU.IU1}
              width_optional="3.8rem"
              height_optional="1.875rem"
              bgColor="#C2C2C7"
              inputName="IU1"
            />
            <InputSelect
              options={optionsIU.IU2}
              height_optional="1.875rem"
              width_optional="3.8rem"
              bgColor="#E1E1E3"
              textSide="center"
              inputName="IU2"
            />
          </div>
        </div>
        <div className={styles.stepInput} style={{ backgroundColor: "#fff" }}>
          <div style={{ display: "flex", flexDirection: "column" }}>
            <h4 className={styles.titleGeneral}>Valuation Date</h4>
            {getShowValidation("VD") && (
              <div className={styles.validationContainer} style={{ marginRight: "auto" }}>
                <h4 className={styles.validation} style={{ marginRight: "0.313rem", marginLeft: "0.313rem" }}>
                  {getLabelValidation("VD")}
                </h4>
              </div>
            )}
          </div>
          <InputClip type="text" placeholder="mm/dd/yyyy" nameItem="VD" />
        </div>
        <div className={styles.stepInput} style={{ backgroundColor: "#fff", height: "auto" }}>
          <div style={{ display: "flex", flexDirection: "column" }}>
            <h4 className={styles.titleGeneral}>Final Cash Flow Year</h4>
            {getInputValidation("FCFY") === false && getShowValidation("FCFY") && (
              <div className={styles.validationContainer} style={{ marginRight: "auto" }}>
                <h4 className={styles.validation} style={{ marginRight: "0.313rem", marginLeft: "0.313rem" }}>
                  {getLabelValidation("FCFY")}
                </h4>
              </div>
            )}
          </div>
          {/* {getWarning('FCFY') && getInputValidation('FCFY') &&<div className={styles.validationContainer} ><h4 className={styles.validation} style={{marginRight:'0.313rem', marginLeft:'0.313rem'}}>{getLabelValidation('FCFY')}</h4></div>} */}
          <InputClip type="text" placeholder="yyyy" nameItem="FCFY" />
        </div>
        <div className={styles.stepInput} style={{ backgroundColor: "#fff" }}>
          <div style={{ display: "flex", flexDirection: "column" }}>
            <h4 className={styles.titleGeneral}>Mid-Year Convention</h4>
            {getInputValidation("MYC") === false && getShowValidation("MYC") && (
              <div className={styles.validationContainer} style={{ marginRight: "auto" }}>
                <h4 className={styles.validation} style={{ marginRight: "0.313rem", marginLeft: "0.313rem" }}>
                  Wrong format
                </h4>
              </div>
            )}
          </div>
          <div className={styles.dobleInputContainer}>
            <InputCheckbox nameItem="MYC" />
            <InputClip type="checkbox" nameItem="MYC" linkedToggle={true} />
          </div>
        </div>

        <div className={styles.stepInput} style={{ backgroundColor: "#fff" }}>
          <div style={{ display: "flex", flexDirection: "column" }}>
            <h4 className={styles.titleGeneral} style={{ opacity: `${!getInputValue("UDCF") ? 0.2 : 1}` }}>
              WACC
            </h4>
            {getShowValidation("WACC") && (
              <div className={styles.validationContainer} style={{ marginRight: "auto" }}>
                <h4 className={styles.validation} style={{ marginRight: "0.313rem", marginLeft: "0.313rem" }}>
                  {getLabelValidation("WACC")}
                </h4>
              </div>
            )}
          </div>
          {!getInputValue("UDCF") && (
            <Tooltip right={true} infoText="Not Needed for Selected Valuation Methodologies" title=" WACC"></Tooltip>
          )}
          {getInputValue("UDCF") && <InputClip type="text" placeholder="%" nameItem="WACC" />}
        </div>

        <div className={styles.stepInput} style={{ backgroundColor: "#fff" }}>
          <div
            style={{
              display: "flex",
              flexDirection: "column",
              width: `${!getInputValue("DDM") && !getInputValue("LDCF") ? "100%" : "auto"}`,
            }}
          >
            <div style={{ display: "flex", flexDirection: "row", justifyContent: "space-between" }}>
              <h4
                className={styles.titleGeneral}
                style={{
                  opacity: `${getInputValue("DDM") || getInputValue("LDCF") ? 1 : 0.2}`,
                }}
              >
                Cost of Equity
              </h4>
              {!getInputValue("DDM") && !getInputValue("LDCF") && (
                <Tooltip
                  right={true}
                  infoText="Not Needed for Selected Valuation Methodologies"
                  title="Cost Of Equity"
                ></Tooltip>
              )}
            </div>
            {getShowValidation("COE") && (
              <div className={styles.validationContainer} style={{ marginRight: "auto" }}>
                <h4 className={styles.validation} style={{ marginRight: "0.313rem", marginLeft: "0.313rem" }}>
                  {getLabelValidation("COE")}
                </h4>
              </div>
            )}
          </div>
          {(getInputValue("DDM") || getInputValue("LDCF")) && <InputClip type="text" placeholder="%" nameItem="COE" />}
        </div>
      </div>
    </div>
  );
}
