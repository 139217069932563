import * as React from "react";
import InputClip from "../../../Inputs/InputClip";
import InputSelect from "../../../Inputs/InputSelect";
import useStoreInputs from "../../../store/globalStates";
import Tooltip from "../../../Inputs/Tooltip";
import { stylesSteps } from "../../../../css/steps";

interface InputComponentProps {
  onTabPress: () => void;
}

const ConfigurationStep3: React.FC<InputComponentProps> = ({ onTabPress }) => {
  const { getNetIncomeEmpty } = useStoreInputs((state) => state);
  const styles = stylesSteps();
  const { chevronStates } = useStoreInputs();
  const { getInputValue, getInputValidation, getShowValidation } = useStoreInputs((state) => state);
  return (
    <div
      style={{
        transition: chevronStates[4].visible ? "transform 0.5s ease" : "transform 0.5s ease",
        transform: chevronStates[4].visible ? "translateY(0)" : "translateY(-60%)",
      }}
    >
      <div
        className={styles.yearContainer}
        style={{ display: "flex", justifyContent: "space-between", alignItems: "center" }}
      >
        <h4
          className={styles.titleGeneral}
          style={{
            paddingTop: "1rem",
            paddingBottom: "1rem",
            paddingLeft: "0.5rem",
            opacity: `${getInputValue("PEM") ? 1 : 0.2}`,
          }}
        >
          Net Income
        </h4>
        {(getInputValidation("NI") === false || getInputValue("NI") === "" || getInputValue("NI")[0] === "") &&
          getInputValue("PEM") && (
            <div className={styles.validationContainer}>
              <h4 className={styles.validation} style={{ marginRight: "0.313rem", marginLeft: "0.313rem" }}>
                Is mandatory
              </h4>
            </div>
          )}
        <InputClip type="text" placeholder="" nameItem="NI" disabled={!getInputValue("PEM")} />
      </div>
      <div className={styles.stepInput} style={{ backgroundColor: "#fff" }}>
        <h4 className={styles.titleGeneral}>Rows per Table</h4>

        <InputSelect
          options={[3, 5, 7]}
          width_optional="3.8rem"
          height_optional="1.875rem"
          bgColor="#E1E1E3"
          textSide="center"
          defaultValue={5}
          inputName="RPT"
        />
      </div>
      <div className={styles.stepInput} style={{ backgroundColor: "#fff" }}>
        <h4 className={styles.titleGeneral}>Columns per Table</h4>
        <InputSelect
          options={[3, 5, 7]}
          width_optional="3.8rem"
          height_optional="1.875rem"
          bgColor="#E1E1E3"
          textSide="center"
          defaultValue={5}
          inputName="CPT"
        />
      </div>
      <div className={styles.stepInput} style={{ backgroundColor: "#fff" }}>
        <div style={{ display: "flex", flexDirection: "column" }}>
          <h4 className={styles.titleGeneral}>WACC Step</h4>
          {getInputValidation("YS") === false &&
            getShowValidation("YS") &&
            getInputValue("YS").length !== 0 &&
            getInputValue("YS")[0] !== "" && (
              <div className={styles.validationContainer} style={{ marginRight: "auto" }}>
                <h4 className={styles.validation} style={{ marginRight: "0.313rem", marginLeft: "0.313rem" }}>
                  Wrong format
                </h4>
              </div>
            )}
        </div>
        <InputClip type="text" placeholder="0" nameItem="WACCS" />
      </div>

      <div className={styles.stepInput} style={{ backgroundColor: "#fff" }}>
        <h4
          className={styles.titleGeneral}
          style={{
            opacity: `${getInputValue("UDCF") && !getInputValue("LDCF") && !getInputValue("DDM") ? 0.2 : 1}`,
          }}
        >
          Cost of Equity Step
        </h4>
        {getInputValue("UDCF") && !getInputValue("LDCF") && !getInputValue("DDM") && (
          <Tooltip
            right={true}
            infoText="Not Needed for Selected Valuation Methodologies"
            title="Cost of Equity Step"
          ></Tooltip>
        )}
        {!(getInputValue("UDCF") && !getInputValue("LDCF") && !getInputValue("DDM")) && (
          <InputClip type="text" placeholder="0" nameItem="COES" />
        )}
      </div>
      <div className={styles.stepInput} style={{ backgroundColor: "#fff" }}>
        <div style={{ display: "flex", gap: "1rem" }}>
          <h4 className={styles.titleGeneral} style={{ opacity: `${!getInputValue("PGRC") ? 0.2 : 1}` }}>
            PGR Step
          </h4>
          <Tooltip infoText="Not Needed for Selected Valuation Methodologies" title=" PGR Step"></Tooltip>
        </div>
        {!getInputValue("PGRC") && <Tooltip right={true} infoText="PGR Step" title="Cost of Equity Step"></Tooltip>}
        {getInputValue("PGRC") && <InputClip type="text" placeholder="0" nameItem="PGRS" />}
      </div>
      <div className={styles.stepInput} style={{ backgroundColor: "#fff", marginBottom: "5rem" }}>
        <h4 className={styles.titleGeneral} style={{ maxWidth: "50%", opacity: `${!getInputValue("TEMC") ? 0.2 : 1}` }}>
          Terminal EBITDA Multiple Step
        </h4>
        {!getInputValue("TEMC") && (
          <Tooltip
            right={true}
            infoText="Not Needed for Selected Valuation Methodologies"
            title="Terminal EBITDA Multiple Step"
          ></Tooltip>
        )}
        {getInputValue("TEMC") && <InputClip type="text" placeholder="0" nameItem="TEMS" onTabPress={onTabPress} />}
      </div>
    </div>
  );
};
export default ConfigurationStep3;
