import React from "react";
import { stylesSteps } from "../../../../css/steps";
import useStoreInputs from "../../../store/globalStates";
import { validatePercentage, validateTEM } from "../../../Validations/ValidateFunctions";

export default function ReportCheckboxs() {
  const styles = stylesSteps();
  const handleCheckboxChange = (inputName: string) => {
    const inputValue = getInputValue(inputName);
    setValueByName(inputName, !inputValue);

    if (inputName === "PGRC") {
      const isNumberAndPercent = validatePercentage(inputValue);
      setValidationByName("PGR", !isNumberAndPercent);
    }

    if (inputName === "TEMC") {
      const isValidate = validateTEM(inputValue);
      setValidationByName("TEM", !isValidate);
    }
  };
  const { setValidationByName, setValueByName, getInputValue } = useStoreInputs((state) => state);
  return (
    <div className={styles.stepOneContainers}>
      <div className={styles.stepCheckbox} style={{ backgroundColor: "#fff", borderRadius: "6px" }}>
        <input
          type="checkbox"
          className={styles.checkboxStep3}
          checked={getInputValue("UDCF")}
          onChange={() => handleCheckboxChange("UDCF")}
          id="checkbox-UDCF"
        />
        <div className={styles.titlesCheckbox}>
          <h4 className={styles.titleCheckbox}>Unlevered Discounted Cash Flows</h4>
          <h6 className={styles.subTitleCheckbox}>(Unlevered DCF)</h6>
        </div>
      </div>
      <div className={styles.stepCheckbox} style={{ backgroundColor: "#fff", borderRadius: "6px" }}>
        <input
          type="checkbox"
          className={styles.checkboxStep3}
          checked={getInputValue("LDCF")}
          onChange={() => handleCheckboxChange("LDCF")}
          id="checkbox-LDCF"
        />
        <div className={styles.titlesCheckbox}>
          <h4 className={styles.titleCheckbox}>Levered Discounted Cash Flows</h4>
          <h6 className={styles.subTitleCheckbox}>(Levered DCF)</h6>
        </div>
      </div>
      <div className={styles.stepCheckbox} style={{ backgroundColor: "#fff", borderRadius: "6px" }}>
        <input
          type="checkbox"
          className={styles.checkboxStep3}
          checked={getInputValue("DDM")}
          onChange={() => handleCheckboxChange("DDM")}
          id="checkbox-DDM"
        />
        <div className={styles.titlesCheckbox}>
          <h4 className={styles.titleCheckbox}>Dividend Discounted Model</h4>
          <h6 className={styles.subTitleCheckbox}>(DDM)</h6>
        </div>
      </div>
    </div>
  );
}
