import * as React from "react";
import { makeStyles, shorthands } from "@fluentui/react-components";
import LogoNoNighter from "./assets/LogoNoNighter";
import HamburguerIcon from "./assets/HamburguerIcon";
import { useStoreRoutingService } from "./store/globalStates";

export interface HeaderProps {
  title: string;
  logo: string;
  message: string;
}

const useStyles = makeStyles({
  banner: {
    display: "flex",
    flexDirection: "row",
    alignItems: "center",
    justifyContent: "space-between",
    backgroundImage: "linear-gradient(to right, #101010, #444444)",
    height: "3.125rem",
    paddingLeft: "1rem",
    paddingRight: "1rem",
  },
  imgLogo: {
    width: "100px",
  },
  selectDemo: {
    ...shorthands.borderRadius("1rem"),
    width: "4rem",
    height: "1.5rem",
    ...shorthands.borderRadius("0.25rem"),
    ...shorthands.padding("0.25rem"),
    backgroundColor: "#232C33",
    ...shorthands.border("none"),
    ...shorthands.outline("none"),
    marginRight: "2rem",
    color: "#fff",
    fontWight: "500",
    fontFamily: "Poppins",
    fontSize: "0.625rem",
    lineHeight: "1rem",
  },
});

const Header: React.FC<HeaderProps> = () => {
  const styles = useStyles();
  const { setRoutingNumber } = useStoreRoutingService();
  return (
    <div className={styles.banner}>
      <LogoNoNighter />
      <div
        onClick={() => {
          setRoutingNumber(0);
        }}
        style={{ cursor: "pointer" }}
      >
        <HamburguerIcon />
      </div>
    </div>
  );
};

export default Header;
