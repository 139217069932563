import React from "react";

export default function ValuationIcon() {
  return (
    <svg width="35" height="42" viewBox="0 0 35 42" fill="none" xmlns="http://www.w3.org/2000/svg">
      <path
        d="M4.93336 19.3016C5.27263 19.4269 5.65229 19.5036 6.04407 19.5036H12.5831C14.3239 19.5036 15.7375 18.09 15.7375 16.3492V10.7956C15.7375 9.05483 14.3239 7.6412 12.5831 7.6412H6.04407C5.65229 7.6412 5.27263 7.71794 4.93336 7.84315C3.73379 8.2834 2.88965 9.44661 2.88965 10.7956V16.3492C2.88965 17.6982 3.73379 18.8614 4.93336 19.3016Z"
        fill="#C287F7"
      />
      <path
        d="M31.1744 12.4112C31.0371 11.9427 30.7705 11.5146 30.403 11.1632L24.3809 5.12898C24.0255 4.78971 23.5973 4.53525 23.1329 4.38581V10.2786C23.1329 11.454 24.0901 12.4112 25.2654 12.4112H31.1744Z"
        fill="#C287F7"
      />
      <path
        d="M25.2695 13.6754C23.4035 13.6754 21.8727 12.1487 21.8727 10.2786V4.22021H8.09181C6.65395 4.22021 5.44226 5.18148 5.0626 6.50626C5.38976 6.4174 5.71691 6.38105 6.04811 6.38105H12.5872C15.0226 6.38105 17.0058 8.36417 17.0058 10.7997V16.3532C17.0058 18.7887 15.0226 20.7718 12.5872 20.7718H6.04407C5.66441 20.7718 5.3009 20.7234 4.93336 20.6345V35.0737C4.93336 36.8145 6.34699 38.2281 8.08777 38.2281H28.1815C29.9223 38.2281 31.336 36.8145 31.336 35.0737V13.6754H25.2654H25.2695Z"
        fill="#A785EF"
      />
      <path
        d="M7.26787 12.9403H8.6815V11.5267C8.6815 11.1712 8.96019 10.8966 9.31157 10.8966C9.66296 10.8966 9.94165 11.1753 9.94165 11.5267V12.9403H11.3553C11.7107 12.9403 11.9854 13.219 11.9854 13.5704C11.9854 13.9218 11.7067 14.2005 11.3553 14.2005H9.94165V15.6141C9.94165 15.9695 9.66296 16.2442 9.31157 16.2442C8.96019 16.2442 8.6815 15.9655 8.6815 15.6141V14.2005H7.26787C6.91244 14.2005 6.63779 13.9218 6.63779 13.5704C6.63779 13.219 6.91648 12.9403 7.26787 12.9403Z"
        fill="white"
      />
      <path
        d="M25.2573 30.9419H11.02C10.6646 30.9419 10.39 30.6511 10.39 30.3118C10.39 29.9725 10.6687 29.6817 11.02 29.6817H25.2573C25.6128 29.6817 25.8874 29.9604 25.8874 30.3118C25.8874 30.6632 25.6087 30.9419 25.2573 30.9419Z"
        fill="white"
      />
      <path
        d="M25.2573 34.9808H11.02C10.6646 34.9808 10.39 34.69 10.39 34.3508C10.39 34.0115 10.6687 33.7207 11.02 33.7207H25.2573C25.6128 33.7207 25.8874 33.9994 25.8874 34.3508C25.8874 34.7022 25.6087 34.9808 25.2573 34.9808Z"
        fill="white"
      />
      <path
        d="M25.2573 26.5717H11.02C10.6646 26.5717 10.39 26.2931 10.39 25.9417C10.39 25.5903 10.6687 25.3116 11.02 25.3116H25.2573C25.6128 25.3116 25.8874 25.6024 25.8874 25.9417C25.8874 26.2809 25.6087 26.5717 25.2573 26.5717Z"
        fill="white"
      />
      <path
        d="M25.2573 22.2178H20.3096C19.9542 22.2178 19.6796 21.9391 19.6796 21.5877C19.6796 21.2363 19.9582 20.9576 20.3096 20.9576H25.2573C25.6128 20.9576 25.8874 21.2484 25.8874 21.5877C25.8874 21.927 25.6087 22.2178 25.2573 22.2178Z"
        fill="white"
      />
      <path
        d="M25.2573 17.8638H20.3096C19.9542 17.8638 19.6796 17.5851 19.6796 17.2337C19.6796 16.8823 19.9582 16.6036 20.3096 16.6036H25.2573C25.6128 16.6036 25.8874 16.8823 25.8874 17.2337C25.8874 17.5851 25.6087 17.8638 25.2573 17.8638Z"
        fill="white"
      />
    </svg>
  );
}
