import * as React from "react";
import { makeStyles, shorthands } from "@fluentui/react-components";
import useStoreInputs, { useModalUnits, useSelectOptionStore } from "../store/globalStates";

interface InputSelectProps {
  options: string[] | number[]; // Lista de opciones para el select
  width_optional?: string;
  height_optional?: string;
  bgColor?: string;
  fontColor?: string;
  textSide?: string;
  defaultValue?: string | number;
  inputName: string;
}

const useStyles = makeStyles({
  select: {
    width: "4rem",
    height: "1.5rem",
    ...shorthands.borderRadius("0.25rem"),
    ...shorthands.padding("0.25rem"),
    backgroundColor: "E8E8EE",
    ...shorthands.border("none"),
    ...shorthands.outline("none"),
    color: "black",
    fontWight: "500",
    fontFamily: "Poppins",
    fontSize: "0.625rem",
    lineHeight: "1rem",
  },
});

const InputSelect: React.FC<InputSelectProps> = ({
  defaultValue,
  options,
  width_optional,
  height_optional,
  bgColor,
  fontColor,
  textSide,
  inputName,
}) => {
  const styles = useStyles();
  const { optionsIU } = useSelectOptionStore((state) => ({
    optionsIU: state.optionsIU[inputName],
  }));
  const { setValueByName, getInputValue } = useStoreInputs((state) => state);
  const { setModalUnits } = useModalUnits();
  const handleOnChange = (e: React.ChangeEvent<HTMLSelectElement>) => {
    if (e.target.value === "Other") {
      return setModalUnits(true, inputName);
    }

    setModalUnits(false, inputName);
    if (inputName === "RPT" || inputName === "CPT") {
      return setValueByName(inputName, Number(e.target.value));
    }
    setValueByName(inputName, e.target.value);
  };

  return (
    <select
      className={styles.select}
      id={`select-${inputName}`}
      style={{
        ...(width_optional ? { width: width_optional } : {}),
        ...(height_optional ? { height: height_optional } : {}),
        ...(bgColor ? { backgroundColor: bgColor } : {}),
        ...(fontColor ? { color: fontColor } : {}),
        ...(textSide ? { textAlign: textSide as any } : {}),
      }}
      defaultValue={defaultValue}
      onChange={handleOnChange}
      value={getInputValue(inputName) as string}
    >
      {inputName === "RPT" || inputName === "CPT"
        ? Array.isArray(options) &&
          options.map((option, index) => (
            <option key={index} value={option}>
              {option}
            </option>
          ))
        : Array.isArray(options) &&
          optionsIU.map((option, index) => (
            <option key={index} value={option}>
              {option}
            </option>
          ))}
    </select>
  );
};

export default InputSelect;
