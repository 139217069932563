import * as React from "react";
import { stylesSteps } from "../../../../css/steps";
import SensitivityTables from "./SensitivityTables";
import ConfigurationStep3 from "./ConfigurationStep3";

interface InputComponentProps {
  onTabPress: () => void;
}

const StepContent3: React.FC<InputComponentProps> = ({ onTabPress }) => {
  const styles = stylesSteps();
  return (
    <>
      <div
        className={`${styles.root}`}
        // style={{ ...(modalVisible.visible ? { backdropFilter: "blur(2px)", filter: "blur(2px)" } : {}) }}
      >
        <SensitivityTables />
        <ConfigurationStep3 onTabPress={onTabPress} />
      </div>
    </>
  );
};

export default StepContent3;
