import React from "react";
import { Valuation } from "../../../types/types";
import { valuationCardStyles } from "../../../css/valuations";
import EditIcon from "../../assets/Valuations/EditIcon";
interface ValuationCardProps {
  valuation: Valuation;
  index: number;
  handleValuation: (valuation: any) => void;
}

export default function ValuationCard({ valuation, index, handleValuation }: ValuationCardProps) {
  const styles = valuationCardStyles();
  return (
    <div key={index} className={styles.valuationsContainer} style={{ boxShadow: "0 2px 4px rgba(0, 0, 0, 0.1)" }}>
      <div className={styles.cardHeader}>
        <div className={styles.cardTitle}>
          <p className={styles.valuationName}>{valuation.valuationName}</p>
          {valuation?.inputs[0]?.value && <h5 className={styles.subTitleValuations}>Unlevered DCF</h5>}
          {valuation?.inputs[1]?.value && <h5 className={styles.subTitleValuations}>Levered DCF</h5>}
          {valuation?.inputs[2]?.value && <h5 className={styles.subTitleValuations}>DDM</h5>}
        </div>
        {valuation.saveForLater && (
          <button
            className={styles.editButton}
            onClick={() => {
              handleValuation(valuation);
            }}
          >
            <EditIcon />
            Edit
          </button>
        )}
      </div>
    </div>
  );
}
