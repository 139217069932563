import { makeStyles, shorthands } from "@fluentui/react-components";

export const useStylesGlobal = makeStyles({
  root: {
    minHeight: "100vh",
    display: "flex",
    flexDirection: "column",
    justifyContent: "flex-start",
    alignItems: "center",
    marginLeft: "0.4rem",
    marginRight: "0.4rem",
  },
  stepContainer: {
    height: "2.125rem",
    display: "flex",
    flexDirection: "row",
    marginLeft: "1rem",
    marginBottom: "1rem",
    paddingBottom: "1rem",
    marginTop: "1rem",
    width: "90%",
    ...shorthands.gap("0.75rem"),
  },
  // titlesContainer: {
  // 	display: 'flex',
  // 	flexDirection: 'column',
  // 	justifyContent: 'flex-start',
  // 	alignItems: 'flex-start'
  // },
  stepNumber: {
    color: "#232C33",
    fontSize: "1rem",
    fontStyle: "normal",
    fontWeight: "700",
    lineHeight: "normal",
    marginTop: "0.5rem",
    marginBottom: "0px",
    fontFamily: "Poppins",
  },
  stepTitle: {
    color: "#9A9AA2",
    fontFamily: "Poppins",
    textAlign: "center",
    fontSize: "1rem",
    fontStyle: "normal",
    fontWeight: "500",
    lineHeight: "normal",
    marginTop: "0px",
    marginBottom: "0px",
  },
  addValuationContainer: {
    display: "flex",
    flexDirection: "column",
    justifyContent: "center",
    alignItems: "center",
  },
  addValuationButton: {
    backgroundColor: "#F9F9F9",
    color: "rgba(0, 0, 0, 0.25)",
    height: "70px",
    width: "70px",
    cursor: "pointer",
    fontSize: "3rem",
    marginBottom: "1rem",
  },
  addValuationTitle: {
    color: "#232C33",
    textAlign: "center",
    fontSize: "0.875rem",
    fontStyle: "normal",
    fontWeight: "600",
    lineHeight: "normal",
  },
  valuationSubTitle: {
    color: "#9a9aa2",
    textAlign: "center",
    fontSize: "1rem",
    fontStyle: "normal",
    fontWeight: "500",
    lineHeight: "normal",
  },
  valuationTitle: {
    color: "#232C33",
    textAlign: "center",
    fontSize: "1.125rem",
    fontStyle: "normal",
    fontWeight: "600",
    lineHeight: "normal",
  },

  numeroCirculoStyle: {
    width: "30px",
    height: "30px",
    lineHeight: "30px",
    textAlign: "center",
    color: "#D4D4D8",
    fontSize: "16px",
    fontWeight: "bold",
  },
  barraSeparadoraStyle: {
    content: "-",
    ...shorthands.margin("0 5px"),
    color: "#007bff",
    fontWeight: "bold",
  },
  listNumbers: {
    display: "flex",
    justifyContent: "center",
    alignItems: "center",
  },
  numbersBar: {
    width: "5%",
    content: '""',
  },
  stepCircle: {
    width: "2.125rem",
    height: "2.125rem",
    textAlign: "center",
    backgroundColor: "#fff",
    color: "#232C33",
    fontFamily: "Poppins",
    fontSize: "1rem",
    fontWeight: "700",
    lineHeight: "2.125rem",
    marginTop: "0",
    marginBottom: "0",
    paddingBottom: "0",
    paddingTop: "0",
  },
  titleStepOneContainer: {
    height: "2.125rem",
    display: "flex",
    flexDirection: "column",
    marginTop: "0",
    marginBottom: "0",
  },
  stepProgress: {
    fontSize: "0.625rem",
    fontWeight: "500",
    fontFamily: "Poppins",
    color: "#994D98",
    marginTop: "0",
    marginBottom: "0",
    lineHeight: "normal",
  },
  titleStep: {
    color: "black",
    fontSize: "1rem",
    fontFamily: "Poppins",
    marginTop: "0",
    marginBottom: "0",
  },
  circleStepContainer: {
    height: "2.125rem",
    marginTop: "0",
    marginBottom: "0",
  },
});
