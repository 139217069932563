import React from "react";
import InputClip from "../../../Inputs/InputClip";
import ChevronImage from "../../../assets/ChevronImage";
import useStoreInputs from "../../../store/globalStates";
import { stylesSteps2 } from "../../../../css/step2";

interface InputComponentProps {
  onTabPress: () => void;
}

const BalanceSheetItems: React.FC<InputComponentProps> = ({ onTabPress }) => {
  const { getInputValidation, getShowValidation } = useStoreInputs((state) => state);

  const styles = stylesSteps2();
  const { chevronStates, setChevronStates } = useStoreInputs();

  return (
    <div
      style={{
        display: "flex",
        flexDirection: "column",
        gap: "1rem",
        transition: chevronStates[2].visible ? "transform 0.5s ease" : "transform 0.5s ease",
        transform: chevronStates[2].visible ? "translateY(0)" : "translateY(-200%)",
      }}
    >
      <div className={styles.stepOneContainers}>
        <div
          className={styles.subStep}
          style={{
            margin: "0",
          }}
        >
          <h4 className={styles.subStepTitle}>Balance Sheet Items</h4>
          <button
            tabIndex={-1}
            style={{
              border: "none",
              backgroundColor: "transparent",
              transform: `rotate(${chevronStates[3].rotation}deg)`,
              transition: "transform 0.5s ease",
              cursor: "pointer",
            }}
            onClick={() => {
              setChevronStates(3);
            }}
          >
            <ChevronImage></ChevronImage>
          </button>
        </div>
        <div
          style={{
            opacity: chevronStates[3].visible ? "1" : "0",
            transition: chevronStates[3].visible ? "opacity 0.3s ease 0.3s" : "",
            margin: "0",
          }}
        >
          {" "}
          <div className={styles.stepInput} style={{ backgroundColor: "#fff" }}>
            <div style={{ display: "flex", flexDirection: "column" }}>
              <h4 className={styles.titleGeneral}>Cash & Equivalents</h4>
              {getInputValidation("CCE") === false && getShowValidation("CCE") && (
                <div className={styles.validationContainer} style={{ marginRight: "auto" }}>
                  <h4 className={styles.validation} style={{ marginRight: "0.313rem", marginLeft: "0.313rem" }}>
                    Wrong format
                  </h4>
                </div>
              )}
            </div>
            {<InputClip type="text" nameItem="CCE" />}
          </div>
          <div className={styles.stepInput} style={{ backgroundColor: "#fff" }}>
            <div style={{ display: "flex", flexDirection: "column" }}>
              <h4 className={styles.titleGeneral}>Total Debt</h4>
              {getInputValidation("TD") === false && getShowValidation("TD") && (
                <div className={styles.validationContainer} style={{ marginRight: "auto" }}>
                  <h4 className={styles.validation} style={{ marginRight: "0.313rem", marginLeft: "0.313rem" }}>
                    Wrong format
                  </h4>
                </div>
              )}
            </div>
            {<InputClip type="text" nameItem="TD" />}
          </div>
          <div
            className={styles.stepInput}
            style={{ backgroundColor: "#fff", marginBottom: "4.2rem" }}
            // onKeyDown={handleKeyDown}
          >
            <div style={{ display: "flex", flexDirection: "column" }}>
              <h4 className={styles.titleGeneral}>Minority Interest</h4>
              {getInputValidation("MI") === false && getShowValidation("MI") && (
                <div className={styles.validationContainer} style={{ marginRight: "auto" }}>
                  <h4 className={styles.validation} style={{ marginRight: "0.313rem", marginLeft: "0.313rem" }}>
                    Wrong format
                  </h4>
                </div>
              )}
            </div>

            {<InputClip type="text" nameItem="MI" onTabPress={onTabPress} />}
          </div>
        </div>
      </div>
    </div>
  );
};
export default BalanceSheetItems;
