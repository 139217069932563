import {
  validateDate,
  validatePercentage,
  validateToggle,
  validateTEM,
  validateEmptyArray,
  validateisEmpty,
  validateYear,
  validateArrayYears,
  validatePositiveNumber,
} from "./ValidateFunctions";

// const {getInputValue} = useStoreInputs((state) => state)

export function validationByName(
  nameItem: string,
  value: string | boolean | string[],
  TEMCheckboxChecked?: boolean,
  PGRCheckboxChecked?: boolean
) {
  if (nameItem === "FCFY") {
    let stringValue = value;
    if (Array.isArray(value)) {
      stringValue = value[0] as string;
    }
    if (validateisEmpty(stringValue) === false) {
      return validateYear(stringValue as string | number);
    } else {
      return true;
    }
  }
  if (nameItem === "VD") {
    if (validateisEmpty(value) === false) {
      const validacion = validateDate(value);
      return validacion;
    } else {
      return true;
    }
  }
  if (nameItem === "MYC") {
    return validateToggle(value);
  }
  if (nameItem === "CEDA") {
    return validateToggle(value);
  }
  if (nameItem === "DTA/DTL") {
    return validateToggle(value);
  }
  if (nameItem === "COE") {
    return validatePercentage(value);
  }
  if (nameItem === "WACC") {
    return validatePercentage(value);
  }
  if (nameItem === "PGR") {
    if (!PGRCheckboxChecked) {
      return true;
    } else {
      return validatePercentage(value);
    }
  }
  if (nameItem === "TEM") {
    if (!TEMCheckboxChecked) {
      return true;
    } else {
      return validateTEM(value);
    }
  }
  if (nameItem === "YS") {
    return validateArrayYears(value);
  }

  if (nameItem === "EBI") {
    if (!validateEmptyArray(value)) {
      return false;
    }
    return true;
    // return validateArrayNumbers(value);
  }
  if (nameItem === "D&A") {
    if (!validateEmptyArray(value)) {
      return false;
    }
    return true;
  }
  if (nameItem === "CX") {
    // if (validateArrayNumbers(value)) {
    //   return validateMostNegative(value);
    // } else {
    //   return false;
    // }
    if (!validateEmptyArray(value)) {
      return false;
    }
    return true;
  }
  if (nameItem === "CNWC") {
    // return validateArrayNumbers(value);
    if (!validateEmptyArray(value)) {
      return false;
    }
    return true;
  }
  if (nameItem === "IdD") {
    // return validateArrayNumbers(value);
    if (!validateEmptyArray(value)) {
      return false;
    }
    return true;
  }
  if (nameItem === "iDD") {
    // return validateArrayNumbers(value);
    if (!validateEmptyArray(value)) {
      return false;
    }
    return true;
  }
  if (nameItem === "NI") {
    // return validateArrayNumbers(value);
    if (!validateEmptyArray(value)) {
      return false;
    }
    return true;
  }
  if (nameItem === "DIR") {
    // return validateArrayNumbers(value);
    if (!validateEmptyArray(value)) {
      return false;
    }
    return true;
  }
  if (nameItem === "CCE") {
    // return validatePositiveArrayNumbers(value);
    // if (!validateEmptyArray(value)) {
    //   return false;
    // }
    return validatePositiveNumber(value);
  }
  if (nameItem === "TD") {
    // if (!validateEmptyArray(value)) {
    //   return false;
    // }
    return validatePositiveNumber(value);
  }
  if (nameItem === "MI") {
    // if (!validateEmptyArray(value)) {
    //   return false;
    // }
    return validatePositiveNumber(value);
  }
  if (nameItem === "NI_3") {
    return true;
  }
  if (nameItem === "WACCS") {
    return validatePositiveNumber(value);
  }
  if (nameItem === "DVDS") {
    if (!validateEmptyArray(value)) {
      return false;
    }
    return true;
  }
  if (nameItem === "COES") {
    return validatePositiveNumber(value);
  }
  if (nameItem === "PGRS") {
    return validatePositiveNumber(value);
  }
  if (nameItem === "TEMS") {
    return validateTEM(value);
  }
  return true;
}
