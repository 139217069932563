import * as React from "react";
// import ClipImage from "../assets/ClipImage";
import useStoreInputs from "../store/globalStates";
import { handleOnChange, handleOnBlur, formatValue, handleKeyDown, getInputStyles } from "../helpers/inputClipHandlers";
import { inputClipStyles } from ".././../css/inputClip";
import InputClipImage from "./InputClipImage";
import { itsCheckboxInputs } from "../helpers/namesInputs";

interface InputClipProps {
  type: "text" | "number" | "date" | "checkbox";
  bgColor?: string;
  placeholder?: string;
  nameItem?: string;
  disabled?: boolean;
  linkedToggle?: boolean;
  onTabPress?: () => void;
}

const InputClip: React.FC<InputClipProps> = ({ disabled, nameItem, type, placeholder, onTabPress }) => {
  const styles = inputClipStyles();

  const { getInputValue, inputs } = useStoreInputs((state) => state);
  const [inputValue, setInputValueLocal] = React.useState(formatValue(getInputValue(nameItem), nameItem) || "");
  const inputStyles = getInputStyles({
    disabled,
    nameItem,
  });

  React.useEffect(() => {
    if (itsCheckboxInputs.includes(nameItem)) {
      setInputValueLocal(getInputValue(nameItem));
    }
  }, [inputs]);
  return (
    <div
      className={styles.inputContainer}
      style={{
        width: nameItem === "VD" ? "8.1rem" : type === "checkbox" ? "3.5rem" : "8.1rem",
        opacity: disabled && 0.2,
      }}
    >
      <input
        className={`${styles.input} ${styles.inputNoSpinButtons}`}
        style={inputStyles}
        type={type === "text" ? "text" : "number"}
        placeholder={placeholder || ""}
        id={nameItem}
        onChange={(e) => {
          handleOnChange(e, nameItem, setInputValueLocal);
        }}
        onKeyDown={(e) => {
          handleKeyDown(e, nameItem, onTabPress, setInputValueLocal);
        }}
        onBlur={(e) => handleOnBlur(e, nameItem, setInputValueLocal)}
        value={inputValue}
        readOnly={disabled}
        disabled={disabled}
      />
      <InputClipImage setInputValueLocal={setInputValueLocal} nameItem={nameItem} disabled={disabled} />
    </div>
  );
};

export default InputClip;
