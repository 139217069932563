import { makeStyles, shorthands } from "@fluentui/react-components";

export const inputCheckboxStyles = makeStyles({
  checkbox: {
    width: "2.875rem",
    height: "1.438rem",
    ...shorthands.padding("0.5em 1em"),
    marginRight: "0.25em",
    ...shorthands.borderRadius("1em"),
    ...shorthands.border("none"),
    ...shorthands.outline("none"),
    marginTop: "0px",
    marginBottom: "0px",
    backgroundColor: "#A9A9A9",
    cursor: "pointer",
    position: "relative",
  },
  checkboxBall: {
    width: "1rem",
    height: "1rem",
    backgroundColor: "#fff",
    ...shorthands.borderRadius("50%"),
    position: "absolute",
    left: "0",
  },
  checkboxOn: {
    backgroundColor: "#00B578",
  },
  checkboxOff: {
    backgroundColor: "#A9A9A9",
  },
  checkboxBallOn: {
    left: "0.1rem",
  },
  checkboxBallOff: {
    left: "1.587rem",
  },
  checkboxText: {
    color: "rgba(251, 251, 254, 1)",
    fontSize: "0.625rem",
    fontWeight: "500",
    fontFamily: "Poppins",
    lineHeight: "1rem",
    userSelect: "none",
  },
  checkboxTextOn: {
    display: "flex",
    justifyContent: "flex-start",
    alignItems: "center",
  },
  CheckboxTextOff: {
    display: "flex",
    justifyContent: "flex-end",
    alignItems: "center",
  },
});
